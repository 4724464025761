import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProfileActions } from './profile.actions';
import { switchMap, map, withLatestFrom, catchError, tap } from 'rxjs/operators';
import { MetaResponse, ProfileData, MetaError, Category, ProfileDataV2 } from 'src/app/api.interfaces';
import { TokenFacade } from '../token/token.facade';
import { Router } from '@angular/router';
import { ProfileFacade } from './profile.facade';
import { ProfileService } from './profile.service';

@Injectable()
export class ProfileEffects {

  headers$ = this.tokenFacade.headers$;
  token$ = this.tokenFacade.tokenValue$;

  @Effect() loadCategories$ = this.actions$.pipe(
    ofType(ProfileActions.loadCategories),
    withLatestFrom(this.token$),
    switchMap(([_, token]) => {
      return this.api.loadCategories(token).pipe(
        map((response: MetaResponse) => response.data),
        map((categories: Category[]) => ProfileActions.loadCategoriesSuccess({ categories })),
        catchError((responseError: MetaError) => {
          return of(ProfileActions.loadCategoriesError())
        })
      )
    })
  );

  @Effect() load$: Observable<Action> = this.actions$.pipe(
    ofType(ProfileActions.load),
    withLatestFrom(this.token$),
    switchMap(([_, token]) => {
      return this.api.loadProfile(token).pipe(
        map((response: MetaResponse) => response.data),
        map((data: ProfileData) => ProfileActions.loadSuccess({ data })),
        catchError((responseError: MetaError) => {
          alert(responseError.error.message);
          this.router.navigate(['/login']);
          return of(ProfileActions.loadError());
        }),
      );
    }),
  );

  @Effect() loadBusiness$: Observable<Action> = this.actions$.pipe(
    ofType(ProfileActions.loadSuccess),
    withLatestFrom(this.token$),
    switchMap(([profileData, token]) => {
      const business: any = profileData.data.business;
      if (profileData.data.business) {
        return of(ProfileActions.loadBusinessSuccess({ data: business }));
      } else {
        return of(ProfileActions.loadBusinessError());
      }
    }),
  );

  @Effect() editProfile$ = this.actions$.pipe(
    ofType(ProfileActions.editProfile),
    withLatestFrom(this.headers$),
    switchMap(([{ actualEmail,payload }, headers]) => {
      return this.api.updateProfile(actualEmail,payload, headers).pipe(
        map(() => ProfileActions.editProfileSuccess({ payload })),
        catchError(() => of(ProfileActions.editProfileError()))
      );
    })
  );

  @Effect() updateProfilePicture$ = this.actions$.pipe(
    ofType(ProfileActions.updateProfilePicture),
    withLatestFrom(this.headers$),
    switchMap(([{ image }, headers]) => {
      return this.api.updateProfilePicture(image, headers).pipe(
        map(() => ProfileActions.updateProfilePictureSuccess({ image })),
        catchError(() => of(ProfileActions.updateProfilePictureError()))
      );
    })
  );



  // Categories
  @Effect() addCategory$ = this.actions$.pipe(
    ofType(ProfileActions.addCategory),
    withLatestFrom(this.headers$),
    switchMap(([{ idCategory }, headers]) => {
      return this.api.addCategory(idCategory, headers).pipe(
        map(() => ProfileActions.addCategorySuccess()),
        catchError(() => of(ProfileActions.addCategoryError()))
      );
    })
  );

  @Effect() removeCategory$ = this.actions$.pipe(
    ofType(ProfileActions.removeCategory),
    withLatestFrom(this.headers$),
    switchMap(([{ idCategory }, headers]) => {
      return this.api.removeCategory(idCategory, headers).pipe(
        map(() => ProfileActions.removeCategorySuccess()),
        catchError(() => of(ProfileActions.removeCategoryError()))
      );
    })
  );


  // Specialties
  @Effect() addSpecialti$ = this.actions$.pipe(
    ofType(ProfileActions.addSpecialti),
    withLatestFrom(this.headers$),
    switchMap(([{ specialti }, headers]) => {
      return this.api.addSpecialti(specialti, headers).pipe(
        map(() => ProfileActions.addSpecialtiSuccess()),
        catchError(() => of(ProfileActions.addSpecialtiError()))
      );
    })
  );

  @Effect() removeSpecialti$ = this.actions$.pipe(
    ofType(ProfileActions.removeSpecialti),
    withLatestFrom(this.headers$),
    switchMap(([{ index }, headers]) => {
      return this.api.removeSpecialti(index, headers).pipe(
        map(() => ProfileActions.removeSpecialtiSuccess()),
        catchError(() => of(ProfileActions.removeSpecialtiError()))
      );
    })
  );


  // Portfolios
  @Effect() addPortfolio$ = this.actions$.pipe(
    ofType(ProfileActions.addPortfolio),
    withLatestFrom(this.headers$),
    switchMap(([{ files, payload }, headers]) => {
      return this.api.addPortfolio(files, payload, headers).pipe(
        map(() => ProfileActions.addPortfolioSuccess()),
        catchError(() => of(ProfileActions.addPortfolioError()))
      );
    })
  );


  @Effect() removePortfolio$ = this.actions$.pipe(
    ofType(ProfileActions.removePortfolio),
    withLatestFrom(this.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.removePortfolio(id, headers).pipe(
        map(() => ProfileActions.removePortfolioSuccess()),
        catchError(() => of(ProfileActions.removePortfolioError()))
      );
    })
  );

  @Effect() updatePortfolioInfo$ = this.actions$.pipe(
    ofType(ProfileActions.updatePortfolioInfo),
    withLatestFrom(this.headers$),
    switchMap(([{ id, payload }, headers]) => {
      return this.api.updatePortfolioInfo(id, payload, headers).pipe(
        map(() => ProfileActions.updatePortfolioInfoSuccess()),
        catchError(() => of(ProfileActions.updatePortfolioInfoError()))
      );
    })
  );

  @Effect() updatePortfolioPicture$ = this.actions$.pipe(
    ofType(ProfileActions.updatePortfolioPicture),
    withLatestFrom(this.headers$),
    switchMap(([{ sort, file, image, portfolio }, headers]) => {
      return this.api.updatePortfolioPicture(sort, file, image, portfolio, headers).pipe(
        map(() => ProfileActions.updatePortfolioPictureSuccess()),
        catchError(() => of(ProfileActions.updateProfilePictureError()))
      );
    })
  );



  // Academic Formations
  @Effect() addAcademicFormation = this.actions$.pipe(
    ofType(ProfileActions.addAcademicFormation),
    withLatestFrom(this.headers$),
    switchMap(([{ item }, headers]) => {
      return this.api.addAcademicFormation(item, headers).pipe(
        map(() => ProfileActions.addAcademicFormationSuccess()),
        catchError(() => of(ProfileActions.addAcademicFormationError()))
      );
    })
  );

  @Effect() updateAcademicFormation = this.actions$.pipe(
    ofType(ProfileActions.updateAcademicFormation),
    withLatestFrom(this.token$),
    switchMap(([{ id, item }, token]) => {
      return this.api.updateAcademicFormation(id, item, token).pipe(
        map(() => ProfileActions.updateAcademicFormationSuccess()),
        catchError(() => of(ProfileActions.updateAcademicFormationError()))
      );
    })
  );

  @Effect() removeAcademicFormation = this.actions$.pipe(
    ofType(ProfileActions.removeAcademicFormation),
    withLatestFrom(this.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.removeAcademicFormation(id, headers).pipe(
        map(() => ProfileActions.removeAcademicFormationSuccess()),
        catchError(() => of(ProfileActions.removeAcademicFormationError()))
      );
    })
  );



  // Experiences
  @Effect() addExperience = this.actions$.pipe(
    ofType(ProfileActions.addExperience),
    withLatestFrom(this.headers$),
    switchMap(([{ experience }, headers]) => {
      return this.api.addExperience(experience, headers).pipe(
        map(() => ProfileActions.addExperienceSuccess()),
        catchError(() => of(ProfileActions.addExperienceError()))
      );
    })
  );

  @Effect() updateExperience$ = this.actions$.pipe(
    ofType(ProfileActions.updateExperience),
    withLatestFrom(this.token$),
    switchMap(([{ id, experience }, token]) => {
      return this.api.updateExperience(id, experience, token).pipe(
        map(() => ProfileActions.updateExperienceSuccess()),
        catchError(() => of(ProfileActions.updateExperienceError()))
      );
    })
  );

  @Effect() removeExperience$ = this.actions$.pipe(
    ofType(ProfileActions.removeExperience),
    withLatestFrom(this.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.removeExperience(id, headers).pipe(
        map(() => ProfileActions.removeExperienceSuccess()),
        catchError(() => of(ProfileActions.removeExperienceError()))
      );
    })
  );




  // Recommendations
  @Effect() addRecommendation$ = this.actions$.pipe(
    ofType(ProfileActions.addRecommendation),
    withLatestFrom(this.headers$),
    switchMap(([{ recommendation }, headers]) => {
      return this.api.addRecommendation(recommendation, headers).pipe(
        map(() => ProfileActions.addRecommendationSuccess()),
        catchError(() => of(ProfileActions.addRecommendationError()))
      );
    })
  );

  @Effect() updateRecommendation$ = this.actions$.pipe(
    ofType(ProfileActions.updateRecommendation),
    withLatestFrom(this.headers$),
    switchMap(([{ id, recommendation }, headers]) => {
      return this.api.updateRecommendation(id, recommendation, headers).pipe(
        map(() => ProfileActions.updateRecommendationSuccess()),
        catchError(() => of(ProfileActions.updateRecommendationError()))
      );
    })
  );

  @Effect() removeRecommendation$ = this.actions$.pipe(
    ofType(ProfileActions.removeRecommendation),
    withLatestFrom(this.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.removeRecommendation(id, headers).pipe(
        map(() => ProfileActions.removeRecommendationSuccess()),
        catchError(() => of(ProfileActions.removeRecommendationError()))
      );
    })
  );

  constructor(
    private actions$: Actions,
    private api: ProfileService,
    private tokenFacade: TokenFacade,
    private router: Router,
    private profileFacade: ProfileFacade
  ) { }
}
