//MODULES
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxBootstrapModule } from './externalModules/ngx-bootstrap.module';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';


//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//COMPONENTS
import { AppComponent } from './app.component';
import { AboutComponent } from './public-pages/about/about.component';
import { ContactComponent } from './public-pages/contact/contact.component';
import { ForgotPasswordComponent } from './shared/modals/forgot-password/forgot-password.component';
import { LoginComponent } from './public-pages/login/login.component';
import { NetworkComponent } from './public-pages/network/network.component';
import { PublicNavbarComponent } from './shared/headers/public-navbar/public-navbar.component';
import { PublicPagesComponent } from './public-pages/public-pages.component';
import { RegisterComponent } from './public-pages/register/register.component';
import { WokeModule } from './woke/woke.module';
import { RootStoreModule } from '../store/root-store.module';
import { BusinessProfileRegisterComponent } from './public-pages/business-profile-register/business-profile-register.component';
import { WorkspaceComponent } from './shared/modals/workspace/workspace.component';

import { StripeModule } from "stripe-angular";
import { CheckoutModule } from './logged/checkout/checkout.module';
import { Router, Scroll, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TermsConditionsComponent } from './public-pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './public-pages/privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './public-pages/reset-password/reset-password.component';
import { RegisterLiteComponent } from './public-pages/register-lite/register-lite.component';
import { LandingComponent } from './public-pages/landing/landing.component';
import { RecoverLiteComponent } from './public-pages/recover-lite/recover-lite.component';
import { RegisterSpaceComponent } from './public-pages/register-space/register-space.component';
import { SpaceRegisterComponent } from './public-pages/space-register/space-register.component';
import { FooterComponent } from './shared/footer/footer.component';
declare var $: any;

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    ForgotPasswordComponent,
    LoginComponent,
    NetworkComponent,
    PublicNavbarComponent,
    PublicPagesComponent,
    RegisterComponent,
    BusinessProfileRegisterComponent,
    WorkspaceComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ResetPasswordComponent,
    RegisterLiteComponent,
    LandingComponent,
    RecoverLiteComponent,
    RegisterSpaceComponent,
    SpaceRegisterComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxBootstrapModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot({
      siteKey: '6LeBZO0ZAAAAAH425WTKI_pnIgPED1PcX2-Kxc4t'
    }),
    WokeModule,
    RootStoreModule,
    StripeModule.forRoot(environment.STRIPE_KEY),
    CheckoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBBDsVzOPIE-TIjBq7_DR61mukHKYCy_zw'
    }),
    AgmJsMarkerClustererModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })
  ],
  entryComponents: [
    ForgotPasswordComponent,
    WorkspaceComponent
  ],
  providers: [
    Meta,
    {provide: 'googleTagManagerId',  useValue: 'GTM-N275CPN'}
  ],
  bootstrap: [AppComponent],
  exports: [FooterComponent]
})
export class AppModule {
  constructor(router: Router) {
    router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      $('html, body').animate({scrollTop: '0px'}, 300);
    });
  }
}
