import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CheckoutWorkspaceService } from '../checkout-workspace/checkout-workspace.service';
import { Subject } from 'rxjs';
import { Workspace } from 'src/app/api.interfaces';
import { CoworkingSpaceService } from 'src/app/logged/workspaces/coworking-space/coworking-space/coworking-space.service';
import { LoggedService } from 'src/app/logged/logged.service';
import { QuickBookingFacade } from 'src/store/quick/quick.facade';
import { QuickModel } from 'src/app/logged/workspaces/workspace/quick/quick.model';

@Component({
  selector: 'app-workspace-detail',
  templateUrl: './workspace-detail.component.html',
  styleUrls: ['./workspace-detail.component.scss']
})
export class WorkspaceDetailComponent implements OnInit, OnDestroy {
  @Output() onWorkspace = new EventEmitter<Workspace>();
  workspace: Workspace;
  killall = new Subject();
  loading = false;

  quickModel: QuickModel;

  constructor(
    private route: ActivatedRoute,
    private checkoutWorkspaceService: CoworkingSpaceService,
    public loggedService: LoggedService,
    private quickFacade: QuickBookingFacade
  ) { }

  ngOnInit() {

    this.quickFacade.quickModel$.pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: quickModel => {
        this.quickModel = quickModel;
      },
      error: error => {
        console.warn(error);
      }
    })

    this.loading = true;

    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap(id => this.checkoutWorkspaceService.loadWorkspaceDetail(id)),
      take(1),
      takeUntil(this.killall),
    ).subscribe({
      next: (workspace: Workspace) => {
        this.workspace = workspace;
        this.onWorkspace.emit(workspace);
        this.loading = false;
      },
      error: (error) => {
        console.warn(error);
      },
    });
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

}
