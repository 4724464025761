import { createReducer, on, Action } from '@ngrx/store';
import { TokenActions } from './token.actions';
import { initialState, TokenState } from './token.state';

export const tokenReducer = createReducer(
  initialState,
  on(
    TokenActions.set,
    (state, { value }) => ({ ...state, value })
  ),
  on(
    TokenActions.clear,
    () => ({ ...initialState })
  ),
  on(
    TokenActions.toggleDarkMode,
    (state) => {
      if (!state.darkMode) {
        window.localStorage.setItem('darkMode', 'true');
      } else {
        window.localStorage.removeItem('darkMode');
      }
      return { ...state, darkMode: !state.darkMode };
    }
  ),
  on(
    TokenActions.switchLanguage,
    (state, { lan }) => {
      window.localStorage.setItem('language', lan);
      return { ...state, language: lan };
    }
  ),
  on(
    TokenActions.setRole,
    (state, { role }) => {
      return { ...state, role: role };
    }
  ),
  on(
    TokenActions.toggleBusinessProfile,
    (state) => {
      if (!state.businessProfile) {
        window.localStorage.setItem('businessProfile', 'true');
      } else {
        window.localStorage.removeItem('businessProfile');
      }
      return { ...state, businessProfile: !state.businessProfile };
    }
  ),
  on(
    TokenActions.refreshTokenSuccess,
    (state, { response }) => ({ ...state, tokenResponse: response, value: `Bearer ${response.token}` })
  ),
  on(
    TokenActions.loadOnboardingsSuccess,
    (state, { sections }) => ({ ...state, onboardings: sections })
  ),
  on(
    TokenActions.setCurrenOnboarding,
    (state, { onboarding }) => ({ ...state, currentOnboarding: onboarding, currentOnboardingIndex: 0 })
  ),
  on(
    TokenActions.nextOnboardingSection,
    (state) => {
      return { ...state, currentOnboardingIndex: state.currentOnboardingIndex + 1}
    }
  ),
  on(
    TokenActions.showOnboarding,
    (state) => {
      return { ...state, onboardingClosed: false }
    }
  ),
  on(
    TokenActions.closeOnboarding,
    (state) => {
      return { ...state, onboardingClosed: true, currentOnboardingIndex: 0 }
    }
  )

)

export function reducer(state: TokenState | undefined, action: Action) {
  return tokenReducer(state, action);
}
