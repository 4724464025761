import { CoworkingChild } from 'src/app/api.interfaces'

export type EventType = {
  active:boolean;
  id: number;
  name: string;
  description: string;
  category: string;
  date: string;
  time: string;
  capacity: number;
  remaining: number;
  location: string;
  price: string;
  limitedVacancy: boolean;
  profilePicture: string;
  coworkingChild: CoworkingChild;
}
export type EventsResponseType = {
  total: number;
  events: EventType[];
  current?: EventType;
}

export class EventClass {
  active?:boolean;
  id: number;
  name: string;
  description: string;
  category: string;
  date: string;
  time: string;
  capacity: number;
  remaining: number;
  location: string;
  price: string;
  limitedVacancy: boolean;
  profilePicture: string;
  coworkingChild: {
    id: number;
    name: string;
    profilePicture: string;
    rating: number;
  };
  constructor(payload?: any) {
    payload = payload || {};
    this.active = payload.active || null;
    this.id = payload.id || 0;
    this.name = payload.name || '';
    this.description = payload.description || '';
    this.category = payload.category || '';
    this.date = payload.date || '';
    this.time = payload.time || '';
    this.capacity = payload.capacity || '';
    this.remaining = payload.remaining || '';
    this.location = payload.location || '';
    this.price = payload.price || '';
    this.limitedVacancy = payload.limitedVacancy || false;
    this.profilePicture = payload.profilePicture || '';
    this.coworkingChild = payload.coworkingChild || {
      id: 0,
      name: '',
      profilePicture: '',
      rating: 0
    };

  }
}

export class UpcomingEventClass {

  id: number;
  event: EventClass;
  qr: string;

  constructor(payload?: any) {
    payload = payload || {};
    this.id = payload.id || 0;
    this.event = new EventClass(payload.event);
    this.qr = payload.qr === '' ? 'mojo' : payload.qr;
  }

}
