import { Component, OnInit, Input } from '@angular/core';
import { NetworkItem } from 'src/app/api.interfaces';
import { TokenFacade } from 'src/store/token/token.facade';
import { LoggedService } from 'src/app/logged/logged.service';

@Component({
  selector: 'app-woke-event',
  templateUrl: './woke-event.component.html',
  styleUrls: ['./woke-event.component.scss']
})
export class WokeEventComponent implements OnInit {

  @Input() event: NetworkItem = <NetworkItem>{};

  darkMode$ = this.tokenFacade.darkMode$;

  constructor(
    private tokenFacade: TokenFacade,
    public loggedService: LoggedService
  ) { }

  ngOnInit() {
  }

}
