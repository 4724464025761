import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { PaymentMethod } from 'src/app/api.interfaces';
import { filter, map, tap } from 'rxjs/operators';
import { AddPaymentComponent } from '../add-payment/add-payment.component';

@Component({
  selector: 'app-select-payment',
  templateUrl: './select-payment.component.html',
  styleUrls: ['./select-payment.component.scss']
})
export class SelectPaymentComponent implements OnInit, OnDestroy {

  partialIndex: string;
  modalConfig: ModalOptions = {
    class: 'modal-dialog-centered modal-xl'
  };

  paymentMethods$ = this.walletFacade.paymentMethods$;
  paymentDefault$ = this.walletFacade.paymentDefault$;

  @Output()selected = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    private walletFacade: WalletFacade,
  ) { }

  ngOnInit() {
    this.paymentDefault$.pipe(
      filter(paymentDefault => paymentDefault !== undefined),
      map(paymentMethod => paymentMethod.id),
      tap(id => this.partialIndex = id)
    ).subscribe();
  }

  ngOnDestroy() {
    this.closeModal();
  }

  addNew() {
    this.closeModal();
    setTimeout(() => {
      this.modalService.show(AddPaymentComponent, this.modalConfig);
    }, 1000);
  }

  partialSelect(paymentMethod: PaymentMethod) {
    this.partialIndex = paymentMethod.id;
  }

  selectPaymentMethod() {
    if (this.partialIndex) {
      this.walletFacade.setPaymentMethodDefault(this.partialIndex);
    }
    this.selected.emit();
  }

  deletePaymentMethod(paymentMethod: PaymentMethod) {
    this.walletFacade.deletePaymentMethod(paymentMethod.id);
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

}
