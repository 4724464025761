import { createAction, props } from '@ngrx/store';
import { PaymentMethod } from 'src/app/api.interfaces';

export const PaymentMethodsActions = {
  add: createAction(
    `[API Adding 😬] Add Payment Method`,
    props<{ tokenId: string }>()
  ),
  addSuccess: createAction(
    `[API Success ✅] Add Payment Method`
  ),
  addError: createAction(
    `[API Error ⛔️] Add Payment Method`
  ),
  load: createAction(
    `[API Loading 😬] Load Payment Methods`
  ),
  loadSuccess: createAction(
    `[API Success ✅] Load Payment Methods`,
    props<{ items: PaymentMethod[] }>()
  ),
  loadError: createAction(
    `[API Error ⛔️] Load Payment Methods`
  ),
  delete: createAction(
    `[API Loading 😬] Delete Payment Method`,
    props<{ id: string }>()
  ),
  deleteSuccess: createAction(
    `[API Success ✅] Delete Payment Method`,
    props<{ id: string }>()
  ),
  deleteError: createAction(
    `[API Error ⛔️] Delete Payment Method`
  ),
  update: createAction(
    `[API Loading 😬] Update Payment Method`,
    props<{ item: PaymentMethod }>()
  ),
  updateSuccess: createAction(
    `[API Success ✅] Update Payment Method`,
    props<{ item: PaymentMethod }>()
  ),
  updateError: createAction(
    `[API Error ⛔️] Update Payment Method`
  ),
  setPaymentDefault: createAction(
    `[Filter Set 🐤] Set Payment Method Default`,
    props<{ paymentDefault: string }>()
  )
}
