import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[appWokeHeight]'
})
export class WokeHeightDirective {

  @HostBinding('style.maxHeight') height: string = '0px';



  constructor(private elRef: ElementRef) {}

  getHeight(): number {
    return +this.elRef.nativeElement.offsetHeight;
  }

  setHeight(height: number) {
    this.height = `${height}px`;
  }

}
