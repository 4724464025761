import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { take, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-public-navbar',
  templateUrl: './public-navbar.component.html',
  styleUrls: ['./public-navbar.component.scss']
})
export class PublicNavbarComponent implements OnInit, OnDestroy {

  public isCollapsed = false;
  locate:boolean;
  killall = new Subject()

  constructor(public location: Location, private router: Router) { }
  ngOnInit() {
    this.locate = this.getLocation(this.router.url)
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.killall)
    ).subscribe({
      next: (event:NavigationEnd) =>{
        console.log(event);
        this.locate = this.getLocation(event.url)
      }
    })
  }

  ngOnDestroy(){
    this.killall.next();
    this.killall.complete();
  }

  getLocation(url:string){
    return url === '/register' || url.includes('contact') || url.includes('login')
  }

  goBack(){
    this.location.back()
  }

}
