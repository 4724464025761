import { createAction, props } from '@ngrx/store';
import { Booking, TokenResponse } from 'src/app/api.interfaces';
import { OnboardingClass, OnboardingType } from 'src/app/woke/onboarder/onboarder.models';

export const TokenActions = {
  set: createAction(
    `[API] Set Token`,
    props<{ value: string }>()
  ),
  clear: createAction(
    `[API] Clear Token`,
  ),
  refreshToken: createAction(
    `[API] Refresh Token`,
    props<{ token: string }>()
  ),
  refreshTokenSuccess: createAction(
    `[API] Refresh Token Success `,
    props<{ response: TokenResponse }>()
  ),
  refreshTokenError: createAction(
    `[API] Refresh Token Error `,
  ),
  toggleDarkMode: createAction(
    `[Local] Toggle Dark Mode`
  ),
  switchLanguage: createAction(
    `[Local] Switch Language`,
    props<{ lan: string }>()
  ),
  setRole: createAction(
    `[Local] Set Role`,
    props<{ role: string }>()
  ),
  setBooking: createAction(
    `[Local] Set Booking`,
    props<{ booking: Booking }>()
  ),
  toggleBusinessProfile: createAction(
    `[Local] Toggle Business Profile`
  ),
  // Al cargar del API los onboarding leídos
  loadOnboardingsSuccess: createAction(
    `[API] Load Onboardings Success`,
    props<{sections: string[]}>()
  ),
  loadOnboardingsError: createAction(
    `[API] Load Onboardings Error`
  ),
  // Cuando terminamos de leer un onboarding
  finishOnboarding: createAction(
    `[API] Finish Onboarding`,
    props<{ onboarding: string }>()
  ),
  finishOnboardingSuccess: createAction(
    `[API] Finish Onboarding Success`,
    props<{ onboarding: string }>()
  ),
  finishOnboardingError: createAction(
    `[API] Finish Onboarding Error`,
    props<{ onboarding: string }>()
  ),
  // Decidimos el onboarding que queremos mostrar
  setCurrenOnboarding: createAction(
    `[Local] Set Current Onboarding`,
    props<{ onboarding: OnboardingType }>()
  ),
  nextOnboardingSection: createAction(
    `[Local] Next Onboarding Section`,
  ),
  showOnboarding: createAction(
    `[Local] Show Onboarding`
  ),
  closeOnboarding: createAction(
    `[Local] Close Onboarding`
  ),
}
