import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { startOfDay, addMinutes, addHours, isPast } from 'date-fns';
declare var $: any;

export interface TimeEvent {
  time: string;
  active?: boolean;
  partial?: boolean;
  taken?: boolean;
  inactive?: boolean;
  past?: boolean;
  dateTime?: number;
  wokeFormat?: string;
}

export type TimePickerEvent = {
  time: string;
  first: TimeEvent;
  last: TimeEvent;
  hours: number;
}

@Component({
  selector: 'app-woke-timepicker',
  templateUrl: './woke-timepicker.component.html',
  styleUrls: ['./woke-timepicker.component.scss']
})
export class WokeTimepickerComponent implements OnInit, OnChanges {

  @Input() exclusions: string[] = [];
  @Input() autoSelection = false;
  @Input() rangeMode = true;
  @Input() opensAt = ''
  @Input() closesAt = ''
  @Input() forDate = '01/01/2020';
  @Output() selection = new EventEmitter<TimePickerEvent>();

  hours: TimeEvent[];

  first: TimeEvent;
  last: TimeEvent;


  constructor() { }

  ngOnInit() {
    this.hours = this.createRows(this.opensAt,this.closesAt);
    this.closesAt !== '' && +this.closesAt.split(':')[1] <= 30 ? this.hours.pop() : this.hours
    //this.goToBottom()
  }

  ngOnChanges() {
    this.hours = this.createRows(this.opensAt,this.closesAt);
  }

  active(timeEvent: TimeEvent) {
    if (!timeEvent.taken) {
      if (!this.rangeMode) {
        this.activeOne(timeEvent);
      } else {
        this.activeRange(timeEvent);
      }
    }
  }

  goToBottom() {
    setTimeout(() => {
      const $target = $('#timeBox')
      const height = $target[0].scrollHeight;

      $target.animate({scrollTop: height/8.5}, 1);
    }, 300);
  }

  activeOne(timeEvent: TimeEvent) {

  }

  activeRange(timeEvent: TimeEvent) {
    if (!this.first) {
      this.first = timeEvent;
      timeEvent.active = true;
    } else {
      if (!this.last) {
        this.last = timeEvent;
        timeEvent.active = true;
        this.apply();
      } else {
        this.hours.forEach(hour => {
          hour.active = false;
          hour.partial = false;
        });
        this.last = undefined;
        this.first = timeEvent;
        timeEvent.active = true;
      }
    }

    this.activeBetween();
  }

  activeBetween() {
    if (this.first && this.last) {
      const arraySorted = this.orderFirstAndLast(this.first, this.last);
      this.first = arraySorted[0];
      this.last = arraySorted[1];
      this.activePartialRange(this.hours, this.first, this.last)
    }
  }

  orderFirstAndLast(first: TimeEvent, last: TimeEvent) {
    const array = [first, last];
    const arraySorted = array.sort((a, b) => {
      const bandA = a.dateTime;
      const bandB = b.dateTime;
      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    });
    return arraySorted;
  }

  activePartialRange(hours: TimeEvent[], first: TimeEvent, last: TimeEvent) {

      const firstIndex = hours.findIndex(x => x.time === first.time);
      const lastIndex = hours.findIndex(x => x.time === last.time);

      hours.forEach(hour => {
        hour.partial = false
      });

      if (lastIndex > 0) {
        for(let i = firstIndex; i < lastIndex; i++) {
          hours[i].partial = true;
        }
      }
  }

  apply() {
    if (this.first && this.last) {
      let validRange = true;
      const firstIndex = this.hours.findIndex(x => x.time === this.first.time);
      const lastIndex = this.hours.findIndex(x => x.time === this.last.time);
      const value = lastIndex - firstIndex;

      for(let i = firstIndex; i < lastIndex; i++) {
        if (this.hours[i].taken) {
          validRange = false;
        }
      }

      if (validRange) {
        const payload: TimePickerEvent = {
          time: `${this.first.time} - ${this.last.time}`,
          first: this.first,
          last: this.last,
          hours: value / 2
        };
        this.selection.emit(payload);
      } else {
        alert('Por favor elige un rango de horas desocupadas.');
      }
    }
  }


  /**
   * Algoritmo Generador
   */

  createRows(init: string, end:string) {

    const starts = init !== '' ? init.split(':')[0] : 6
    const ends = end !== '' ? end.split(':')[0] : 23

    // Initial Values
    const hours = [];
    // Starting at 8:00
    for (let i = +starts; i <= +ends; i++) {
      // Normal hour start
      const timeEvent = this.createTimeEvent(`${this.getStr(i)}:00`);

      hours.push(timeEvent);
      // Normal hour end

      // Half hour start
      const halfTimEvent = this.createTimeEvent(`${this.getStr(i)}:30`)
      hours.push(halfTimEvent);
      // Half hour end
    }

    return hours;
  }

  getStr(time: number) {
    return time < 10 ? `0${time}` : `${time}`
  }

  createTimeEvent(time = '00:00'): TimeEvent {
    const date = this.transformStringToTime(time);
    let taken = this.exclusions.find(x => x === time) ? true : false;
    return {
      time,
      wokeFormat: time.split(':')[0],
      taken,
      past: isPast(date),
      dateTime: date.getTime()
    };
  }

  transformStringToTime(time = '00:00') {
    try {
      const now = this.transformStringToDate(this.forDate);
      const [ hourStr, minutesStr ] = time.split(':');
      const [ hours, minutes ] = [ +hourStr, +minutesStr ];
      return addMinutes(addHours(startOfDay(now), hours), minutes);
    } catch (error) {
      return new Date();
    }
  }

  transformStringToDate(time = '01/01/2020') {
    const today = startOfDay(new Date())
    try {
      const [ strDay, strMonth, strYear ] = time.split('/');
      const [day, month, year] = [ +strDay, +strMonth - 1, +strYear ];
      return new Date(year, month, day);
    } catch (error) {
      return today;
    }
  }

}
