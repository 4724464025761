import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { switchMap, map, filter } from 'rxjs/operators';
import { MetaResponse, MyOffice, MyOfficeMember, BillingInformation } from 'src/app/api.interfaces';
import { UserFound, CheckoutOfficePayload, CheckoutProcessResponse, DayPassPayload, DayPassSuccessResponse, UploadPaymentResponse, BookingHistoryItem } from './my-office.models';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { DayPassLimit } from './my-office/my-office.model';

@Injectable({
  providedIn: 'root'
})
export class MyOfficeService {

  paymentDefault$ = this.walletFacade.paymentDefault$;

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade,
    private walletFacade: WalletFacade,
  ) {

  }

  getOfficeDetail(officeId: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/detail/${officeId}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          filter(office => office !== undefined),
        )
      })
    )
  }


  getOfficeCheckout(officeId: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/checkout/${officeId}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          filter(office => office !== undefined),
        )
      })
    )
  }

  loadPaymentMethods() {
    this.walletFacade.loadPaymentMethods();
  }

  loadBillingInformation() {
    const url = `${environment.api}/wallet/billing-information`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as BillingInformation)
        )
      })
    )
  }

  checkoutOffice(payload: CheckoutOfficePayload, office: MyOffice) {
    const formData = new FormData();
    formData.append('source_id', payload.source_id);
    formData.append('invoice', payload.invoice);

    const url = `${environment.api}/my-office/stripe/checkout/process/${office.id}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as CheckoutProcessResponse)
        )
      })
    )
  }

  searchUser(username: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/chat/search?search=${username}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as UserFound[]),
          map(users => users.filter((_, index) => index < 5))
        )
      })
    )
  }

  searchUniqueUser(username: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/chat/search?search=${username}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map((users: UserFound[]) => {
            return users.find(user => user.username === username || user.email === username)
          })
        )
      })
    )
  }

  addTeamMember(office: MyOffice, idUser: number) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/add`;
        const formData = new FormData();
        formData.append('id', `${office.id}`);
        formData.append('idUser', `${idUser}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }

  deleteTeamMember(officeId: number, user: MyOfficeMember) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/delete`;
        const formData = new FormData();
        formData.append('id', `${officeId}`);
        formData.append('idUser', `${user.id}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }

  addDayPass(payload: DayPassPayload, officeId: number) {
    const url = `${environment.api}/my-office/day-pass/add`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const formData = new FormData();
        formData.append('id', `${officeId}`);
        formData.append('idUser', `${payload.idUser}`);
        formData.append('date', `${payload.date}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as DayPassSuccessResponse),
        )
      })
    )
  }


  deleteDayPass(dayPassId: number, officeId: number) {
    const url = `${environment.api}/my-office/day-pass/delete`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const formData = new FormData();
        formData.append('id', `${officeId}`);
        formData.append('dayPassId', `${dayPassId}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }

  uploadPayment(officeId: number, reference: string, file: File) {
    const url = `${environment.api}/my-office/upload-payment-receipt/${officeId}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const formData = new FormData();
        formData.append('reference', reference);
        formData.append('file', file);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as UploadPaymentResponse),
        )
      })
    )
  }

  loadBookingHistory(officeId: string) {
    const url = `${environment.api}/my-office/booking-history/${officeId}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(url, { headers })
        .pipe(
          map((response: MetaResponse) => response.data as BookingHistoryItem[]),
        )
      )
    )
  }

  rateBooking(bookingId: number, rating: number, comments: string) {
    const url = `${environment.api}/workspace/rate/new`;
    const formData = new FormData();
    formData.append('id', `${bookingId}`);
    formData.append('rating', `${rating}`);
    formData.append('comments', `${comments}`);
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.post(url, formData, { headers })
        .pipe(
          map((response: MetaResponse) => response.data),
        )
      )
    )
  }

  getDayPassesLimit(officeID:string, date:string){
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/day-pass/${officeID}/date`;
        const formData = new FormData();
        formData.append('date', date);
        return this.http.post(url, formData, {headers}).pipe(
          map((response: MetaResponse) => response.data as DayPassLimit)
        )
      })
    )
  }

}
