import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenFacade } from 'src/store/token/token.facade';
import { switchMap, map, tap, delay } from 'rxjs/operators';
import { ApplyOfficeType, RegisterBusinessType, ApplyLicenseType } from '../../shared/components/book-selector/plan.models';
import { ProfileFacade } from 'src/store/profile/profile.facade';
import { CoworkingChildsFacade } from 'src/store/childs/childs.facade';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MetaResponse, ProfileData } from 'src/app/api.interfaces';
import { GetOfficesType, LicenseType } from './plan.models';
import { MyLicense } from 'src/app/logged/my-license/my-license.types';

function makeid(length: number) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `${result}@woke.mx`;
}

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  featured$ = this.childsFacade.all$;
  offices$ = this.profileFacade.offices$;
  extraoffices$ = this.profileFacade.extraoffices$;
  licenses$ = this.profileFacade.licenses$

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade,
    private profileFacade: ProfileFacade,
    private childsFacade: CoworkingChildsFacade,
    private fb: FormBuilder
  ) { }

  registerBusinessProfile(payload: any) {
    return this.tokenFacade.tokenValue$.pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': token,
        });
        const url = `${environment.api}/settings/register/business/new`;
        const formData = new FormData();
        formData.append('name', payload.name);
        formData.append('username', payload.usernameBusiness);
        formData.append('password', payload.password);
        formData.append('email', makeid(5));
        console.log('Creating Business profile');
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          switchMap((data) => {
            console.log('Business profile created', data);
            console.log('Refreshing Profile Data');
            const headers = new HttpHeaders({ 'Authorization': token });
            return this.http.get(`${environment.api}/profile`, { headers }).pipe(
              map((response: MetaResponse) => response.data),
            )
          }),
          switchMap((data) => {
            console.log('Profile Data Refreshed');
            console.log(data);
            console.log('Setting Profile Data in Store');
            this.profileFacade.loadData(data);

            console.log('Loading Business Profile');
            const headers = new HttpHeaders({
              'Authorization': token,
              'x-switch-user': payload.usernameBusiness
            });
            return this.http.get(`${environment.api}/profile`, { headers }).pipe(
              map((response: MetaResponse) => response.data),
              tap((data: ProfileData) => {
                this.profileFacade.loadBusinessSuccess(data);
                console.log('Business Profile Loaded', data);
                console.log('Waiting to toggle');
              }),
              delay(1000),
              tap(() => {
                console.log('Ready to toggle');
                this.tokenFacade.toggleBusinessProfile();
                console.log('Toggled to Business');
              })
            )
          })
        )
      }),
    )
  }

  getCoworkingParentsList(){
    const url = `${environment.api}/workspace/coworking-parents/list`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers =>{
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data)
        )
      })
    )
  }

  getCoworkingParentInfo(id:string){
    const url = `${environment.api}/workspace/coworking-parent/${id}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers =>{
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data)
        )
      })
    )
  }

  officeApply(applyOffice: ApplyOfficeType) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/profile/my-office/apply`;
        const formData = new FormData();
        formData.append('coworkingChild', `${applyOffice.coworkingChild}`);
        formData.append('reference', applyOffice.reference);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      }),
    )
  }

  licenseApply(applyLicense:ApplyLicenseType){
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-license/add_license`;
        const formData = new FormData();
        formData.append('name', applyLicense.licenseName)
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }

  getOffices() {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-offices`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          tap(offices => this.profileFacade.loadExtraOffices(offices.extraOffices)),
          map((data: GetOfficesType) => {
            return [...data.myOffice];
          }),
          map(offices => offices.filter(office => office.status !== 'rejected' || office.active)),
          tap(offices => this.profileFacade.loadOffices(offices))
        )
      })
    )
  }

  getMyLicenses() {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-licenses`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as MyLicense[]),
          tap(licenses => this.profileFacade.loadLicenses(licenses))
        )
      })
    )
  }

  getLicenses() {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/licenses`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as LicenseType[]),
        )
      })
    )
  }

  initOfficeFormGroup() {
    return this.fb.group({
      parent: ['', Validators.required],
      coworkingChild: ['', Validators.required],
      reference: ['', [Validators.required, Validators.minLength(1)]]
    });
  }


  initLicenseFormGroup() {
    return this.fb.group({
      licenseName : ['', Validators.required]
    });
  }


  loadOffices = this.profileFacade.loadOffices;
}
