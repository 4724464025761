import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EditProfileData, Experience, Recommendation, AcademicInformation, Portfolio, PortfolioImage } from 'src/app/api.interfaces';
import { switchMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient,
  ) { }

  getBody(payload: any): string {
    const { id, ...payloadWithoutId } = payload;
    let body = ''
    for (let [key, value] of Object.entries(payloadWithoutId)) {
      body = `${body}${key}=${value}&`
    }
    body.slice(0, -1);
    return body;
  }

  put(url: string, token: string, payload: any) {
    const headers = new HttpHeaders({
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body = this.getBody(payload);
    return this.http.put(url, body, { headers });
  }

  loadCategories(token: string) {
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.get(`${environment.api}/profile/categories/list`, { headers })
  }


  loadProfile(token: string) {
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.get(`${environment.api}/profile`, { headers })
  }

  loadBusinessProfile(token: string, username: string) {
    const headers = new HttpHeaders({
      'Authorization': token,
      'x-switch-user': username
    });
    return this.http.get(`${environment.api}/profile`, { headers })
  }

  updateProfile(actualEmail:string,payloadToUpdate: EditProfileData, headers: HttpHeaders) {
    let payload = {};
    console.log(payloadToUpdate);

    const formData = new FormData();
    for (const [key, value] of Object.entries(payloadToUpdate)) {
      if (value !== null && key !== 'username') {
        payload[key] = value;
        formData.append(key, value as string);
      }
    }
    if(actualEmail === payloadToUpdate.email){
      formData.delete('email')
    }
    return this.http.post(`${environment.api}/profile/edit`, formData, { headers });
  }

  updateProfilePicture(image: string, headers: HttpHeaders) {
    const body = new FormData();
    image !== undefined ? body.append('profilePicture', image) : body.append('deleteProfilePicture','yes');
    return this.http.post(`${environment.api}/profile/edit`, body, { headers });
  }


  // Categories
  addCategory(idCategory: string, headers: HttpHeaders) {
    const body = new FormData();
    body.append('category', idCategory)
    body.append('action', 'put');
    return this.http.post(`${environment.api}/profile/manage-categories`, body, { headers });
  }

  removeCategory(idCategory: string, headers: HttpHeaders) {
    const body = new FormData();
    body.append('category', idCategory)
    body.append('action', 'dim');
    return this.http.post(`${environment.api}/profile/manage-categories`, body, { headers });
  }



  // Specialties
  addSpecialti(specialti: string, headers: HttpHeaders) {
    const formData = new FormData()
    formData.append('speciality', specialti)
    formData.append('action', 'put');
    return this.http.post(`${environment.api}/profile/manage-specialtis`, formData, { headers })
  }

  removeSpecialti(index: number, headers: HttpHeaders) {
    const formData = new FormData()
    formData.append('speciality', `${index}`)
    formData.append('action', 'dim');
    return this.http.post(`${environment.api}/profile/manage-specialtis`, formData, { headers })
  }



  // Portfolios
  addPortfolio(files: File[], payload: any, headers: HttpHeaders) {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('description', payload.description);

    if (files[0]) {
      formData.append('porfolio[image1]', files[0])
    }
    if (files[1]) {
      formData.append('porfolio[image2]', files[1])
    }
    if (files[2]) {
      formData.append('porfolio[image3]', files[2])
    }
    if (files[3]) {
      formData.append('porfolio[image4]', files[3])
    }
    if (files[4]) {
      formData.append('porfolio[image5]', files[4])
    }
    return this.http.post(`${environment.api}/profile/portfolio/new`, formData, { headers });
  }

  removePortfolio(id: number, headers: HttpHeaders) {
    return this.http.delete(`${environment.api}/profile/portfolio/delete/${id}`, { headers });
  }

  updatePortfolioInfo(id: number, payload: any, headers: HttpHeaders) {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('description', payload.description);
    return this.http.post(`${environment.api}/profile/portfolio/edit/${id}`, formData, { headers });
  }

  deleteAndUpdateImage(file: File, image: PortfolioImage, portfolio: Portfolio, headers: HttpHeaders) {
    const formData = new FormData();
    formData.append('imageId', image.sort);
    formData.append('image', file);
    return this.http.delete(`${environment.api}/profile/portfolio/image/delete/${portfolio.id}/${image.sort}`, { headers }).pipe(
      switchMapTo(this.http.post(`${environment.api}/profile/portfolio/image/add/${portfolio.id}`, formData, { headers }))
    );
  }
  updateImage(sort: number, file: File, portfolio: Portfolio, headers: HttpHeaders) {
    const formData = new FormData();
    formData.append('imageId', `${sort + 1}`);
    formData.append('image', file);
    return this.http.post(`${environment.api}/profile/portfolio/image/add/${portfolio.id}`, formData, { headers })
  }

  updatePortfolioPicture(sort: number, file: File, image: PortfolioImage, portfolio: Portfolio, headers: HttpHeaders) {
    if (image !== null) {
      return this.deleteAndUpdateImage(file, image, portfolio, headers);
    }
    return this.updateImage(sort, file, portfolio, headers);

  }


  // Academic Formations
  addAcademicFormation(academicInformations: AcademicInformation, headers: HttpHeaders) {
    const formData = new FormData()
    formData.append('institutionName', academicInformations.institutionName)
    formData.append('description', academicInformations.description);
    formData.append('from', academicInformations.from);
    formData.append('to', academicInformations.to);
    return this.http.post(`${environment.api}/profile/academic-formation/new`, formData, { headers });
  }

  updateAcademicFormation(id: number, academicInformations: AcademicInformation, token: string) {
    return this.put(`${environment.api}/profile/academic-formation/edit/${id}`, token, academicInformations);
  }

  removeAcademicFormation(id: number, headers: HttpHeaders) {
    return this.http.delete(`${environment.api}/profile/academic-formation/delete/${id}`, { headers });
  }


  // Experiences
  addExperience(experience: Experience, headers: HttpHeaders) {
    const formData = new FormData()
    formData.append('companyName', experience.companyName)
    formData.append('description', experience.description);
    formData.append('from', experience.from);
    formData.append('to', experience.to);
    return this.http.post(`${environment.api}/profile/experience/new`, formData, { headers })
  }

  updateExperience(id: number, experience: Experience, token: string) {
    return this.put(`${environment.api}/profile/experience/edit/${id}`, token, experience);
  }

  removeExperience(id: number, headers: HttpHeaders) {
    return this.http.delete(`${environment.api}/profile/experience/delete/${id}`, { headers })
  }




  // Recommendations
  addRecommendation(recommendation: Recommendation, headers: HttpHeaders) {
    const formData = new FormData();
    formData.append('author', recommendation.author);
    formData.append('file', recommendation.file as string);
    return this.http.post(`${environment.api}/profile/recommendation/new`, formData, { headers });
  }

  updateRecommendation(id: number, recommendation: Recommendation, headers: HttpHeaders) {
    const formData = new FormData();
    formData.append('author', recommendation.author);
    formData.append('file', recommendation.file as string);
    return this.http.post(`${environment.api}/profile/recommendation/edit/${id}`, formData, { headers });
  }

  removeRecommendation(id: number, headers: HttpHeaders) {
    return this.http.delete(`${environment.api}/profile/recommendation/delete/${id}`, { headers })
  }


}
