import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';

@Component({
  selector: 'app-woke-filters',
  templateUrl: './woke-filters.component.html',
  styleUrls: ['./woke-filters.component.scss']
})
export class WokeFiltersComponent implements OnInit {


  @Input() label = '';

  showOptions = false;
  maxHeight = 0;
  initial: any;

  darkMode$ = this.tokenFacade.darkMode$;

  constructor(
    private tokenFacade: TokenFacade
  ) { }

  ngOnInit() {}

  toggle() {
    this.showOptions = !this.showOptions;

  }

}
