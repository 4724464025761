import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-woke-field-label',
  templateUrl: './woke-field-label.component.html',
  styleUrls: ['./woke-field-label.component.scss']
})
export class WokeFieldLabelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
