import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WalletState } from './wallet.state';
import { paymentMethodsAdapter, PaymentMethodsState } from './payment-methods/payment-methods.state'
import { Dictionary } from '@ngrx/entity';
import { PaymentMethod } from 'src/app/api.interfaces';

export const walletSelector = createFeatureSelector('Wallet');

const paymentSelector = createSelector(
  walletSelector,
  (state: WalletState) => state.paymentMethods
);

export const allPaymentMethods = createSelector(
  paymentSelector,
  paymentMethodsAdapter.getSelectors().selectAll
);

export const selectPayment = createSelector(
  paymentSelector,
  (entities: Dictionary<PaymentMethod>, id: number) => entities.entities[id]
);

export const selectPaymentDefault = createSelector(
  paymentSelector,
  (state: PaymentMethodsState) => state.paymentDefault
);

export const selectPaymentDefaultEntity = createSelector(
  selectPaymentDefault,
  paymentSelector,
  (paymentDefault: string, dictionary: Dictionary<PaymentMethod>) => {
    if (!paymentDefault) {
      if (!dictionary.entities || !Object.values(dictionary.entities).length) {
        return undefined;
      }
      return Object.values(dictionary.entities)[0];
    }
    return dictionary.entities[paymentDefault];
  }
)
