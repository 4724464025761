import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { QuickBookingState } from './quick.state';
import { QuickBookingActions } from './quick.actions';
import { bookingSelector, quickModelSelector, bookingInfoSelector, bookingTypeSelector } from './quick.selectors';
import { QuickModel } from 'src/app/logged/workspaces/workspace/quick/quick.model';
import { CheckoutWorkspaceViewModel } from 'src/app/logged/wallet/checkout-workspace/checkout-workspace/checkout-workspace.view-model';
import { BookingInfo } from 'src/app/logged/workspaces/workspace/models/workspaces.models';

@Injectable()
export class QuickBookingFacade {

  booking$ = this.store.pipe(
    select(bookingSelector)
  )

  quickModel$ = this.store.pipe(
    select(quickModelSelector)
  )

  bookingInfo$ = this.store.pipe(
    select(bookingInfoSelector)
  )

  bookingType$ = this.store.pipe(
    select(bookingTypeSelector)
  )

  constructor(private store: Store<QuickBookingState>) { }

  bookingProcess(workspaceId: string, checkoutViewModel: CheckoutWorkspaceViewModel) {
    this.store.dispatch(QuickBookingActions.bookingProcess({ workspaceId, checkoutViewModel }));
  }

  setQuickModel(quickModel: QuickModel) {
    this.store.dispatch(QuickBookingActions.setQuickModel({ quickModel }));
  }

  setBookingInfo(bookingInfo: BookingInfo) {
    this.store.dispatch(QuickBookingActions.setBookingInfo({ bookingInfo }));
  }

}
