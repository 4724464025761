import { createAction, props } from '@ngrx/store';
import { CoworkingChild } from 'src/app/api.interfaces';

export const CoworkingChildsActions = {
  load: createAction(
    `[API] Load Coworking Childs`,
  ),
  loadError: createAction(
    `[API] Load Coworking Childs Error`,
  ),
  loadSuccess: createAction(
    `[API] Load Coworking Childs Success`,
    props<{ items: CoworkingChild[] }>()
  ),
  loadOneSuccess: createAction(
    `[API] Load One Coworking Child Success`,
    props<{ item: CoworkingChild }>()
  ),
  loadOne: createAction(
    `[API] Load One Coworking Child`,
    props<{ id: number }>()
  ),
  loadOneError: createAction(
    `[API] Load One Coworking Child Error`,
  ),
  loadGallery: createAction(
    `[API] Load Coworking Child Gallery`,
    props<{ id: number }>()
  ),
  loadGallerySuccess: createAction(
    `[API] Load Coworking Child Gallery Success`,
    props<{ items: { image: string }[], id: number }>()
  ),
  loadGalleryError: createAction(
    `[API] Load Coworking Child Gallery Error`
  )
}
