import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TokenFacade } from 'src/store/token/token.facade';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  language:string;
  androidBadge:string;
  iosBadge:string;

  killall = new Subject();

  constructor(private tokenFacade: TokenFacade,private translate: TranslateService) { }

  ngOnInit(): void {
    this.tokenFacade.language$.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: lan =>{
        this.language = lan
        this.getImgSrc(lan)
      }
    })
  }

  ngOnDestroy(){
    this.killall.next();
    this.killall.complete();
  }

  getImgSrc(lan:string){
    this.language === 'en' ? this.androidBadge = '/assets/img/logos/googleplay_en.png'
    : this.androidBadge = '/assets/img/logos/googleplay_esp.png'

    this.language === 'en' ? this.iosBadge = '/assets/img/logos/appstore_en_black.svg'
    : this.iosBadge = '/assets/img/logos/appstore_esp_black.svg'

  }

  switchLanguage(){
    let lang = this.language == 'en' ? 'es' : 'en'
    this.tokenFacade.switchLanguage(lang);
    this.translate.use(lang);
  }

}
