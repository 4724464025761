import { Component, OnInit, Input } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';


@Component({
  selector: 'app-woke-card',
  templateUrl: './woke-card.component.html',
  styleUrls: ['./woke-card.component.scss']
})
export class WokeCardComponent implements OnInit {

  @Input() buttonMode:boolean = true;

  darkMode$ = this.tokenFacade.darkMode$;

  constructor(private tokenFacade: TokenFacade) { }

  ngOnInit() {
  }

}
