import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MyOffice, PaymentMethod, Workspace, ExtraOffice, ParsedOfficesLicenses } from 'src/app/api.interfaces';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggedService } from '../../logged.service';
import { QuickBookingFacade } from 'src/store/quick/quick.facade';
import { take, takeUntil, map, switchMap, filter, switchMapTo, tap } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { CoworkingSpaceService } from '../../workspaces/coworking-space/coworking-space/coworking-space.service';
import { ProfileFacade } from 'src/store/profile/profile.facade';
import { QuickModel } from '../../workspaces/workspace/quick/quick.model';
import { CheckoutWorkspaceViewModel } from '../../wallet/checkout-workspace/checkout-workspace/checkout-workspace.view-model';
import { CheckoutWorkspaceService } from '../../wallet/checkout-workspace/checkout-workspace/checkout-workspace.service';
import { BookingInfo } from '../../workspaces/workspace/models/workspaces.models';
import { environment } from 'src/environments/environment'
import { TokenFacade } from 'src/store/token/token.facade';
import { BookService } from '../../workspaces/book/book/book.service';
import {saveAs} from 'file-saver';
@Component({
  selector: 'app-checkout-booking',
  templateUrl: './checkout-booking.component.html',
  styleUrls: ['./checkout-booking.component.scss']
})
export class CheckoutBookingComponent implements OnInit, OnDestroy {

  loadingCheckout = true;
  paying = false;
  bookingInfo:BookingInfo;
  language:string
  creditsNeeded;
  apiUrl = environment.resourceUrl
  selectedOffice:ParsedOfficesLicenses
  trueCapacity:number
  isInternal:string;
  validCode = false;
  codes = [];
  copyBookingInfo:BookingInfo
  terms:string = ''
  checkTerms:boolean = false;

  // Booking Detail
  quickModel$ = this.quickFacade.quickModel$;
  bookingType$ = this.quickFacade.bookingType$;
  offices$ = this.profileFacade.officesValid$;
  extraoffices$ = this.profileFacade.extraoffices$;
  licenses$ = this.profileFacade.licenses$;
  bookingType: string;
  quickModel: QuickModel;
  workspace: Workspace;
  applying = false;
  language$ = this.tokenFacade.language$

  // Event Name
  eventNameControl: FormControl;

  // Terms and Conditions

  termsControl: FormControl;

  // Promo Code

  promoCode: FormControl;
  code



  // Use Bplan Switch
  useBusinessPlan = false;



  // * pay with money

  // downpayment
  bookingInfo$ = this.quickFacade.bookingInfo$;
  // payment method
  paymentMethod: PaymentMethod = undefined;
  // invoice
  invoice = false;
  cfdi: number;



  // * Pay with office

  // select office
  offices:ParsedOfficesLicenses[];
  officeControl: FormControl;

  // total office hours
  officeTotal: {
    text?: string;
    remainingCredits?: string | number;
    credits_limit?:string | number;
  };



  // Invites
  invites: string[] = [];

  killall = new Subject();

  canUseCode = true;
  canUsePlan = true;
  switchPlanDisable = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private coworkingService: CoworkingSpaceService,
    public loggedService: LoggedService,
    private quickFacade: QuickBookingFacade,
    private profileFacade: ProfileFacade,
    private tokenFacade : TokenFacade,
    private bookService: BookService,
    private checkoutWorkspaceService: CheckoutWorkspaceService
  ) { }

  getRemainingHours(id) {
    return this.offices.find(office => office.id === id);
  }

  ngOnInit() {
    this.eventNameControl = this.fb.control('');
    this.termsControl = this.fb.control('');
    this.promoCode = this.fb.control('');
    this.officeControl = this.fb.control('');
    combineLatest([this.bookingInfo$,this.language$]).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: ([info,lang]) =>{
        this.bookingInfo = info;

        this.language = lang
      },
      error: err => console.log(err)
    })

    this.officeControl.valueChanges.pipe(
      map(id => this.getRemainingHours(id)),
      filter(officeFound => officeFound !== undefined),
      tap(office => this.selectedOffice = office),
      map(officeFound => this.getTotal(officeFound)),
      takeUntil(this.killall)
    ).subscribe(total => {
      this.officeTotal = total;
      // console.log(this.officeTotal, "officetotal");

    });


    // Load workspace detail
    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap(id => this.coworkingService.loadWorkspaceDetail(id)),
      take(1),
      takeUntil(this.killall),
    ).subscribe({
      next: (workspace: Workspace) => {
        this.workspace = workspace;
        this.terms = workspace.coworkingChild.terms
        this.checkInternal(this.workspace.coworkingParent.id)
        this.trueCapacity = workspace.capacity - 1
        this.initQuickModel();
        this.loadingCheckout = false;
      },
      error: error => {
        this.loadingCheckout = false;
        console.warn(error);
      }
    });


  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
    this.loggedService.closeAll();
  }

  onWorkspace(workspace: Workspace) {
    this.workspace = workspace;
    this.loadingCheckout = false;
  }

  /**
   * Apply promo code
  */

  onApply(){
    // if(this.code !== ''){
    //   this.code = this.promoCode.value
    //   this.codes.push(this.code)
    // }else{
    //   this.codes.pop()
    //   this.code = this.promoCode.value
    //   this.codes.push(this.code)
    // }

    this.applying = true;
    //* Como solo se puede usar un código
    if(this.codes.length === 1){
      this.promoCode.patchValue('')
      this.applying = false;
      return this.loggedService.showError(this.language === 'en' ? 'You can only use one code' : 'Sólo puedes usar un código')
    }
    const infoCode = {
      office : this.isInternal,
      id : this.workspace.id,
      timeSelected: this.quickModel.hoursordays,
      code: this.promoCode.value
    }

    this.bookService.getTotalPromoCode(infoCode).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: (bookingInfo: BookingInfo) =>{
        //* Guardamos una copia de la info anterior por si el código se elimina
        this.copyBookingInfo = this.bookingInfo;
        //* Guardamos el código
        this.code = this.promoCode.value;
        this.quickFacade.setBookingInfo(bookingInfo);
        this.bookingInfo = bookingInfo
        console.log(this.bookingInfo);
        this.codes.push(infoCode.code)
        this.promoCode.patchValue('')
        this.applying = false;

        this.validCode = true;
        this.switchPlanDisable = true;
      },
      error: err =>{
        this.promoCode.patchValue('')
        console.log(err);
        this.loggedService.showError(this.language === 'en' ? 'Invalid Code' : 'Código inválido')
        this.applying = false;
      }
    })

  }

  removeCode(i){
    this.validCode = false;
    this.switchPlanDisable = false;
    this.codes.splice(i,1)
    this.bookingInfo = this.copyBookingInfo
    this.quickFacade.setBookingInfo(this.bookingInfo);
    this.code = ''
  }

  /**
   * get the payment method
   */
  onPaymentMethodChange(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  /**
   * Get the invoice
   */
  onInvoiceChange(invoice: boolean) {
    this.invoice = invoice;
  }

  onCfdiChange(cfdi: any) {
    this.cfdi = cfdi;
  }

  /**
   * Get the invites
   */
  onInvites(invites: string[]) {
    this.invites = invites;
  }


  /**
   * Confirm the checkout
   */
  confirm(template: TemplateRef<any>) {

    const checkoutPayload = {
      quickModel: this.quickModel,
      nameBooking: this.eventNameControl.value,
      specialRequest:'',
      invites: this.invites,
      useBusinessPlan: this.useBusinessPlan,
      paymentMethod: this.paymentMethod,
      isOffice: this.selectedOffice ? this.selectedOffice.isOffice : false,
      planId:this.officeControl.value,
      total: this.officeTotal,
      invoice: this.invoice,
      cfdi: this.cfdi,
      promoCode: this.code
    };

    const checkout = new CheckoutWorkspaceViewModel(checkoutPayload);
    // console.log(checkout, "checkoutpayload");

    if (!checkout.isValid(this.creditsNeeded)) {
      // console.log(this.creditsNeeded, "credits needed");

      const error = checkout.getError(this.creditsNeeded, this.language);
      console.log("error", error);

      return this.loggedService.showError(error);
    }

    if (!this.paying) {
      this.paying = true;
      if (checkout.isBooking()) {
        // console.log("isbooking -> checkoutProcess");
        // para los requested
        this.checkoutWorkspaceService.checkoutProcess(this.workspace, checkout, this.invites).pipe(
          take(1),
          takeUntil(this.killall)
        ).subscribe({
          next: data => {
            this.paying = false;
            if (!data.error) {
              return this.loggedService.openModal(template);
            } else {
              // Error
              console.warn(data.error);
              this.loggedService.showError(data.msg);
            }
          },
          error: error => {
            console.warn(error);
            this.paying = false;
            this.loggedService.showError(error);
          }
        })
      } else {
        // console.log("isnotbooking -> bookingProcess");
        this.checkoutWorkspaceService.bookingProcess(this.workspace, checkout, this.invites).pipe(
          take(1),
          takeUntil(this.killall)
        ).subscribe({
          next: data => {
            this.paying = false;
            if (!data.error) {
              return this.loggedService.openModal(template);
            } else {
              // Error
              console.warn(data.error);
              this.loggedService.showError(data.msg);
            }
          },
          error: error => {
            console.warn(error);
            this.paying = false;
            this.loggedService.showError(error);
          }
        })
      }
    }
  }

  checkInternal(cwparentid){
    this.bookService.getOffices().pipe(
      takeUntil(this.killall)
    ).subscribe({
      next : offices =>{


        let filteroffices = offices.offices.filter( office => {
          if(office.coworkingParent.id == cwparentid){
            return office
          }
        });
        let filterextra = offices.extraOffices.filter( extraoffice => {
          if(extraoffice.coworkingParent.id == cwparentid){
            return extraoffice
          }
        });

        let officesP = [...filteroffices,...filterextra]

        // * Si hay oficina que haga interno al usuario sólo necesitamos el id de una para pasarlo al endpoint
        this.isInternal = officesP.length ? `${officesP[0].id}` : ''
      }
    })
  }

  goToWorkspace() {
    this.router.navigate(['/workspace']);
    this.loggedService.closeModal()
  }

  modelChange(e){
    if(e){
      this.canUseCode = false;
      this.codes = []
      this.code = ''
      this.officeControl.patchValue('')
    }else{
      this.canUseCode = true;
    }
  }




  getTotal(plan:ParsedOfficesLicenses ) {
    if (!plan) {
      return undefined;
    }
    const complement = plan.remainingCredits;
    let credits = (+(+this.bookingInfo.internalPrice / +this.bookingInfo.currency_value).toFixed(2) * this.quickModel.hoursordays)
    const creditsSelected =  this.bookingInfo.type == "Other" ? this.bookingInfo.cost.subTotal : credits;
    this.creditsNeeded = creditsSelected;
    return {
      //text: `-${timeSelected} of ${complement} hours`,
      text: `-${creditsSelected} of ${complement} credits`,
      remainingCredits: complement,
      credits_limit: this.selectedOffice.credits_limit === undefined ? 0 + +complement : this.selectedOffice.credits_limit + +complement
    }
  }

  parseOfficeLicenseItem(item){
    const items = item.map(intj => {
      if(intj.officeNumber){
        return {
          id: intj.id,
          name: `Office ${intj.officeNumber}`,
          credits_limit:intj.credit_limit,
          remainingCredits:+intj.remainingCredits,
          isOffice: true
        }
      }
      if(intj.workspace){
        return {
          id: intj.id,
          name: `Office ${intj.workspace.name}`,
          remainingCredits: +intj.remainingCredits,
          isOffice: true
        }
      }
      if(intj.qr.includes('UL')){
        return {
          id: intj.id,
          name: `License ${intj.name}`,
          remainingCredits: intj.remainingCredits,
          isOffice: false
        }
      }
    })
    return items
  }

  initQuickModel() {
    combineLatest(
      [this.quickModel$,
      this.bookingType$,
      this.offices$,
      this.extraoffices$,
      this.licenses$]
    ).pipe(
      take(1),
      takeUntil(this.killall),
    ).subscribe({
      next: ([quickModel, bookingType, offices, extraoffices, licenses]) => {
        if (quickModel === undefined) {
          return this.router.navigate(['workspace/'])
        }

        this.bookingType = bookingType;
        this.quickModel = quickModel;


        let filterofices = offices.filter( office => {
          if(office.coworkingParent.id == this.workspace.coworkingParent.id){
            return office
          }
        });
        let filterextra = extraoffices.filter( extraoffice => {
          if(extraoffice.coworkingParent.id == this.workspace.coworkingParent.id){
            return extraoffice
          }
        });

        let plans = [...filterofices,...filterextra,...licenses];
        this.offices = this.parseOfficeLicenseItem(plans)
        console.log(this.offices, "oficinas y licencias");
        console.log(this.quickModel, "quickmodel");


      },
      error: error => {
        console.warn(error);
        this.loggedService.showError(error);
      }
    });
  }

  parseUrl = (url: string) => environment.api.includes('stage') ? `${environment.resource}${url}` : `${environment.resourceUrl}${url}`
  openLink = (url: string) => {
    const win = window.open(url, '_blank');
    return win.focus();
  }

  showTerms(){
    const pdf = this.parseUrl(this.terms);
    saveAs(pdf, 'file.pdf');
  }

  changeTerms(value){
    this.checkTerms = !this.checkTerms;
    console.log(this.checkTerms);
  }



}
