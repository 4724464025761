import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LicenseComponent } from './license/license.component';
import { NetworkComponent } from './network/network.component';
import { OfficeComponent } from './office/office.component';
import { CheckoutBookingComponent } from './checkout-booking/checkout-booking.component';


const routes: Routes = [
  {
    path: 'license/:id',
    component: LicenseComponent
  },
  {
    path: 'network/:id',
    component: NetworkComponent
  },
  {
    path: 'office/:id',
    component: OfficeComponent
  },
  {
    path: 'booking/:id',
    component: CheckoutBookingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule { }
