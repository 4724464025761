import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap, switchMapTo, withLatestFrom } from 'rxjs/operators';
import { TokenActions } from './token.actions';
import { TokenService } from './token.service';
import { ProfileActions } from '../profile/profile.actions';
import { ProfileFacade } from '../profile/profile.facade';
import { MetaResponse } from 'src/app/api.interfaces';
import { TokenFacade } from './token.facade';

@Injectable()
export class TokenEffects {

  @Effect() refreshToken$: Observable<Action> = this.actions$.pipe(
    ofType(TokenActions.refreshToken),
    switchMap(({ token }) => {
      return this.api.refreshToken(token).pipe(
        tap(() => console.log('Refresh')),
        map((response) => TokenActions.refreshTokenSuccess({ response })),
        catchError(() => of(TokenActions.refreshTokenError()))
      )
    })
  );

  @Effect() refreshSuccess$ = this.actions$.pipe(
    ofType(TokenActions.refreshTokenSuccess),
    switchMap(() => {
      return of(ProfileActions.load())
    })
  )

  // cargar los onboardings justo después de que el perfil se ha cargado
  // @Effect() loadOnboardings$ = this.actions$.pipe(
  //   ofType(ProfileActions.loadSuccess),
  //   switchMapTo(this.profileFacade.profileId$),
  //   switchMap(id => this.api.loadOnboardings(id).pipe(
  //     map((response: MetaResponse) => response.data),
  //     map(data => data.onboardings),
  //     map(onboardings => onboardings.sections as string[]),
  //     map(sections => TokenActions.loadOnboardingsSuccess({ sections })),
  //     catchError(() => of(TokenActions.loadOnboardingsError()))
  //   ))
  // );

  @Effect() closeOnboarding$ = this.actions$.pipe(
    ofType(TokenActions.closeOnboarding),
    withLatestFrom(this.profileFacade.profileId$),
    withLatestFrom(this.tokenFacade.currentOnboarding$),
    switchMap(([[_, id], current]) => this.api.finishOnboarding(current.name, id).pipe(
      map((response: MetaResponse) => response.data),
      map(data => data.onboardings),
      map(onboardings => onboardings.sections as string[]),
      map(sections => TokenActions.loadOnboardingsSuccess({ sections })),
      catchError(() => of(TokenActions.loadOnboardingsError()))
    ))
  )

  constructor(
    private actions$: Actions,
    private api: TokenService,
    private profileFacade: ProfileFacade,
    private tokenFacade: TokenFacade
  ) { }
}
