import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { WalletState } from './wallet.state';
import { PaymentMethodsActions } from './payment-methods/payment-methods.actions';
import { allPaymentMethods, selectPaymentDefaultEntity } from './wallet.selectors';
import { PaymentMethod, MetaResponse } from 'src/app/api.interfaces';
import { Observable } from 'rxjs';
import { WalletService } from './wallet.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class WalletFacade {

  paymentMethods$ = this.store.pipe(
    select(allPaymentMethods)
  );

  paymentDefault$: Observable<PaymentMethod> = this.store.pipe(
    select(selectPaymentDefaultEntity)
  );

  constructor(
    private store: Store<WalletState>,
    private walletService: WalletService,
  ) { }

  addPaymentMethod(tokenId: string) {
    return this.walletService.newPaymentMethod(tokenId).pipe(
      map((response: MetaResponse) => response.data as PaymentMethod[]),
      tap(items => this.loadPaymentMethodsData(items))
    )
  }

  loadPaymentMethodsData(items: PaymentMethod[]) {
    this.store.dispatch(PaymentMethodsActions.loadSuccess({ items }))
  }

  loadPaymentMethods() {
    this.store.dispatch(PaymentMethodsActions.load());
  }

  updatePaymentMethod(item: PaymentMethod) {
    this.store.dispatch(PaymentMethodsActions.update({ item }));
  }

  deletePaymentMethod(id: string) {
    this.store.dispatch(PaymentMethodsActions.delete({ id }));
  }

  setPaymentMethodDefault(paymentDefault: string) {
    this.store.dispatch(PaymentMethodsActions.setPaymentDefault({ paymentDefault }))
  }

}
