import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormControl, FormBuilder } from '@angular/forms';
import { MyOffice } from 'src/app/api.interfaces';
import { Subject, of } from 'rxjs';
import { MyOfficeService } from 'src/app/logged/my-office/my-office.service';
import { LoggedService } from 'src/app/logged/logged.service';
import { TokenFacade } from 'src/store/token/token.facade';
import { tap, auditTime, switchMap, catchError, takeUntil, take, switchMapTo, map, filter } from 'rxjs/operators';
import { MyLicense } from 'src/app/logged/my-license/my-license.types';
import { MyLicenseService } from 'src/app/logged/my-license/my-license.service';


export class UserSearched {
  email: string;
  firstName: string;
  id: number;
  image: string;
  lastName: string;
  username: string;
  status:boolean;
  selected = false;
  constructor(payload: any) {
    this.email = payload.email;
    this.firstName = payload.firstName;
    this.id = payload.id;
    this.image = payload.image ? environment.resourceUrl + payload.image : 'assets/img/utils/Profile.png';
    this.lastName = payload.lastName;
    this.username = payload.username;
    this.status = payload.status;
  }

  active() {
    this.selected = true;
  }

  reset() {
    this.selected = false;
  }

}

@Component({
  selector: 'app-woke-search-select',
  templateUrl: './woke-search-select.component.html',
  styleUrls: ['./woke-search-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WokeSearchSelectComponent implements OnInit, OnDestroy  {
  searched = false;
  searching = false;
  saving = false;
  language:string
  teamMemberControl: FormControl;
  usersFound: UserSearched[] = [];
  currentUser: UserSearched;
  @Input() office: MyOffice;
  @Input() license: MyLicense;
  @Output() memberAdded: EventEmitter<MyOffice>  = new EventEmitter();

  killall = new Subject();

  constructor(
    private fb: FormBuilder,
    private myOfficeService: MyOfficeService,
    private myLicenseService:MyLicenseService,
    private loggedService: LoggedService,
    private tokenFacade: TokenFacade,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(){
    this.teamMemberControl = this.fb.control('');
    this.getLanguage();
    const changes$ = this.teamMemberControl.valueChanges;
    changes$.pipe(
      tap(() => {this.searching = true, this.currentUser = undefined}),
      auditTime(300),
      tap(search => this.emptyArray(search)),
      filter(search => search !== ""),
      switchMap(teamMember =>
        this.myOfficeService.searchUser(teamMember)
      ),
      map((users: any[]) => ({ users: users.map(user => new UserSearched(user)), searched: true })),
      takeUntil(this.killall)
    ).subscribe({
      next: ({users, searched}) => {
        this.searching = false;
        this.searched = searched;
        this.usersFound = users.filter(user => user.status === true);
        this.cd.markForCheck();
        console.log(this.usersFound);

      },
      error: error => {
        console.warn(error);
        this.searching = false;
        this.searched = true;
        this.usersFound = [];
      }
    })
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  emptyArray(member){
    if(member === "") {
      this.usersFound = []
      this.searched = false;
      this.searching = false;
      this.cd.markForCheck();
    }
  }

  getLanguage(){
    this.tokenFacade.language$.pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: lan => {
        this.language = lan
      },
      error: err =>{
        console.log(err);
      }
    })
  }

  addUserConfirm() {
    if (!this.currentUser) {
      return this.loggedService.showError(this.language === 'en'?'Please select a user':'Por favor seleccione un usuario.');
    }
    if (this.license && this.license.members && this.license.members.find(member => member.id === this.currentUser.id) ) {
      return this.loggedService.showError(this.language === 'en'?'This user is already a member':'Este usuario ya es un miembro');
    }
    if (this.office && this.office.members && this.office.members.find(member => member.id === this.currentUser.id) ) {
      return this.loggedService.showError(this.language === 'en'?'This user is already a member':'Este usuario ya es un miembro');
    }
    this.saving = true;
    this.office ? this.myOfficeService.addTeamMember(this.office, this.currentUser.id):this.myLicenseService.addTeamMember(this.license, this.currentUser).pipe(
      switchMapTo(this.office ? this.myOfficeService.getOfficeDetail(`${this.office.id}`):this.myLicenseService.loadMyLicenseDetail(`${this.license.id}`)),
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: response => {
        this.memberAdded.emit(response);
        this.saving = false;
      },
      error: error => {
        console.warn(error);
        this.saving = false;
        this.loggedService.showError(error);
      }
    })
  }

  selectUser(user: UserSearched) {
    for (const userFound of this.usersFound) {
      userFound.reset();
    }
    user.active();
    this.currentUser = user;
  }

}
