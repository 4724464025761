import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './token.reducer';
import { TokenFacade } from './token.facade';
import { EffectsModule } from '@ngrx/effects';
import { TokenEffects } from './token.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('Token', reducer),
    EffectsModule.forFeature([TokenEffects])
  ],
  providers: [
    TokenFacade
  ]
})
export class TokenStoreModule { }
