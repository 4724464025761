import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TokenState } from './token.state';
import { HttpHeaders } from '@angular/common/http';
import { ProfileState } from '../profile/profile.state';
import { ProfileData } from 'src/app/api.interfaces';
import { environment } from 'src/environments/environment';

export const profileFeatureSelector = createFeatureSelector('Profile');
export const tokenFeatureSelector = createFeatureSelector('Token');

export const tokenValueSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.value as string
);

export const darkModeSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.darkMode
);

export const languageSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.language
);

export const businessProfileSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.businessProfile
);


export const personalProfileSelector = createSelector(
  profileFeatureSelector,
  (state: ProfileState) => state.data
);

export const businessProfileDataSelector = createSelector(
  profileFeatureSelector,
  (state: ProfileState) => state.businessProfile
);


export const youreInProfileSelector = createSelector(
  tokenValueSelector,
  businessProfileSelector,
  (token, businessProfile) => {
    if (!token) {
      return undefined;
    }
    if (!businessProfile) {
      return 'PERSONAL';
    }
    return 'BUSINESS'
  }
);

export const canSwitchSelector = createSelector(
  tokenValueSelector,
  businessProfileDataSelector,
  (token, profile) => {
    if (!token || !profile || !profile.switch || profile.role !== 'ROLE_BUSINESS_ADMIN') {
      return false;
    }
    return true;
  }
);

export const canCreateBusiness = createSelector(
  tokenValueSelector,
  personalProfileSelector,
  (token, profile) => {
    if (!token) {
      return false;
    }

    const role = profile && profile.role ? profile.role : '';
    if (role === "ROLE_BUSINESS") {
      return false;
    }
    return true;
  }
);

export const switchProfileDataSelector = createSelector(
  profileFeatureSelector,
  businessProfileSelector,
  (state: ProfileState, businessProfile) => businessProfile === true ? state.data : state.businessProfile
);

export const switchImageSelector = createSelector(
  switchProfileDataSelector,
  (state: ProfileData) => state && state.userInformation && state.userInformation.image ? environment.resourceUrl + state.userInformation.image : ``
)



export const headersSelector = createSelector(
  tokenValueSelector,
  businessProfileSelector,
  personalProfileSelector,
  (token, businessProfile, profile) => {
    if (!token) {
      return undefined;
    }
    if (businessProfile === false) {
      return new HttpHeaders({ 'Authorization': token, })
    } else {
      const businessUsername = profile && profile.business ? profile.business.username : '';
      if (businessUsername === '') {
        return undefined;
      } else {
        return new HttpHeaders({
          'Authorization': token,
          'x-switch-user': businessUsername
        })
      }
    }
  }
)

export const onboardingsSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.onboardings
);

export const currentOnboardingSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.currentOnboarding ? state.currentOnboarding : null
);

export const currentOnboardingIndexSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.currentOnboardingIndex
);

export const currentSectionSelector = createSelector(
  currentOnboardingSelector,
  currentOnboardingIndexSelector,
  (onboarding, index) => onboarding && onboarding.sections && onboarding.sections.length ? onboarding.sections[index] : null
);

export const isOnboardingFinishedSelector = createSelector(
  currentOnboardingSelector,
  currentOnboardingIndexSelector,
  (onboarding, index) => {
    if (onboarding && onboarding.sections && onboarding.sections.length && index === onboarding.sections.length - 1) {
      return true;
    }
    return false;
  }
);

export const onboardingClosedSelector = createSelector(
  tokenFeatureSelector,
  (state: TokenState) => state.onboardingClosed
);

export const canShowOnboardingSelector = createSelector(
  onboardingsSelector,
  onboardingClosedSelector,
  currentOnboardingSelector,
  (onboardings, onboardingClosed, currentOnboarding) => {
    if (
      !onboardingClosed &&
      currentOnboarding &&
      currentOnboarding.name &&
      onboardings
    ) {
      if (onboardings.find(onboarding => onboarding === currentOnboarding.name)) {
        return false;
      }
      return true;
    }
    return false;
  }
);
