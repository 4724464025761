import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WokeBookingComponent } from './woke-booking/woke-booking.component';
import { WokeBookingsComponent } from './woke-bookings/woke-bookings.component';
import { WokeCardComponent } from './woke-card/woke-card.component';
import { RouterModule } from '@angular/router';
import { WokeEventComponent } from './woke-event/woke-event.component';
import { WokeListItemComponent } from './woke-list-item/woke-list-item.component';
import { WokeSubsectionComponent } from './woke-subsection/woke-subsection.component';
import { WokeSubsectionTitleComponent } from './woke-subsection-title/woke-subsection-title.component';
import { WokeSubsectionActionsComponent } from './woke-subsection-actions/woke-subsection-actions.component';
import { WokeRatingComponent } from './woke-rating/woke-rating.component';
import { WokeTitleComponent } from './woke-title/woke-title.component';
import { WokeSubtitleComponent } from './woke-subtitle/woke-subtitle.component';
import { WokeCircleComponent } from './woke-circle/woke-circle.component';
import { WokeInputComponent } from './woke-input/woke-input.component';
import { WokeFiltersComponent } from './woke-filters/woke-filters.component';
import { WokeRatingsComponent } from './woke-ratings/woke-ratings.component';
import { WokeBalanceComponent } from './woke-balance/woke-balance.component';
import { WokeFieldLabelComponent } from './woke-field-label/woke-field-label.component';
import { WokeSectionComponent } from './woke-section/woke-section.component';
import { WokeSectionActionsComponent } from './woke-section-actions/woke-section-actions.component';
import { WokeChatItemComponent } from './woke-chat-item/woke-chat-item.component';
import { WokeChatMessageComponent } from './woke-chat-message/woke-chat-message.component';
import { WokeHeightDirective } from './woke-height.directive';
import { WokeRadioComponent } from './woke-radio/woke-radio.component';
import { WokeAccordeonComponent } from './woke-accordeon/woke-accordeon.component';
import { WokeProgressBarComponent } from './woke-progress-bar/woke-progress-bar.component';
import { WokeCommentsComponent } from './woke-comments/woke-comments.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WokeFileComponent } from './woke-file/woke-file.component';
import { WokeTimepickerComponent } from './woke-timepicker/woke-timepicker.component';
import { WokeDatepickerComponent } from './woke-datepicker/woke-datepicker.component';
import { NgxBootstrapModule } from '../externalModules/ngx-bootstrap.module';
import { WokeTagComponent } from './woke-tag/woke-tag.component';
import { UpcomingWorkspaceComponent } from './upcoming-workspace/upcoming-workspace.component';
import { UpcomingEventComponent } from './upcoming-event/upcoming-event.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { WokeOfficeDetailComponent } from './woke-office-detail/woke-office-detail.component';
import { QRCodeModule } from 'angularx-qrcode';
import { WokeSelectComponent } from './woke-select/woke-select.component';
import { WokeYoutubeComponent } from './woke-youtube/woke-youtube.component';
import { WokeTermsComponent } from './woke-terms/woke-terms.component';
import { OnboarderComponent } from './onboarder/onboarder.component';
import { WokeCarouselComponent } from './woke-carousel/woke-carousel.component';
import {TranslateModule} from '@ngx-translate/core';
import { WokeSearchSelectComponent } from './woke-search-select/woke-search-select.component';


@NgModule({
  declarations: [WokeBookingComponent, WokeBookingsComponent, WokeCardComponent, WokeEventComponent, WokeListItemComponent, WokeSubsectionComponent, WokeSubsectionTitleComponent, WokeSubsectionActionsComponent, WokeRatingComponent, WokeTitleComponent, WokeSubtitleComponent, WokeCircleComponent, WokeInputComponent, WokeFiltersComponent, WokeRatingsComponent, WokeBalanceComponent, WokeFieldLabelComponent, WokeSectionComponent, WokeSectionActionsComponent, WokeChatItemComponent, WokeChatMessageComponent, WokeHeightDirective, WokeRadioComponent, WokeAccordeonComponent, WokeProgressBarComponent, WokeCommentsComponent, WokeFileComponent, WokeTimepickerComponent, WokeDatepickerComponent, WokeTagComponent, UpcomingWorkspaceComponent, UpcomingEventComponent, SpinnerComponent, WokeOfficeDetailComponent, WokeSelectComponent, WokeYoutubeComponent, WokeTermsComponent, OnboarderComponent, WokeCarouselComponent, WokeSearchSelectComponent],
  exports: [TranslateModule,WokeBookingComponent, WokeBookingsComponent, WokeCardComponent, WokeEventComponent, WokeListItemComponent, WokeSubsectionComponent, WokeSubsectionTitleComponent, WokeSubsectionActionsComponent, WokeRatingComponent, WokeTitleComponent, WokeSubtitleComponent, WokeCircleComponent, WokeInputComponent, WokeFiltersComponent, WokeRatingsComponent, WokeBalanceComponent, WokeFieldLabelComponent, WokeSectionComponent, WokeSectionActionsComponent, WokeChatItemComponent, WokeChatMessageComponent, WokeHeightDirective, WokeRadioComponent, WokeAccordeonComponent, WokeProgressBarComponent, WokeCommentsComponent, WokeFileComponent, WokeTimepickerComponent, WokeDatepickerComponent, WokeTagComponent, UpcomingWorkspaceComponent, UpcomingEventComponent, SpinnerComponent, WokeOfficeDetailComponent, WokeSelectComponent, WokeYoutubeComponent, WokeTermsComponent, OnboarderComponent, WokeCarouselComponent, WokeSearchSelectComponent],
  imports: [
    CommonModule,
    NgxBootstrapModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    TranslateModule
  ]
})
export class WokeModule { }
