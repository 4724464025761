import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-woke-circle',
  templateUrl: './woke-circle.component.html',
  styleUrls: ['./woke-circle.component.scss']
})
export class WokeCircleComponent implements OnInit {

  @Input() imgSrc: string;
  @Input() size: string;

  constructor() { }

  ngOnInit() {
  }

}
