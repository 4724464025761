import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { RootActions } from './root.actions';

@Injectable()
export class RootFacade {

  constructor(private store: Store<any>) { }

  logout() {
    this.store.dispatch(RootActions.logout());
  }

}
