import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './profile.reducer';
import { ProfileFacade } from './profile.facade';
import { EffectsModule } from '@ngrx/effects';
import { ProfileEffects } from './profile.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('Profile', reducer),
    EffectsModule.forFeature([ProfileEffects])
  ],
  providers: [
    ProfileFacade
  ]
})
export class ProfileStoreModule { }
