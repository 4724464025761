import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalsService } from 'src/app/shared/modals/modals.service'
import { ContactService } from './contact.service';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { TokenFacade } from 'src/store/token/token.facade';
import { take, takeUntil } from 'rxjs/operators';


const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
const LETTERS = /^[a-zA-ZñáéíóúÑÁÉÍÓÚ]*$/;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

  contactForm: FormGroup;
  loading: boolean = false;
  modalRef: BsModalRef
  content: string;
  title: string;
  contactSpace:boolean;
  language:string;

  // Kill all
  killall = new Subject();

  public modalConfig: ModalOptions = {
    class: 'modal-dialog-centered modal-xl modal-privacy'
  };

  constructor(
    public form: FormBuilder,
    private modalService: BsModalService,
    private wokeService: ModalsService,
    private contactService: ContactService,
    private location:Location,
    private tokenFacade : TokenFacade
  ) {

  }

  ngOnInit() {
    this.contactSpace = this.location.path().includes('contact-space') ? true : false
    this.initForm();
    this.tokenFacade.language$.pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: lan =>{
        this.language = lan
      }
    })
   }

  ngOnDestroy() {
    this.modalService.hide()
    document.querySelector('body').classList.remove('modal-open');
  }

  initForm(){
    if(this.contactSpace){
      this.contactForm = this.form.group({
        name: this.form.control('', [Validators.required, Validators.pattern(LETTERS)]),
        spaceName: this.form.control('', [Validators.required, Validators.pattern(LETTERS)]),
        mail: this.form.control('', [Validators.required, Validators.email]),
        phone: this.form.control('', [Validators.required]),
        message: this.form.control('', [Validators.required])
      })
    }else{
      this.contactForm =this.form.group({
        firstName: this.form.control('', [Validators.required, Validators.pattern(LETTERS)]),
        lastName: this.form.control('', [Validators.required, Validators.pattern(LETTERS)]),
        email: this.form.control('', [Validators.required, Validators.email]),
        subject: this.form.control('', [Validators.required]),
        message: this.form.control('', [Validators.required]),
        recaptcha: this.form.control(false, [Validators.required])
      })
    }

  }

  goBack(){
    this.location.back()
  }

  showPolicies(temp) {
    this.wokeService.getPrivacy().subscribe({
      next: (response) => {
        this.content = response.html;
        this.title = this.language === 'en' ? "Privacy Policy" : "Política de Privacidad"
        if (this.content !== "") {
          this.openTagModal(temp)
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  showTerms(temp) {
    this.wokeService.getTerms().subscribe({
      next: (response) => {
        this.content = response.html;
        this.title = this.language === 'en' ? "Terms and Conditions" : "Términos y Condiciones"
        if (this.content !== "") {
          this.openTagModal(temp)
        }
      },
      error: (err) => {
        console.log(err);

      }
    })

  }


  openTagModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, { class: 'modal-dialog-centered modal-xl modal-privacy' })
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  contact(template: TemplateRef<any>) {
    if (this.contactForm.valid) {
      if (!this.loading) {
        this.loading = true;
        this.contactSpace ? this.contactService.contactSpace(this.contactForm.value).subscribe({
          next: data => {
            console.log(data);
            this.loading = false;
            this.modalRef = this.modalService.show(
              template,
              { class: 'modal-dialog-centered modal-xl' }
            )
          },
          error: error => {
            console.warn(error);
            this.loading = false
          }
        }) : this.contactService.saveFormContact(this.contactForm.value).subscribe({
          next: data => {
            console.log(data);
            this.loading = false;
            this.modalRef = this.modalService.show(
              template,
              { class: 'modal-dialog-centered modal-xl' }
            )
          },
          error: error => {
            console.warn(error);
            this.loading = false
          }
        })
      }
    }
  }


}
