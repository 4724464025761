import { TokenResponse } from 'src/app/api.interfaces';
import { OnboardingType } from 'src/app/woke/onboarder/onboarder.models';

export interface TokenState {
  value: string;
  tokenResponse?: TokenResponse;
  darkMode: boolean;
  language:string;
  businessProfile: boolean;
  role:string,
  onboardings?: string[];
  currentOnboarding?: OnboardingType;
  currentOnboardingIndex: number;
  onboardingClosed: boolean;
}

export const initialState: TokenState = {
  value: '',
  darkMode: false,
  role:'',
  language:'es',
  businessProfile: window.localStorage.getItem('businessProfile') ? true : false,
  currentOnboardingIndex: 0,
  onboardingClosed: true,
  onboardings: null
};
