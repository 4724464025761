import { createReducer, on, Action } from '@ngrx/store';
import { ProfileActions } from './profile.actions';
import { initialState, ProfileState } from './profile.state';
import { Recommendation, Portfolio } from 'src/app/api.interfaces';

export const profileReducer = createReducer(
  initialState,

  // Load Categories
  on(ProfileActions.loadCategoriesSuccess,
    (state, { categories }) => ({ ...state, categories })),
  // Load Profile
  on(ProfileActions.loadData,
    ProfileActions.loadSuccess,
    (state, { data }) => ({ ...state, data, loading: false, loaded: true })
  ),
  // Load Business Profile
  on(ProfileActions.loadBusinessSuccess,
    (state, { data }) => ({ ...state, loading: false, loaded: true, businessProfile: data })
  ),
  // Load Offices
  on(
    ProfileActions.loadOfficesSuccess,
    (state, { offices }) => ({ ...state, offices })
  ),
   // Load ExtraOffices
   on(
    ProfileActions.loadExtraOfficesSuccess,
    (state, { extraOffices }) => ({ ...state, extraOffices })
  ),
  // Load Licenses
  on(
    ProfileActions.loadLicensesSuccess,
    (state, { licenses }) => ({ ...state, licenses })
  ),

  // Load
  on(
    ProfileActions.load,
    ProfileActions.loadBusiness,
    ProfileActions.loadCategories,
    state => ({ ...state, loading: true, loaded: false })
  ),

  on(
    ProfileActions.loadError,
    ProfileActions.loadBusinessError,
    ProfileActions.editProfileError,
    ProfileActions.updateProfilePictureError,
    ProfileActions.loadCategoriesError,
    state => ({ ...state, loading: false, loaded: false })
  ),


  // Profile Picture Reducer
  on(ProfileActions.updateProfilePicture,
    (state, { image, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';
      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          userInformation: {
            ...state[section].userInformation,
            image: image
          }
        }
      };
    }),


  // Basic Information Reducer
  on(ProfileActions.editProfile,
    (state, { payload, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';
      return {
        ...state,
        loaded: true,
        loading: false,
        [section]: {
          ...state[section],
          firstName: payload.firstName,
          lastName: payload.lastName,
          username: payload.username,
          email: payload.email,
          userInformation: {
            ...state[section].userInformation,
            industry: payload.industry,
            about: payload.about,
            website: payload.website,
            facebook: payload.facebook,
            instagram: payload.instagram,
            twitter: payload.twitter,
            linkedin: payload.linkedin,
            behance: payload.behance
          }
        },
      };
    }),


  // Add Specialti
  on(ProfileActions.addSpecialti,
    (state, { specialti, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';
      const specialties = state[section].professionalInformation ?
        state[section].professionalInformation.specialties : [];
      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            specialties: [...specialties, specialti]
          }
        }
      };
    }),


  // Remove Specialti
  on(ProfileActions.removeSpecialti,
    (state, { index, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';

      const specialties = state[section].professionalInformation ?
        state[section].professionalInformation.specialties : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            specialties: specialties.filter((s, i) => i !== index)
          }
        }
      };
    }),


  // Add a New Portfolio
  on(ProfileActions.addPortfolio,
    (state, { files, payload, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';

      const newPortfolio: Portfolio = {
        id: null,
        projectName: payload.name,
        description: payload.description,
        images: files as any
      };

      const portfolio = state[section].professionalInformation ?
      state[section].professionalInformation.portfolio : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            portfolio: [...portfolio, newPortfolio]
          }
        }
      };
    }),

  // Remove Portfolio
  on(
    ProfileActions.removePortfolio,
    (state, { id, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const portfolio = state[section].professionalInformation ?
      state[section].professionalInformation.portfolio : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            portfolio: portfolio.filter(p => p.id !== id)
          }
        }
      }
    }),

  // Update Porfolio Info
  on(
    ProfileActions.updatePortfolioInfo,
    (state, { id, payload, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const portfolio = state[section].professionalInformation ?
      state[section].professionalInformation.portfolio : [];

      const index = portfolio.findIndex(e => e.id == id);

      const copy = [...portfolio];

      copy[index] = {
        ...portfolio[index],
        projectName: payload.name,
        description: payload.description
      };

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            portfolio: [...copy]
          }
        }
      };
    }),

  // Add Category
  on(ProfileActions.addCategory,
    (state, { idCategory, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';
      const categories = state[section].professionalInformation ?
        state[section].professionalInformation.category : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            category: [...categories, idCategory]
          }
        }
      }
    }),


  // Remove Category
  on(ProfileActions.removeCategory,
    (state, { idCategory, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';
      const categories = state[section].professionalInformation ?
        state[section].professionalInformation.category : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            category: categories.filter(category => category != idCategory)
          }
        }
      };
    }),


  // Add Academic Formation
  on(ProfileActions.addAcademicFormation,
    (state, { item, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';
      const academicInformation = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.academicInformation : [];
      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              academicInformation: [...academicInformation, {
                ...item,
                since: item.from,
                until: item.to
              }]
            }
          }
        }
      };
    }),


  // Update Academic Formation
  on(ProfileActions.updateAcademicFormation,
    (state, { id, item, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const academicInformation = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.academicInformation : [];

      const index = academicInformation.findIndex(e => e.id == id);

      const copy = [...academicInformation];

      copy[index] = {
        ...item,
        since: item.from,
        until: item.to
      };

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              academicInformation: [...copy]
            }
          }
        }
      };

    }),


  // Remove Academic Formation
  on(ProfileActions.removeAcademicFormation,
    (state, { id, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const academicInformation = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.academicInformation : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              academicInformation: academicInformation.filter(x => x.id != id)
            }
          }
        }
      };

    }),


  // Add Experience
  on(ProfileActions.addExperience,
    (state, { experience, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';
      const experiences = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.experience : [];
      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              experience: [...experiences, {
                ...experience,
                since: experience.from,
                until: experience.to
              }]
            }
          }
        }
      }
    }),

  // Update Experience
  on(ProfileActions.updateExperience,
    (state, { id, experience, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const experiences = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.experience : [];

      const index = experiences.findIndex(e => e.id == id);

      const copy = [...experiences];

      copy[index] = {
        ...experience,
        since: experience.from,
        until: experience.to
      };

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              experience: [...copy]
            }
          }
        }
      };
    }),


  // Remove Experience
  on(ProfileActions.removeExperience,
    (state, { id, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const experiences = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.experience : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              experience: experiences.filter(x => x.id != id)
            }
          }
        }
      };

    }),


  // Add Recommendation
  on(ProfileActions.addRecommendation,
    (state, { recommendation, businessProfile }) => {
      const section = businessProfile ? 'businessProfile' : 'data';
      const recommendations = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.recommendations : [];
      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              recommendations: [...recommendations, recommendation]
            }
          }
        }
      }
    }),

  // Update Recommendation
  on(ProfileActions.updateRecommendation,
    (state, { id, recommendation, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const recommendations: Recommendation[] = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.recommendations : [];

      const index = recommendations.findIndex(e => e.id == id);

      const copy = [...recommendations];

      copy[index] = {
        ...recommendation
      };

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              recommendation: [...copy]
            }
          }
        }
      };
    }),


  // Remove Recommendation
  on(ProfileActions.removeRecommendation,
    (state, { id, businessProfile }) => {

      const section = businessProfile ? 'businessProfile' : 'data';

      const recommendations = state[section].professionalInformation &&
        state[section].professionalInformation.curriculum ?
        state[section].professionalInformation.curriculum.recommendations : [];

      return {
        ...state,
        loading: false,
        loaded: true,
        [section]: {
          ...state[section],
          professionalInformation: {
            ...state[section].professionalInformation,
            curriculum: {
              ...state[section].professionalInformation.curriculum,
              recommendations: recommendations.filter(x => x.id != id)
            }
          }
        }
      };

    }),


);

export function reducer(state: ProfileState | undefined, action: Action) {
  return profileReducer(state, action);
}
