import { Component, OnInit, Input } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';

@Component({
  selector: 'app-woke-chat-message',
  templateUrl: './woke-chat-message.component.html',
  styleUrls: ['./woke-chat-message.component.scss']
})
export class WokeChatMessageComponent implements OnInit {

  @Input() current = false;

  darkMode$ = this.tokenFacade.darkMode$;

  constructor(
    private tokenFacade: TokenFacade
  ) { }

  ngOnInit() {
  }

}
