import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ApiService } from 'src/app/api.service';
import { LoggedService } from '../../logged/logged.service';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';


const LETTERS = /^[a-zA-ZñáéíóúÑÁÉÍÓÚ\s]*$/;


@Component({
  selector: 'app-register-lite',
  templateUrl: './register-lite.component.html',
  styleUrls: ['./register-lite.component.scss']
})
export class RegisterLiteComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  modalRef: BsModalRef
  content:string;
  title:string;
  errorMessage:string = '';

  killall = new Subject();


  modalConfig: ModalOptions = {
    class: 'modal-dialog-centered modal-xl modal-privacy'
  };

  fill_in: boolean;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private api: ApiService,
    private router: Router,
    private loggedService: LoggedService,
    private wokeService: ModalsService
  ) { }

  ngOnInit() {
    this.initRegisterForm()
  }

  ngOnDestroy() {
    this.modalService.hide()
    document.querySelector('body').classList.remove('modal-open');
    this.killall.next();
    this.killall.complete();
  }

  initRegisterForm() {
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(LETTERS)]],
      lastName: ['', [Validators.pattern(LETTERS)]],
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    },{validator: this.passwordConfirming});
  }
  showPolicies(temp){
    this.wokeService.getPrivacy().subscribe({
      next: (response)=>{
        this.content = response.html;
        this.title = "Privacy Policy"
        if(this.content !== ""){
          console.log(this.content);

          this.openTagModal(temp)
        }
      },
      error: (err) =>{
        console.log(err);

      }
    })
  }
  showTerms(temp) {
    this.wokeService.getTerms().subscribe({
      next: (response)=>{
        this.content = response.html;
        this.title = "Terms and Conditions"
        if(this.content !== ""){
          console.log(this.content);

          this.openTagModal(temp)
        }
      },
      error: (err) =>{
        console.log(err);

      }
    })

  }
  openTagModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, {class: 'modal-dialog-centered modal-xl modal-privacy'})
  }

  openErrorModal(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, {class: 'modal-dialog-centered modal-xl'})
  }

  openSucess(tagModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(tagModal, {class: 'modal-dialog-centered modal-xl'})
    this.modalService.onHidden.pipe(
      take(1),
      takeUntil(this.killall),
    ).subscribe(() => {
      this.router.navigate(['/login'])
    })
  }

  passwordConfirming(pass: AbstractControl): { invalid: boolean } {
    if (pass.get('password').value !== pass.get('confirmPassword').value) {
        return {invalid: true};
    }
}

  register(tagModal: TemplateRef<any>,success: TemplateRef<any>) {
    if (this.registerForm.valid) {
      this.loading = true;
      const register$ = this.api.registerLite(this.registerForm.value);
      register$.subscribe(
        () => {this.loading = false
          this.openSucess(success);
        },
        failedResponse => {
          this.errorMessage = failedResponse.error.error;
          this.openErrorModal(tagModal)
          this.loading = false;
        }
      );
    } else {
      this.fill_in = true
      this.openErrorModal(tagModal)
    }
  }

}
