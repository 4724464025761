import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-woke-youtube',
  templateUrl: './woke-youtube.component.html',
  styleUrls: ['./woke-youtube.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WokeYoutubeComponent implements OnInit {

  constructor( private _sanitizer: DomSanitizer) { }

  source:string;

  @Input() idVideo:string;

  ngOnInit() {
    this.getVideoIframe();
  }

  getVideoIframe() {

    return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.idVideo +'?autoplay=1');
}


}
