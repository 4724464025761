import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { switchMap, map, filter } from 'rxjs/operators';
import { MetaResponse, BillingInformation, MyOffice } from 'src/app/api.interfaces';
import { LicenseInfoResponse, LicenseRequestResponse, LicenseCheckoutPayload, LicenseChekoutProcessPayload, LicenseCheckoutProcessResponse, CheckoutInfoResponse, NetworkCheckoutPayload } from './checkout.types';
import { throwError } from 'rxjs';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { CheckoutOfficePayload, CheckoutProcessResponse } from '../my-office/my-office.models';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  paymentDefault$ = this.walletFacade.paymentDefault$;

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade,
    private walletFacade: WalletFacade
  ) { }


  loadNetworkCheckout(id: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/network/booking/checkout/${id}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as CheckoutInfoResponse),
        )
      })
    )
  }

  networkCheckoutProcess(payload: NetworkCheckoutPayload) {
    const { idBooking, sourceId, invoice, cfdi} = payload;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/network/booking/stripe/checkout/process/${idBooking}`;
        const formData = new FormData();
        formData.append('source_id', sourceId);
        formData.append('invoice', invoice === true ? 'yes' : 'no');
        formData.append('cdfi', `${cfdi}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as LicenseCheckoutProcessResponse),
        )
      })
    );
  }

  loadLicenseCheckoutInfo(id: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/credit_packages/detail/${id}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as LicenseInfoResponse),
        )
      })
    )
  }

  loadOfficeDetail(officeId: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/detail/${officeId}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          filter(office => office !== undefined),
        )
      })
    )
  }

  getDayPassesLimit(officeID:string, date:string){
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/day-pass/${officeID}`;
        const formData = new FormData();
        formData.append('date', date);
        return this.http.post(url, formData, {headers}).pipe(
          map((response: MetaResponse) => response.data)
        )
      })
    )
  }


  loadOfficeCheckoutInfo(officeId: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-office/checkout/${officeId}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          filter(office => office !== undefined),
        )
      })
    )
  }

  loadBillingInformation() {
    const url = `${environment.api}/wallet/billing-information`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as BillingInformation)
        )
      })
    )
  }

  loadLicenseCheckoutRequest(payload: LicenseCheckoutPayload) {

    const { idLicense, sourceId, invoice, packageId } = payload;

    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/licenses/checkout/request/${idLicense}`;
        return this.http.post(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as LicenseRequestResponse),
          switchMap(data => {
            if (data.continueTo === 'Checkout') {
              return this.loadLicenseCheckoutProcess({ sourceId, invoice, packageId });
            } else {
              return throwError('Invalid License');
            }
          })
        )
      })
    )
  }

  loadLicenseCheckoutProcess(payload: LicenseChekoutProcessPayload) {
    const { sourceId, invoice, packageId } = payload;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/licenses/stripe/checkout/process`;
        const formData = new FormData();
        formData.append('source_id', sourceId);
        formData.append('invoice', invoice === true ? 'yes' : 'no');
        formData.append('package_id', packageId);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as LicenseCheckoutProcessResponse),
        )
      })
    );
  }

  loadOfficeCheckoutProcess(payload: CheckoutOfficePayload, office: MyOffice, cdfi: number) {
    const formData = new FormData();
    formData.append('source_id', payload.source_id);
    formData.append('invoice', payload.invoice);
    formData.append('cdfi', `${cdfi}`);


    const url = `${environment.api}/my-office/stripe/checkout/process/${office.id}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as CheckoutProcessResponse)
        )
      })
    )
  }

  loadPaymentMethods() {
    this.walletFacade.loadPaymentMethods();
  }

}
