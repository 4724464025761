import { Component, OnInit, Input } from '@angular/core';
import { Workspace, UpcomingWorkspace } from 'src/app/api.interfaces';
import { TokenFacade } from 'src/store/token/token.facade';
import { LoggedService } from 'src/app/logged/logged.service';

@Component({
  selector: 'app-upcoming-workspace',
  templateUrl: './upcoming-workspace.component.html',
  styleUrls: ['./upcoming-workspace.component.scss'],
})
export class UpcomingWorkspaceComponent implements OnInit {
  @Input() workspace: UpcomingWorkspace;

  @Input() canBookAgain = false;

  darkMode$ = this.tokenFacade.darkMode$;

  constructor(
    private tokenFacade: TokenFacade,
    public loggedService: LoggedService
  ) { }

  ngOnInit() {
  }
}
