import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CoworkingChildsState } from './childs.state';
import { CoworkingChildsActions } from './childs.actions';
import { allCoworkings, selectEntity, selectGalleryEntity } from './childs.selectors';
import { CoworkingChild } from 'src/app/api.interfaces';

@Injectable()
export class CoworkingChildsFacade {

  all$ = this.store.pipe(
    select(allCoworkings)
  )

  current$ = (id: number) => this.store.pipe(
    select(selectEntity, id)
  );

  currentGallery$ = (id: number) => this.store.pipe(
    select(selectGalleryEntity, id)
  );

  constructor(private store: Store<CoworkingChildsState>) { }

  loadSucces(items: CoworkingChild[]) {
    this.store.dispatch(CoworkingChildsActions.loadSuccess({ items }));
  }

  load() {
    this.store.dispatch(CoworkingChildsActions.load());
  }

  loadOne(id: number) {
    this.store.dispatch(CoworkingChildsActions.loadOne({ id }))
  }

  loadGallery(id: number) {
    this.store.dispatch(CoworkingChildsActions.loadGallery({ id }))
  }

}
