import { CommonData } from './logged/work/hire/provider-profile/provider-profile.model'
import { environment } from 'src/environments/environment'

export interface LoginResponse {
  token?: string;
  refresh_token?: string;
  error?: string;
}

export type coresLanding = {
  id: number;
  name: string;
  profilePicture: string;
  profilePictureWhite: string;
  description: string;
  rating: number;
  status: string;
  username: string;
  value: number;
  label: string;
}

export type calcuResponse = {
  success: boolean;
  total: number;
  unit_price: string;
  units: [];
}

export type RegisterSpaceFormType = {
  name: string;
  mail: string;
  phone: string;
  space_name: string;
  space_type: string;
  space_buildings:string;
  space_address:string;
  space_capacity:string;
};

export interface RegisterResponse {
  status: number;
  data: {
    email: string;
    firstName: string;
    id: number;
    lastName: number;
    username: string;
  }
}

export type IndustriesResponse = {
  id: number;
  name: string;
}


export interface Curriculum {
  academicInformation: AcademicInformation[];
  experience: Experience[];
  recommendations: Recommendation[];
}

export interface Period {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface PaymentOffice {
  startPaymentPeriod: Period;
  endPaymentPeriod: Period;
  startCurrentPeriod: Period;
  endCurrentPeriod: Period;
  allowToPayDate: Period;
  subTotal: number;
  total: number;
  totalNoFee:number;
  paymentFee: number;
  charges: number;
  tax: number;
  taxNoFee: number;
  fixed:[];
  dynamic:[];
  payments: any;
  isDefaulter: boolean;
  difference:number;
  amountDue: number;
  remainingHours: string | number;
  remainingCredits?: string | number;
  rent:string;
  products?:number;
  showPayBtn:boolean;
  checkout: any;
  extraCost: number;
  coworkingChild: CoworkingChild;
}

export type MyOfficeMember = {
  id: number;
  roles: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  image: string;
  status: boolean;
}

export type DayPass = {
  active:boolean;
  id: number;
  name: string;
  img?: string;
  date?: {
    date: string;
  };
}

export type MyOffice = {
  active:boolean;
  capacity: string;
  client:string;
  coworkingChild: CoworkingChild;
  coworkingParent?: {
    id: number;
    name: string;
    rating: number;
    profilePicture: string;
  };
  credit_limit:number;
  dayPasseslimit?: string;
  duration: string | number;
  extraCost: number;
  id: number;
  name: string;
  officeNumber?: string;
  owner: {
    firstName: string;
    id: number;
    lastName: string;
  };
  payment: PaymentOffice;
  qr?: string;
  remainingCredits?:string;
  remainingHours?: string;
  valid: boolean | 'inheritance';
  status?: 'pending' | 'rejected';
  members?: MyOfficeMember[];
  dayPasses?: DayPass[];
  dayPasseslimitCOVID?:string;
}

export type ParsedOfficesLicenses = {
  id: number;
  name: string;
  credits_limit: number;
  remainingCredits:number;
  isOffice: boolean;
}

export type ExtraOffice = {
  id: string;
  endAt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  startAt: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  owner: {
    firstName: string;
    id: number;
    lastName: string;
    username: string;
  };
  workspace: {
    id: number;
    name: string;
    type: string;
  };
  coworkingChild: CoworkingChild;
  coworkingParent:{
    id: number;
    name: string;
    rating: number;
    profilePicture: string;
  };
  qr?: string;
  members?: MyOfficeMember[];
  dayPasses?: DayPass[];
  dayPasseslimit?: string;
  dayPasseslimitCOVID?:string;
  capacity: string;
  remainingCredits?: number;
}

export type License = {
  id: number;
  name: string;
  owner: {
    firstName: string;
    id: number
    lastName: string;
    username: string;
  }
  qr: string;
  remainingCredits: number;
  type: string;
}

export type FixedService = {
  id: number;
  date: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  concept: string;
  price: string;
  productId: number;
  type: string;
  quantity: string;
  subtotal: string;

}

export type DynamicService = {
  concept: string;
  quantity:string;
  price: string;
  id: number;
  type: string;
  subtotal: string;
}

export type AddressResponse = {
  country: string;
  id: number;
  municipality: string;
  neighborhood: string;
  state: string;
  type: string;
  zip_code: string;
}


export interface PartialBusiness {
  switch: boolean;
  username: string;
  role: string;
  image?: string;
}

export interface ProfileDataV2 {
  personal: ProfileData,
  business?: ProfileData,
}

export interface ProfileData {
  switch: boolean;
  id: number;
  firstName: string;
  lastName: string;
  customerId: number | null;
  email: string;
  highlighted: boolean | null;
  phone: number | null;
  business?: PartialBusiness;
  professionalInformation: {
    category: string[];
    specialties: string[];
    priceParHour: any;
    daysPerWeek: any;
    hoursPerWeek: any;
    roleWithinCompany: any;
    portfolio: Portfolio[];
    curriculum: Curriculum;
  };
  role: string;
  status: boolean;
  userInformation: UserInformation;
  username: string;
  myOffice?: {
    extraOffices: any[];
    myOffice: any[] | MyOffice;
  };
  ratings: any[];
}

export type UserInformation = {
  about: string | null;
  behance: string | null;
  city: CommonData;
  country: CommonData;
  extNum: string | null;
  facebook: string | null;
  image: string | null;
  industry: CommonData;
  instagram: string | null;
  intNum: string | null;
  linkedin: string | null;
  municipality: CommonData;
  neighborhood: string | null;
  rfc: string | null;
  state: CommonData;
  street: string | null;
  twitter: string | null;
  website: string | null;
  zip: string | null;
}

export interface EditProfileData {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  industry: string;
  about: string;
  website: string;
  facebook: string;
  instagram: string;
  twitter: string;
  linkedin: string;
  behance: string;
}

export interface ProfileResponse {
  data: ProfileData;
}

export interface UpcomingWorkspace {
  id: number;
  startAt: string;
  endAt: string;
  startTimeAt: string;
  endTimeAt: string;
  isModified: boolean;
  coworkingChild: CoworkingChild;
  workspace: Workspace;
}

export interface EventChild {
  active?:boolean;
  capacity: number;
  category: string;
  coworkingChild: {
    id: number;
    name: string;
    rating: number;
    profilePicture: string;
  };
  date: string;
  description: string;
  id: number;
  limitedVacancy: boolean;
  location: string;
  name: string;
  price: string;
  profilePicture: string;
  remaining: number;
  time: string;
}

export interface Workspace {
  active?:boolean;
  upcoming?: boolean;
  past?: boolean;
  amenities?: string;
  availableDays: string[];
  capacity: number;
  closesAt: string;
  coworkingChild?: {
    id: number;
    name: string;
    profilePicture: string;
    rating: number;
    terms?:string;
  };
  coworkingParent?: {
    id: number;
    name: string;
    profilePicture: string;
    rating: number;
  };
  description?: string;
  external_remaining_time?:{
    hour: number;
    min: number;
  };
  internal_remaining_time?:{
    day: number;
    hour: number;
  }
  highlighted?: any;
  id: number;
  internalPrice: string;
  isRequest?: boolean;
  externalPrice:string;
  location?: string;
  short_location?:string;
  minimumBookingTime: string;
  minimumBookingTime_es?: string;
  name: string;
  opensAt: string;
  profilePicture: string;
  type: string;
  type_es?:string;
  requesting?: boolean;
  rating?:number;
  workspaceImages?: {id: number, image: string}[];
}

export interface WorkspacePins{
active: string;
distance: string;
distance_value: number;
duration: string;
duration_value: number;
id: number;
lat: string;
lng: string;
location: string;
name: string;
profilePicture: string;
rating: number;
workspaces: Workspace[];
isClicked?:boolean;
pinId?:number;
}
export interface ChatThread {
  message: string;
  body: string;
  thread: number;
  sender: number;
  destin: string;
}

export interface ChatUser {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  image?: string;
}

export class ChatUserClass {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  image?: string;
  hasImage: boolean;
  constructor(payload: ChatUser) {
    this.id = payload.id;
    this.firstName = payload.firstName;
    this.lastName = payload.lastName;
    this.username = payload.username;
    this.email = payload.email;
    this.hasImage = payload.image ? true : false;
    this.getImage(payload)
  }
  getImage(payload:ChatUser){
    if(!payload.image){
      this.image = 'assets/img/utils/Profile.png'
    } else if(payload.image && payload.firstName !== "Woke Support"){
      this.image =  environment.resourceUrl + payload.image
    }else if(payload.firstName === "Woke Support"){
      this.image = payload.image
    }
  }
}

export interface ChatMessage {
  id?: number;
  createdAt?: string;
  message?: string;
}

export interface Chat {
  id?: number;
  unread?: string;
  message?: ChatMessage;
  users?: ChatUser[];
  type?: string;
}

export class ChatConversationClass {
  id: number;
  unread: number;
  message: ChatMessage;
  users: ChatUserClass[];
  active = false;
  type: 'personal' | 'woke' | 'parent' | 'child';

  userWith: ChatUserClass;

  constructor(chat: Chat) {
    this.id = chat.id;
    this.unread = chat.unread ? +chat.unread : 0;
    this.message = chat.message ? chat.message : { };
    this.users = chat.users.map(user => new ChatUserClass(user));
    if (this.users.length) {
      this.userWith = this.users[this.users.length - 1];
    }
    if (!chat.type) {
      this.type = 'personal';
    }

    if (chat.type === 'woke') {
      this.type = 'woke';
    }

    if (chat.type === 'parent') {
      this.type = 'parent';
    }
    if (chat.type === 'child') {
      this.type = 'child';
    }
  }

  read() {
    this.active = true;
    this.unread = 0;
  }

  isReaded() {
    return this.unread !== 0;
  }

}

export class ChatClass implements Chat {

  id: number;
  unread: string;
  message: ChatMessage;
  users = [];
  user: ChatUser = <ChatUser>{
    id: undefined,
    firstName: '',
    lastName: '',
    image: 'assets/img/utils/Profile.png'
  };

  constructor(payload?: any) {
    payload = payload || {};
    this.id = payload ? payload.id : 0;
    this.unread = payload ? payload.unread : '';
    this.message = payload ? payload.message : undefined;
    this.users = payload ? payload.users : [];


    switch (payload.type) {
      case 'woke':
        this.user = <ChatUser>{
          id: payload.id,
          firstName: 'Woke Support',
          lastName: '',
          image: 'assets/img/logos/profile_picture.png',
        }
        break;
      case 'publico':
        this.user = <ChatUser>{
          id: 0,
          firstName: 'Publico',
          lastName: '',
          image: 'https://manager.woke.mx/images/12-parent.jpg'
        }
        break;
      case 'child':
        this.user = <ChatUser>{
          id: 0,
          firstName: 'Publico',
          lastName: '',
          image: 'https://manager.woke.mx/images/12-parent.jpg'
        }
        break;
      default:
        if (payload.userWith) {
          const user = payload.userWith;
          let image = 'assets/img/utils/Profile.png';
          if (user.hasImage) {
            if (user.image.includes(environment.resourceUrl)) {
              image = user.image;
            } else {
              image = environment.resourceUrl + user.image;
            }
          }
          this.user = <ChatUser>{
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            image
          }
        }
        if (payload.user) {
          let image = payload.user.image;
          if (payload.user.userInformation && payload.user.userInformation.image) {
            image = environment.resourceUrl + payload.user.userInformation.image;
          } else {
            image = 'assets/img/utils/Profile.png';
          }
          this.user = <ChatUser>{
            id: payload.user.id,
            firstName: payload.user.firstName,
            lastName: payload.user.lastName,
            image,
          }
        }
        break;
    }
  }

}

export interface CoworkingCommunity {
  id: number;
  name: string;
  profilePicture: string;
  coworkings: Array<{
    id: number;
    name: string;
    profilePicture: string;
  }>
}

export class CoworkingCommunityClass {
  id: number;
  name:string;
  profilePicture:string;
  coworkings: Array<{
    id: number;
    name: string;
    profilePicture: string;
  }>

  constructor(payload?: any) {
    payload = payload || {};
    this.id = payload ? payload.id : 0;
    this.name = payload ? payload.name : '';
    this.profilePicture = payload ? payload.profilePicture : 'assets/img/utils/Profile.png';
    this.coworkings = payload ? payload.coworkings : [];
  }


}

export interface CoworkingCommunityUser {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  industry: string;
  image?: string;
}

export interface CoworkingCommunityChild {
  coworkingChild:CoworkingChildFeatured;
  maxPages: number;
  currentPage: number;
  users: CoworkingCommunityUser[]
}

export interface CoworkingChild {
  id: number;
  name: string;
  chat?: string;
  profilePicture: string;
  description: string;
  rating: number;
  parent: CoworkingParent;
  availableDays: string[];
  schedule: string;
  status:string;
  amenitines: string;
  location:string;
  workspaces: Workspace[];
  events: any[];
  gallery: Array<{ image: string }>;
}

export interface CoworkingChildFeatured {
  id: number;
  name: string;
  profilePicture: string;
  rating?:number;
}

export interface CoworkingParent {
  id: number;
  name: string;
  profilePicture: string;
  rating: number;
  description?: string;
  coworkings?: CoworkingChild[];
  gallery: Array<{ image: string }>;
}

export interface Coworking {
  id: number;
  name: string;
  profilePicture: string;
  rating: number;
}

export interface MetaResponse {
  status: number;
  data: any;
  message?: string;
}

export interface MapsResponse {
  status: string;
  results: any;
}

export interface MetaError {
  error: { status: number, error: string, message: string };
  message: string;
  status: number;
}

export interface BusinessPorfileResponse extends MetaResponse {
  data: {
    id: number;
    name: string;
    username: string;
    email: string;
  }
}

export interface WorkspacesResponse extends MetaResponse {
  status: number;
  data: {
    total: number;
    workspaces: Array<Workspace>;
  };
}

export interface BillingInformation {
  fullName?: string;
  rfc?: string;
  street?: string;
  exteriorNumber?: string;
  interiorNumber?: string;
  neighborhood?: string;
  zipCode?: string;
  locality?: string;
  municipality?: string;
  state?: string;
  country?: string;
  cdfi?: CFDI;
}

export interface BillingInformationResponse extends MetaResponse {
  data: BillingInformation;
}

export interface UpcomingNetworkItem {
  id: number;
  event: NetworkItem;
}

export interface BookingProcessPayload {
  id: number;
  startAt: string;
  endAt?: string;
  startTimeAt?: string;
  endTimeAt?: string;
  timeSelected: string;
  nameBooking?: string;
  specialRequest?: string;
  invites?: string[];
  withMoney?: string;
}

export interface NetworkItem {
  active?:boolean;
  upcoming?: boolean;
  id: number;
  name: string;
  description: string;
  category: string;
  date: string;
  time: string;
  capacity: string;
  remaining: string;
  location: string;
  price: number;
  limitedVacancy: boolean;
  profilePicture: string;
  dynamicText: string;
  coworkingChild: {
    id: number;
    name: string;
    rating: number;
    profilePicture: string;
  }
}

export interface NetworkResponse extends MetaResponse {
  data: {
    total: number;
    events: NetworkItem[];
  }
}


export interface AcademicInformation {
  id: number;
  institutionName: string;
  description: string;
  since?: string;
  until?: string;
  from?: string;
  to?: string;
}


export interface Experience {
  id: number;
  companyName: string;
  description: string;
  since?: string;
  until?: string;
  from?: string;
  to?: string;
}

export interface PortfolioImage {
  id?: number;
  image: string | ArrayBuffer;
  sort?: string;
}

export interface Portfolio {
  id: number;
  projectName: string;
  description?: string;
  images: PortfolioImage[];
}

export interface Recommendation {
  id: number;
  author: string;
  file: string | ArrayBuffer | File;
  ofiginalFile?: string;
}

export interface Booking {
  date?: string;
  time?: string;
  subtotal: number;
  iva: number;
  paymentFee: number;
  total: number;
  hours?: number;
  // Real data
  startAt?: string;
  endAt?: string;
  startTimeAt?: string;
  endTimeAt?: string;
  timeSelected?: number;
  nameOfBooking?: string;
  specialRequest?: string;
  id?: number;
  msg?: string;
  continueTo?: string;
  workspace?: Workspace;
  //
  from: 'workspace' | 'event';
  qr?: string;
  isModifiable?: boolean;
}

export interface PaymentMethod {
  id?: string;
  type?: string;
  bank?: string;
  cardHolder?: string;
  cardNumber?: string;
  expirationdDate?: string;
  securityCode?: string;
  cardNumberHidden?: string;
  brand?: string;
  data?: {
    address: any;
    card_number: string;
    holder_name: string;
    expiration_year: string;
    expiration_month: string;
    points_type: string;
    points_card: boolean;
  }
}

export interface EarningAccount {
  id?: number;

}


export interface ClusterPerson {
  id?: number;
  profilePicture: string;
  name: string;
  username?: string;
}

export interface PaymentHistory {
  history: any;
}

export interface Payment {
  id: string;
  entity: string;
  creationDate: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  total: number;
  description: string;
}

export interface ClusterBalance {
  balance: any;
}

export interface Category {
  id: number;
  category: string;
  description: string;
}


export type TokenResponse = {
  token: string;
  refresh_token: string;
}

export type MongoDbEntity = {
}

export type CommonEntity = {
  _id: string,
  name: string,
  description?: string,
  controlType?: string
}

export type CFDI = {
  id: number;
  code: string;
  name: string;
}
