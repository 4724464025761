
export interface Endpoint {
  method: 'get' | 'post' | 'patch' | 'delete' | 'put';
  url: string;
  keyMap?: any;
}

export class APIMap {


  /**
   * Chat Endpoints
   */

  chatSearch: Endpoint = {
    method: 'get',
    url: '/chat/search'
  };

  chatList: Endpoint = {
    method: 'get',
    url: '/chat/list'
  };


  /**
   * Landing Endpoints
   */

  about: Endpoint = {
    method: 'get',
    url: '/about'
  };

  login: Endpoint = {
    method: 'post',
    url: `/login`,
    keyMap: {
      username: 'string',
      password: 'string'
    }
  };

  register: Endpoint = {
    method: 'post',
    url: `/register`,
    keyMap: {
      firstName: 'string',
      lastName: 'string',
      username: 'string',
      email: 'string',
      password: 'string'
    }
  };

  /**
   * Network Endpoints
   */

  networkUpcoming: Endpoint = {
    method: 'get',
    url: `/network/upcoming`
  };

  network: Endpoint = {
    method: 'get',
    url: `/network`
  };

  networkPaginate: Endpoint = {
    method: 'get',
    url: `/network/paginate`,
    keyMap: {
      maxResults: 'number',
    }
  };



  /**
   * Profile Endpoints
   */

  getProfile: Endpoint = {
    method: 'get',
    url: `/profile`
  };

  editProfile: Endpoint = {
    method: 'put',
    url: '/profile/edit',
    keyMap: {
      firstName: 'string',
      lastName: 'string',
      email: 'string',
      industry: 'number',
      about: 'string',
      website: 'string',
      facebook: 'string',
      instagram: 'string',
      twitter: 'string',
      linkedin: 'string',
      behance: 'string'
    }
  };

  /**
   * Settings Endpoints
   */

  settingsZendesk: Endpoint = {
    method: 'get',
    url: `/settings/zendesk`
  };

  settingsDeleteAccount: Endpoint = {
    method: 'post',
    url: `/settings/delete-account`,
    keyMap: {
      why: 'string'
    }
  };

  /**
   * Wallet Endpoints
   */

  walletPaymentHistory: Endpoint = {
    method: 'get',
    url: `/wallet/payment-history`
  };

  walletBillingInformation: Endpoint = {
    method: 'get',
    url: `/wallet/billing-information`
  };

  walletEarningAccount: Endpoint = {
    method: 'get',
    url: `/wallet/earning-account`
  };

  walletPaymentMethod: Endpoint = {
    method: 'get',
    url: `/wallet/payment-method`
  };

  /**
   * Workspace Endpoints
   */

  workspaceUpcoming: Endpoint = {
    method: 'get',
    url: '/workspace/upcoming'
  };

  workspacePast: Endpoint = {
    method: 'get',
    url: '/workspace/past'
  };

  workspaceFeatured: Endpoint = {
    method: 'get',
    url: '/workspace/featured'
  };

  workspaces: Endpoint = {
    method: 'get',
    url: '/workspaces'
  };

  workspacePaginated: Endpoint = {
    method: 'get',
    url: '/workspace/paginated'
  };

  workspaceId: Endpoint = {
    method: 'get',
    url: '/workspace/:id'
  };

  workspaceCoworkingParents: Endpoint = {
    method: 'get',
    url: '/workspace/coworking-parents'
  };

  workspaceCoworkingParent: Endpoint = {
    method: 'get',
    url: '/workspace/coworking-parent/:id'
  };

  workspaceCoworkingChild: Endpoint = {
    method: 'get',
    url: '/workspace/coworking-child/:id'
  };

}
