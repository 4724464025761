import { Component, OnInit, OnDestroy, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { StripeSource, StripeScriptTag } from 'stripe-angular';
import { environment } from 'src/environments/environment';


@Component({
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit, OnDestroy {

  private publishableKey:string = environment.STRIPE_KEY

  formGroup: FormGroup;
  invalidError: any;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private walletFacade: WalletFacade,
    public StripeScriptTag:StripeScriptTag

  ) {
    this.StripeScriptTag.setPublishableKey( this.publishableKey )

   }

  ngOnInit() {
    this.initFormGroup();
  }

  ngOnDestroy() {
    this.closeModal();
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      name: ''
    });
  }

  onStripeInvalid(error: Error) {
    console.log('Validation Error', error)
  }

  setStripeToken(token: stripe.Token) {
    this.walletFacade.addPaymentMethod(token.id);
    this.closeModal();
  }

  setStripeSource(source: StripeSource) {
    console.log('Stripe source', source)
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }
}
