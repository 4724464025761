import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service'


@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  constructor(private wokeService: ModalsService) { }

  ngOnInit() {
    this.showTerms()
  }
  content:string;
  title:string;
  loading = true;


  showTerms() {
    this.wokeService.getTerms().subscribe({
      next: (response)=>{
        this.content = response.html;
        this.title = "Terms and Conditions"
        if(this.content !== ""){
          console.log(this.content);
          this.loading = false;

        }
      },
      error: (err) =>{
        console.log(err);
        this.loading = false;

      }
    })

  }

}
