import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { StripeSource, StripeScriptTag } from 'stripe-angular';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LoggedService } from 'src/app/logged/logged.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss']
})
export class AddPaymentMethodComponent implements OnInit, OnDestroy {

  private publishableKey:string = environment.STRIPE_KEY

  saving = false;

  formGroup: FormGroup;
  invalidError: any;

  killall = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private walletFacade: WalletFacade,
    private loggedService: LoggedService,
    public StripeScriptTag:StripeScriptTag
  ) {
    this.StripeScriptTag.setPublishableKey( this.publishableKey )
  }

  ngOnInit() {
    this.initFormGroup();
  }

  ngOnDestroy() {
    this.closeModal();
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      name: ''
    });
  }

  onStripeInvalid(error: Error) {
    console.log('Validation Error', error)
  }

  setStripeToken(token: stripe.Token) {
    if (!this.saving) {
      this.saving = true;
      this.walletFacade.addPaymentMethod(token.id).pipe(
        take(1),
        takeUntil(this.killall)
      ).subscribe({
        next: () => {
          this.saving = false;
          this.closeModal();
        },
        error: error => {
          this.saving = false;
          console.warn(error.error.error);
          this.loggedService.showError(error.error.error);
        }
      })
    }
  }

  setStripeSource(source: StripeSource) {
    console.log('Stripe source', source)
  }

  onStripeError(error: Error) {
    console.error('Stripe error', error)
  }

}
