import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './childs.reducer';
import { CoworkingChildsFacade } from './childs.facade';
import { EffectsModule } from '@ngrx/effects';
import { CoworkingChildsEffects } from './childs.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('CoworkingChilds', reducer),
    EffectsModule.forFeature([CoworkingChildsEffects])
  ],
  providers: [
    CoworkingChildsFacade
  ]
})
export class CoworkingChildsStoreModule { }
