import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TokenStoreModule } from './token/token-store.module';
import { CoworkingChildsStoreModule } from './childs/childs-store.module';
import { ProfileStoreModule } from './profile/profile-store.module';
import { WalletStoreModule } from './wallet/wallet-store.module';
import { metaReducers } from './meta.reducer';
import { RootFacade } from './root.facade';
import { QuickBookingStoreModule } from './quick/quick-store.module';

@NgModule({
  imports: [
    TokenStoreModule,
    CoworkingChildsStoreModule,
    ProfileStoreModule,
    WalletStoreModule,
    QuickBookingStoreModule,
    StoreModule.forRoot({}, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    EffectsModule.forRoot([])
  ],
  providers: [
    RootFacade
  ]
})
export class RootStoreModule {}
