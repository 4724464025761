import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';
import { LoggedService } from 'src/app/logged/logged.service';
import { UpcomingEventClass } from 'src/app/logged/network/network/network/network.models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upcoming-event',
  templateUrl: './upcoming-event.component.html',
  styleUrls: ['./upcoming-event.component.scss']
})
export class UpcomingEventComponent implements OnInit {

  @Input() upcoming: UpcomingEventClass = new UpcomingEventClass({});

  darkMode$ = this.tokenFacade.darkMode$;
  modalRef: BsModalRef;


  constructor(
    private tokenFacade: TokenFacade,
    public loggedService: LoggedService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-centered modal-qr' });
  }

}
