import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenFacade } from 'src/store/token/token.facade';



@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  openSpan = false
  language: string;
  killall = new Subject()

  constructor(private tokenFacade: TokenFacade, private translate: TranslateService) { }

  ngOnInit() {
    this.tokenFacade.language$.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: lan =>{
        this.language = lan
      }
    })
  }

  ngOnDestroy(){
    this.killall.next();
    this.killall.complete();
  }

  switchLanguage(){
    let lang = this.language == 'en' ? 'es' : 'en'
    this.tokenFacade.switchLanguage(lang);
    this.translate.use(lang);
  }



}
