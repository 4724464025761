import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { MetaResponse, Workspace, Booking } from 'src/app/api.interfaces';
import { WorkspacesResponse, BookingClass } from './models/workspaces.models';

export type InvitationsType = {
  owner: string;
  invites: Booking[];
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  darkMode$ = this.tokenFacade.darkMode$;

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade
  ) { }

  loadPendingRequest() {
    const url = `${environment.api}/workspace/pending-request`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map((data: any[]) => {
            return data.map(item => new BookingClass(item))
          })
        )
      }),
    )
  }

  loadInvited() {
    const url = `${environment.api}/workspace/upcoming/invited`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map(data => {
            const c = Object.entries(data);
            let invited: BookingClass[] = []
            for (const [key, value] of c) {
              const bookings = (value as any[]).map(booking => new BookingClass(booking, { invitation: true}));
              invited.push(...bookings)
            }
            return invited;
          })
        )
      }),
    )
  }

  loadInvitedByOwner() {
    const url = `${environment.api}/workspace/upcoming/invited`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map(data => {
            const c = Object.entries(data);
            let invited: InvitationsType[] = []
            for (const [key, value] of c) {
              invited.push({
                owner: key,
                invites: value as Booking[]
              });
            }
            return invited;
          })
        )
      }),
    )
  }

  loadChilds() {
    const url = `${environment.api}/workspace/featured`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data)
        )
      }),
    )
  }


  loadWorkspaces() {
    const url = `${environment.api}/workspaces`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as WorkspacesResponse)
        )
      }),
    )
  }

  loadMoreWorkspaces(results: number) {
    const url = `${environment.api}/workspace/paginate?maxResults=${results}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as WorkspacesResponse)
        )
      }),
    );
  }

  searchWorkspaces(search: string) {
    const url = `${environment.api}/workspace/filter`;
    const formData = new FormData();
    formData.append('search', search);
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as Workspace[])
        )
      }),
    );
  }

  generateFormData(obj: any) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      formData.append(key, `${value}`);
    }
    return formData;
  }

  applySearchWorkspaces(obj: any) {
    const url = `${environment.api}/workspace/filter`;
    const formData = this.generateFormData(obj);
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as Workspace[])
        )
      }),
    );
  }

  cancelPending(id){
    const url = `${environment.api}/my-office/change/status/${id}?status=rejected`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, {headers} ).pipe(
          map((response: MetaResponse) => response.data)
        )
      }),
    );
  }
}
