import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-woke-section-actions',
  templateUrl: './woke-section-actions.component.html',
  styleUrls: ['./woke-section-actions.component.scss']
})
export class WokeSectionActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
