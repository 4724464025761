import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { PaymentMethod } from 'src/app/api.interfaces';
import { filter, map, tap, takeUntil } from 'rxjs/operators';
import { AddPaymentMethodComponent } from '../add-payment-method/add-payment-method.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit, OnDestroy {

  partialIndex: string;
  modalConfig: ModalOptions = {
    class: 'modal-dialog-centered modal-xl'
  };

  paymentMethods$ = this.walletFacade.paymentMethods$;
  paymentDefault$ = this.walletFacade.paymentDefault$;

  killall = new Subject();

  constructor(
    private modalService: BsModalService,
    private walletFacade: WalletFacade,
  ) { }

  ngOnInit() {
    this.paymentDefault$.pipe(
      filter(paymentDefault => paymentDefault !== undefined),
      map(paymentMethod => paymentMethod.id),
      takeUntil(this.killall)
    ).subscribe({
      next: id => {
        this.partialIndex = id;
      },
      error: error => {
        console.log(error);
      },
      complete: () => console.log('Stop Listening Payment Default Changes on Sselect Payment')
    });
  }

  ngOnDestroy() {
    this.closeModal();
    this.killall.next();
    this.killall.complete();
  }

  addNew() {
    this.closeModal();
    setTimeout(() => {
      this.modalService.show(AddPaymentMethodComponent, this.modalConfig);
    }, 1000);
  }

  partialSelect(paymentMethod: PaymentMethod) {
    this.partialIndex = paymentMethod.id;
  }

  selectPaymentMethod() {
    if (this.partialIndex) {
      this.walletFacade.setPaymentMethodDefault(this.partialIndex);
      this.closeModal();
    }
  }

  deletePaymentMethod(paymentMethod: PaymentMethod) {
    this.walletFacade.deletePaymentMethod(paymentMethod.id);
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  getClass = (brand: string) => {
    switch (brand) {
      case 'MasterCard':
        return 'master_icon';
      case 'Visa':
        return 'visa_icon';
      case 'American Express':
        return 'american_icon';
      default:
        return 'nobrand';
    }
  }


}
