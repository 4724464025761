import { ProfileData, Category, MyOffice, ExtraOffice } from 'src/app/api.interfaces';
import { MyLicense } from 'src/app/logged/my-license/my-license.types';

export interface ProfileState  {
  data?: ProfileData,
  loading: boolean,
  loaded: boolean,
  businessProfile?: ProfileData,
  categories: Category[],
  offices: MyOffice[],
  extraOffices:ExtraOffice[],
  licenses: MyLicense[],
}

export const initialState: ProfileState = {
  loaded: false,
  loading: false,
  categories: [],
  offices: [],
  extraOffices: [],
  licenses: []
};
