import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter,Output, ContentChild, TemplateRef, OnChanges, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-woke-select',
  templateUrl: './woke-select.component.html',
  styleUrls: ['./woke-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WokeSelectComponent),
      multi: true
    }
  ]
})
export class WokeSelectComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  private eventsSubscription: Subscription;

  @Input() value: any;
  @Input() closeSelect: boolean;
  @Input() placeholder: string;
  @Input() options = [];
  @Input() valueAccesor: string;
  @Input() noValue:boolean;
  @Input() className:string
  @Output()
  category = new EventEmitter<string>();

  @ContentChild('item', { static: true}) item: TemplateRef<any>;
  @ContentChild('selected', { static: true}) selected: TemplateRef<any>;

  showOptions = false;
  showPlaceholder:boolean = false

  constructor() {

  }

  change(value: any) {
    this.onChange(value);
  }

  onChange = (delta: any) => { };


  writeValue(obj: any): void {

    // Esto para limpiar los inputs desde afuera
    if(obj === '' ){
      this.value = ''
      return this.value
    }

    if(obj === null ){
      this.value = null
      return this.value
    }



    if (this.valueAccesor && this.options && this.noValue === false) {
      console.log(this.valueAccesor);

      const tania = this.options.find(option => option[this.valueAccesor] === obj);
      console.log(tania, obj);
      if (!tania) {
        this.value = obj;
      } else {
        this.value = tania;
      }
    }else if(this.valueAccesor && this.options && this.noValue){
    }else{
      this.value = obj;



    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnInit() {
  }

  ngOnDestroy(){
  }


  ngOnChanges(changes: any) {
    if (changes && changes.options && changes.options.currentValue) {
      const options = changes.options.currentValue;
      if (this.valueAccesor && options) {
        let polloyon = options.find(option => option[this.valueAccesor] === this.value);
        this.value = polloyon;
      }
    }
    if(this.placeholder === "Add category" || this.placeholder === "Agregar categoría"){
      this.showPlaceholder = true
    }
  }

  toggleShowOptions() {
    this.showOptions = !this.showOptions;
  }

  selectOption(option: any) {
    this.value = option;
    //console.log("option", option);

    this.showOptions = false;
    if (this.valueAccesor) {
      this.change(option[this.valueAccesor]);
      this.category.emit(option[this.valueAccesor]);
    } else {
      this.change(option);
      this.category.emit(option);
    }
  }

}
