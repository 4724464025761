import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, AfterViewInit } from '@angular/core';
import Swiper from 'swiper';
import { BookingClass } from 'src/app/logged/workspaces/workspace/models/workspaces.models';


@Component({
  selector: 'app-woke-carousel',
  templateUrl: './woke-carousel.component.html',
  styleUrls: ['./woke-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class WokeCarouselComponent implements OnInit, OnDestroy, AfterViewInit {

  swiper:any;

  constructor() { }

  @Input() slides:BookingClass[]
  @Input() invited = false;
  @Input() pending = false;
  @Input() upcoming = false;
  @Input() reference:string;

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.initSwiper()
  }

  ngOnDestroy(){
    this.swiper.destroy();
  }

  initSwiper(){
    this.swiper = new Swiper(`#${this.reference}`, {

      autoHeight: true,
      observer: true,
      observeParents:true,
      spaceBetween: 5,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });
    setTimeout(() => {
      this.swiper.update()
    }, 500);
    this.swiper.on('tap', function(){
      setTimeout(() => {
        this.update()
      }, 400);
    })
    this.swiper.on('observerUpdate',function(){
      setTimeout(() => {
        this.update()
      }, 300);
    })
  }

}
