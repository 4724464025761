import { Injectable } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';
import { HttpClient } from '@angular/common/http';
import { switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MetaResponse } from 'src/app/api.interfaces';
import { BookingHistoryItem, UserFound } from '../my-office/my-office.models';
import { MyLicense, MyLicenseMember } from './my-license.types';
import { LicenseType } from '../workspaces/workspace/plan/plan.models';

@Injectable({
  providedIn: 'root'
})
export class MyLicenseService {

  constructor(
    private tokenFacade: TokenFacade,
    private http: HttpClient
  ) {}

  loadMyLicenseDetail(myLicenseId: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-license/detail/${myLicenseId}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }

  editLicenseName(id:string, name:string){
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/licenses/edit/${id}`;
        const formData = new FormData();
        formData.append('name', name);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }

  loadBookingHistory(LicenseId: string) {
    const url = `${environment.api}/my-licenses/booking-history/${LicenseId}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(url, { headers })
        .pipe(
          map((response: MetaResponse) => response.data as BookingHistoryItem[]),
        )
      )
    )
  }

  rateBooking(bookingId: number, rating: number, comments: string) {
    const url = `${environment.api}/workspace/rate/new`;
    const formData = new FormData();
    formData.append('id', `${bookingId}`);
    formData.append('rating', `${rating}`);
    formData.append('comments', `${comments}`);
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.post(url, formData, { headers })
        .pipe(
          map((response: MetaResponse) => response.data),
        )
      )
    )
  }

  searchUser(username: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/chat/search?search=${username}`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map((users: UserFound[]) => {
            return users.find(user => user.username === username)
          })
        )
      })
    )
  }

  addTeamMember(myLicense: MyLicense, user: UserFound) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-license/add`;
        const formData = new FormData();
        formData.append('id', `${myLicense.id}`);
        formData.append('idUser', `${user.id}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }


  getLicenses() {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/credit_packages`;
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as LicenseType[]),
        )
      })
    )
  }


  deleteTeamMember(myLicense: MyLicense, user: MyLicenseMember) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const url = `${environment.api}/my-license/delete`;
        const formData = new FormData();
        formData.append('id', `${myLicense.id}`);
        formData.append('idUser', `${user.id}`);
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data),
        )
      })
    )
  }


}
