import { createReducer, on, Action } from '@ngrx/store';
import { adapter, CoworkingChildsState } from './childs.state';
import { CoworkingChildsActions } from './childs.actions';
import { Update } from '@ngrx/entity';
import { CoworkingChild } from 'src/app/api.interfaces';

const initialState: CoworkingChildsState = adapter.getInitialState({
  loading: false,
  loaded: false
})

export const coworkingChildsReducer = createReducer(
  initialState,
  on(
    CoworkingChildsActions.loadOne,
    CoworkingChildsActions.load,
    CoworkingChildsActions.loadGallery,
    (state) => ({ ...state, loading: true, loaded: false })
  ),
  on(
    CoworkingChildsActions.loadOneError,
    CoworkingChildsActions.loadError,
    CoworkingChildsActions.loadGalleryError,
    (state) => ({ ...state, loading: false, loaded: false })
  ),
  on(
    CoworkingChildsActions.loadSuccess,
    (state, { items }) => adapter.addAll(items, { ...state, loading: false, loaded: true })
  ),
  on(
    CoworkingChildsActions.loadGallerySuccess,
    (state, { items, id }) => {
      const itemUpdate: Update<CoworkingChild> = {
        id,
        changes: { gallery: items }
      };
      return adapter.updateOne(itemUpdate, { ...state, loading: false, loaded: true });
    }
  ),
  on(
    CoworkingChildsActions.loadOneSuccess,
    (state, { item }) => adapter.upsertOne(item, { ...state, loading: false, loaded: true })
  )
)

export function reducer(state: CoworkingChildsState | undefined, action: Action) {
  return coworkingChildsReducer(state, action);
}
