import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { switchMap, switchMapTo } from 'rxjs/operators';
import { TokenFacade } from '../token/token.facade';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(
    private tokenFacade: TokenFacade,
    private http: HttpClient,
  ) { }

  // Payment Methods
  newPaymentMethod(tokenId: string) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        const formData = new FormData();
        formData.append('token_id', tokenId);
        return this.http.post(`${environment.api}/wallet/stripe/card/new`, formData, { headers }).pipe(
          switchMap(() => {
            return this.http.get(`${environment.api}/wallet/stripe/payment-method`, { headers })
          })
        );
      })
    )
  }

  loadPaymentMethods(headers: HttpHeaders) {
    return this.http.get(`${environment.api}/wallet/stripe/payment-method`, { headers })
  }

  deletePaymentMethod(headers: HttpHeaders, id: string) {
    return this.http.delete(`${environment.api}/wallet/stripe/remove/card/${id}`, { headers })
  }

}
