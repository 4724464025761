import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CheckoutWorkspaceService } from '../checkout-workspace/checkout-workspace.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SelectPaymentComponent } from '../select-payment/select-payment.component';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AddPaymentComponent } from '../add-payment/add-payment.component';
import { BillingInformation, PaymentMethod } from 'src/app/api.interfaces';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-pay-with-money',
  templateUrl: './pay-with-money.component.html',
  styleUrls: ['./pay-with-money.component.scss']
})
export class PayWithMoneyComponent implements OnInit, OnDestroy {
  modalConfig: ModalOptions = { class: 'modal-dialog-centered modal-xl' };

  bookingInfo$ = this.checkoutWorkspaceService.bookingInfo$;
  paymentDefault$ = this.checkoutWorkspaceService.paymentDefault$;

  @Output() onPayment = new EventEmitter<PaymentMethod>();

  billingInformation: BillingInformation;

  killall = new Subject();

  cfdiControl = this.fb.control('Use of CFDI');

  cfdis = [
    {
      id: 1,
      name: 'I06 - Comunicaciones Telefónicas',
    },
    {
      id: 2,
      name: 'G03 - Gastos en General',
    },
    {
      id: 3,
      name: 'P01 - Por definir',
    }
  ]

  constructor(
    private checkoutWorkspaceService: CheckoutWorkspaceService,
    private modalService: BsModalService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.checkoutWorkspaceService.loadPaymentMethods();
    this.checkoutWorkspaceService.loadBillingInformation().pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: billingInformation => {
        this.billingInformation = billingInformation;
      }
    });
    this.paymentDefault$.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: payment => {
        this.onPayment.emit(payment);
      }
    })
  }

  ngOnDestroy() {
    this.closeModal();
    this.killall.next();
    this.killall.complete();
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  changePaymentMethod() {
    const modalRef = this.modalService.show(SelectPaymentComponent, this.modalConfig);
    // modalRef.content.selected.pipe(
    //   take(1),
    //   takeUntil(this.killall)
    // ).subscribe(() => {
    //   this.closeModal();
    //   this.checkoutWorkspaceService.loadPaymentMethods();
    // });
  }

  addPaymentMethod() {
    this.modalService.show(AddPaymentComponent, this.modalConfig);
  }

}
