import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-woke-tag',
  templateUrl: './woke-tag.component.html',
  styleUrls: ['./woke-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WokeTagComponent implements OnInit {

  @Input() editable: boolean;

  @Output() delete = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  onDelete() {
    this.delete.emit();
  }

}
