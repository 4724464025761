import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//PUBLIC PAGES
import { AboutComponent } from './public-pages/about/about.component';
import { ContactComponent } from './public-pages/contact/contact.component';
import { LoginComponent } from './public-pages/login/login.component';
import { NetworkComponent } from './public-pages/network/network.component';
import { PublicPagesComponent } from './public-pages/public-pages.component';
import { RegisterComponent } from './public-pages/register/register.component';
import { BusinessProfileRegisterComponent } from './public-pages/business-profile-register/business-profile-register.component'
import { TermsConditionsComponent } from './public-pages/terms-conditions/terms-conditions.component'
import { PrivacyPolicyComponent } from './public-pages/privacy-policy/privacy-policy.component'
import { ResetPasswordComponent } from './public-pages/reset-password/reset-password.component';
import { RegisterLiteComponent } from './public-pages/register-lite/register-lite.component';
import { LandingComponent } from './public-pages/landing/landing.component';
import { RecoverLiteComponent } from './public-pages/recover-lite/recover-lite.component';
import { RegisterSpaceComponent } from './public-pages/register-space/register-space.component';
import { SpaceRegisterComponent } from './public-pages/space-register/space-register.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PublicPagesComponent, children: [
          { path: '', component: LandingComponent },
          { path: 'login', component: LoginComponent },
          { path: 'about', component: AboutComponent },
          { path: 'contact', component: ContactComponent },
          { path: 'contact-space', component: ContactComponent },
          { path: 'event/:id', component: NetworkComponent },
          { path: 'register', component: RegisterComponent },
          { path: 'space-register', component: SpaceRegisterComponent },
          { path: 'register-space', component: RegisterSpaceComponent },
          { path: 'registerlite', component: RegisterLiteComponent},
          { path: 'recoverlite', component: RecoverLiteComponent},
          { path: 'business-profile', component: BusinessProfileRegisterComponent},
          { path: 'terms-and-conditions', component: TermsConditionsComponent},
          { path: 'privacy-policy', component: PrivacyPolicyComponent},
          { path: 'reset-password/:id', component: ResetPasswordComponent}

        ]
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./logged/logged.module').then(mod => mod.LoggedModule),
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
