import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { switchMap, map, filter } from 'rxjs/operators';
import { MetaResponse, Workspace } from 'src/app/api.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CoworkingSpaceService {

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade
  ) { }

  loadWorkspaceDetail(idWorkspace: string) {
    const url = `${environment.api}/workspace/${idWorkspace}`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data),
          map(data => Array.isArray(data) && data.length ? data[0] as Workspace : undefined),
          filter(data => data !== undefined)
        )
      }),
    )
  }

  parseImages(image: string) {
    return `${environment.imageUrl}${image}`;
  }
}
