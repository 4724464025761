import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TokenResponse } from 'src/app/api.interfaces';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private http: HttpClient) {}

  refreshToken(token: string) {
    const formData = new FormData();
    formData.append('refresh_token', token);
    return this.http.post(`${environment.api}/token/refresh`, formData).pipe(
      map(response => response as TokenResponse)
    )
  }

  loadOnboardings(idUser: number) {
    const url = `${environment.firebaseApi}/woke/onboardings/${idUser}`;
    return this.http.get(url);
  }

  finishOnboarding(section: string, idUser: number) {
    const url = `${environment.firebaseApi}/woke/onboardings/${idUser}`;
    return this.http.post(url, { section });
  }
}
