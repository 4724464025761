import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { WokeHeightDirective } from '../woke-height.directive';
import { TokenFacade } from 'src/store/token/token.facade';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-woke-accordeon',
  templateUrl: './woke-accordeon.component.html',
  styleUrls: ['./woke-accordeon.component.scss']
})
export class WokeAccordeonComponent implements OnInit {
  @ViewChild(WokeHeightDirective, { static: false }) wokeHeight: WokeHeightDirective;

  @Input() label = '';

  closed = true;
  maxHeight = 0;
  initial$ = new Subject<number>();

  darkMode$ = this.tokenFacade.darkMode$;


  constructor(
    private tokenFacade: TokenFacade
  ) { }

  ngOnInit() {
    this.initial$.subscribe({
      next: height => {
        this.wokeHeight.setHeight(height);
      }
    })
  }

  toggle() {
    this.closed = !this.closed;
    if (this.closed) {
      this.initial$.next(0);
    } else {
      this.initial$.next(4000);
    }
  }
}
