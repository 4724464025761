import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { WalletFacade } from './wallet.facade';
import { EffectsModule } from '@ngrx/effects';
import { PaymentMethodsEffects } from './payment-methods/payment-methods.effects';
import { reducer as paymentMethods } from './payment-methods/payment-methods.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('Wallet', {
      paymentMethods,
    }),
    EffectsModule.forFeature([
      PaymentMethodsEffects,
    ])
  ],
  providers: [
    WalletFacade
  ]
})
export class WalletStoreModule { }
