import { createReducer, on, Action } from '@ngrx/store';
import { QuickBookingState } from './quick.state';
import { QuickBookingActions } from './quick.actions';
import { BookingClass } from 'src/app/logged/workspaces/workspace/models/workspaces.models';

const initialState: QuickBookingState = {
  loaded: false,
  loading: false,
  booking: <BookingClass>{},
}


export const reducerInstace = createReducer(
  initialState,
  on(
    QuickBookingActions.bookingProcess,
    (state => ({ ...state, loading: true, loaded: false }))
  ),
  on(
    QuickBookingActions.bookingProcessSuccess,
    (state => ({ ...state, loading: false, loaded: true }))
  ),
  on(
    QuickBookingActions.bookingProcessError,
    (state => ({ ...state, loading: false, loaded: false }))
  ),
  on(
    QuickBookingActions.setQuickModel,
    ((state, { quickModel }) => ({ ...state, quickModel }))
  ),
  on(
    QuickBookingActions.setBookingInfo,
    ((state, { bookingInfo }) => ({ ...state, bookingInfo }))
  )
)

export function reducer(state: QuickBookingState | undefined, action: Action) {
  return reducerInstace(state, action);
}
