import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfileState } from './profile.state';
import { PartialBusiness, ProfileData } from 'src/app/api.interfaces';
import { businessProfileSelector } from '../token/token.selectors';
import { environment } from 'src/environments/environment';

export const featureSelector = createFeatureSelector('Profile');

export const profileSelector = createSelector(
  featureSelector,
  businessProfileSelector,
  (state: ProfileState, businessProfile) => businessProfile === false ? state.data : state.businessProfile
);

export const personalProfileSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.data
);


export const profileIdSelector = createSelector(
  profileSelector,
  state => state ? state.id : undefined
);


export const nameSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state !== undefined ? `${state.firstName} ${state.lastName}` : ''
);

export const allCategoriesSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.categories
);

export const roleSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state ? state.role : ''
);

export const businessSelector = createSelector(
  personalProfileSelector,
  state => state ? state.business : <PartialBusiness>{}
);

// Business Username
export const businessUsernameSelector = createSelector(
  businessSelector,
  state => state ? state.username : ''
);


// Profile Pictures
export const profilePictureSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state && state.userInformation && state.userInformation.image  ?
    environment.resourceUrl + state.userInformation.image : null
);


// Categories
export const categoriesSelector = createSelector(
  profileSelector,
  allCategoriesSelector,
  (state: ProfileData, categories) => state && state.professionalInformation ?
    state.professionalInformation.category.map(category => {
      return categories.find(cat => `${cat.id}` == category)
    }) : []
);


// Specialties
export const specialtiesSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state && state.professionalInformation ?
    state.professionalInformation.specialties : []
);


// Portfolios
export const portfolioSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state && state.professionalInformation ?
    state.professionalInformation.portfolio : []
);


// Academic Formations
export const academicFormationsSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state && state.professionalInformation && state.professionalInformation.curriculum ?
    state.professionalInformation.curriculum.academicInformation : []
);


// Experiences
export const experienceSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state && state.professionalInformation && state.professionalInformation.curriculum ?
    state.professionalInformation.curriculum.experience : []
);

// Recommendations
export const recommendationsSelector = createSelector(
  profileSelector,
  (state: ProfileData) => state && state.professionalInformation && state.professionalInformation.curriculum ?
    state.professionalInformation.curriculum.recommendations : []
);

/**
 * OFFICES LICENSES EXTRA OFFICES
 */

// Offices
export const officesSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.offices
)

export const extraofficesSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.extraOffices
)

export const officesValidSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.offices.filter(office => office.valid)
)

// Licenses
export const licensesSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.licenses
)

// Loading
export const loadingSelector = createSelector(
  featureSelector,
  (state: ProfileState) => state.loading
)
