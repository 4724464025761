import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-woke-subsection-actions',
  templateUrl: './woke-subsection-actions.component.html',
  styleUrls: ['./woke-subsection-actions.component.scss']
})
export class WokeSubsectionActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
