import { Component, OnInit, Input } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';

@Component({
  selector: 'app-woke-input',
  templateUrl: './woke-input.component.html',
  styleUrls: ['./woke-input.component.scss']
})
export class WokeInputComponent implements OnInit {

  @Input() placeholder = 'Search';

  darkMode$ = this.tokenFacade.darkMode$;

  constructor(private tokenFacade: TokenFacade) { }

  ngOnInit() {
  }

}
