import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckoutRoutingModule } from './checkout-routing.module';
import { LicenseComponent } from './license/license.component';
import { SelectPaymentMethodComponent } from './components/select-payment-method/select-payment-method.component';
import { AddPaymentMethodComponent } from './components/add-payment-method/add-payment-method.component';
import { DefaultPaymentMethodComponent } from './components/default-payment-method/default-payment-method.component';
import { WokeModule } from 'src/app/woke/woke.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxBootstrapModule } from 'src/app/externalModules/ngx-bootstrap.module';
import { HttpClientModule } from '@angular/common/http';
import { CheckoutWorkspaceModule } from '../wallet/checkout-workspace/checkout-workspace.module';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { SelectInvoiceComponent } from './components/select-invoice/select-invoice.component';
import { StripeModule } from 'stripe-angular';
import { NetworkComponent } from './network/network.component';
import { OfficeComponent } from './office/office.component';
import { CheckoutBookingComponent } from './checkout-booking/checkout-booking.component';
import { BookingInvitesComponent } from './components/booking-invites/booking-invites.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [LicenseComponent, SelectPaymentMethodComponent, AddPaymentMethodComponent, DefaultPaymentMethodComponent, SelectInvoiceComponent, NetworkComponent, OfficeComponent, CheckoutBookingComponent, BookingInvitesComponent],
  imports: [
    CommonModule,
    CheckoutRoutingModule,
    WokeModule,
    ReactiveFormsModule,
    FormsModule,
    NgxBootstrapModule,
    HttpClientModule,
    CheckoutWorkspaceModule,
    UiSwitchModule,
    StripeModule,
    TranslateModule
  ],
  entryComponents: [AddPaymentMethodComponent, SelectPaymentMethodComponent]
})
export class CheckoutModule { }
