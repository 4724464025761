
import { Component, OnInit, Input } from '@angular/core';
import { ChatConversationClass } from 'src/app/api.interfaces';

@Component({
  selector: 'app-woke-chat-item',
  templateUrl: './woke-chat-item.component.html',
  styleUrls: ['./woke-chat-item.component.scss']
})
export class WokeChatItemComponent implements OnInit {

  @Input() chat: ChatConversationClass = <ChatConversationClass>{};

  constructor() { }

  ngOnInit() {
  }

  getMessage(){
    if(this.chat.message.message){
      let result = this.chat.message.message;
      let resultArray = result.split(" ");
      if(resultArray.length > 2){
      resultArray = resultArray.slice(0, 2);
      result = resultArray.join(" ") + "..."; ;
      if(result[0].length > 15){
        result = result.slice(0,15)+ "...";
      }

      }
      return result
    }else{
      return null
    }
}

}
