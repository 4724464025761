import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';

@Component({
  selector: 'app-onboarder',
  templateUrl: './onboarder.component.html',
  styleUrls: ['./onboarder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboarderComponent implements OnInit {

  currentOnboarding$ = this.tokenFacade.currentOnboarding$;
  currentSection$ = this.tokenFacade.currentSection$;
  isFinished$ = this.tokenFacade.isFinished$;
  canShowOnboarding$ = this.tokenFacade.canShowOnboarding$;

  constructor(
    private tokenFacade: TokenFacade
  ) { }

  ngOnInit() {}

  continue(event: MouseEvent) {
    event.stopPropagation();
    this.tokenFacade.nextSection();
  }

  done(event: MouseEvent) {
    if(event){
      event.stopPropagation();
    }
    this.tokenFacade.doneSection();
  }

}
