import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Workspace } from 'src/app/api.interfaces';
import { TokenFacade } from 'src/store/token/token.facade';
import { LoggedService } from 'src/app/logged/logged.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

type Star = {
  state: 'active' | 'inactive';
}
@Component({
  selector: 'app-woke-booking',
  templateUrl: './woke-booking.component.html',
  styleUrls: ['./woke-booking.component.scss']
})
export class WokeBookingComponent implements OnInit {
  @Input() pastBooking = false;
  @Input() upcomingBooking = false;
  @Input() cardForBooking = false;
  @Input() workspace: Workspace;

  @Output() bookWorkspace: EventEmitter<Workspace> = new EventEmitter();

  darkMode$ = this.tokenFacade.darkMode$;
  stars: Star[] = [];
  rating:number;
  language$ = this.tokenFacade.language$
  killall = new Subject()

  constructor(
    private tokenFacade: TokenFacade,
    public loggedService: LoggedService
  ) { }

  ngOnInit() {

    this.rating = this.workspace.coworkingChild.rating
    this.fillStars();
  }

  book(workspace: Workspace) {
    this.bookWorkspace.emit(workspace);
  }

  fillStars() {
    for(let i = 0; i < 5; i++) {
      this.stars.push({
        state: (i < this.rating && this.rating) ? 'active' : 'inactive'
      })
    }
  }

}
