import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
@Component({
  selector: 'app-woke-terms',
  templateUrl: './woke-terms.component.html',
  styleUrls: ['./woke-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WokeTermsComponent implements OnInit {

  @Input() content:any;

  constructor() { }

  ngOnInit() {
  }



}
