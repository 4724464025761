import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, withLatestFrom, catchError, tap } from 'rxjs/operators';
import { TokenFacade } from '../token/token.facade';
import { QuickBookingActions } from './quick.actions';
import { QuickBookingService } from './quick.service';
import { Router } from '@angular/router';

@Injectable()
export class QuickBookingEffects {



  @Effect() bookingProcess$: Observable<Action> = this.actions$.pipe(
    ofType(QuickBookingActions.bookingProcess),
    withLatestFrom(this.tokenFacade.headers$),
    switchMap(([{ workspaceId, checkoutViewModel }, headers]) => {
      return this.api.bookingProcess(headers, workspaceId, checkoutViewModel)
        .pipe(
          map((response) => QuickBookingActions.bookingProcessSuccess()),
          tap(() => {
            this.router.navigate(['workspace/']);
          }),
          catchError(() => of(QuickBookingActions.bookingProcessError()))
        )
    })
  );

  constructor(
    private actions$: Actions,
    private api: QuickBookingService,
    private tokenFacade: TokenFacade,
    private router: Router
  ) { }
}
