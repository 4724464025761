import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TokenFacade } from 'src/store/token/token.facade';
import { switchMap, map } from 'rxjs/operators';
import { MetaResponse, Payment, BillingInformation, CFDI } from 'src/app/api.interfaces';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { BookingHistoryItem } from '../my-office/my-office.models';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  paymentMethods$ = this.walletFacade.paymentMethods$;

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade,
    private walletFacade: WalletFacade,
  ) { }

  loadBookingHistory() {
    const url = `${environment.api}/wallet/booking-history`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data ? response.data : [] as BookingHistoryItem[])
        )
      }),
    )
  }

  loadPaymentHistory() {
    const url = `${environment.api}/wallet/payment-history`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data ? response.data : [] as Payment[])
        )
      }),
    )
  }

  loadBillingInformation() {
    const url = `${environment.api}/wallet/billing-information`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as BillingInformation)
        )
      })
    )
  }

  loadCFDI() {
    const url = `${environment.api}/wallet/cdfis`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as CFDI[])
        )
      })
    )
  }

  loadPaymentMethods() {
    this.walletFacade.loadPaymentMethods();
  }

  deletePaymentMethod(id: string) {
    this.walletFacade.deletePaymentMethod(id);
  }

  createFormData(payload: any): FormData {
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (value && key !== 'username' && key !== 'email') {
        formData.append(key, value as string);
      }
    }
    return formData;
  }

  saveBillingInformation(payload: BillingInformation) {
    const formData = this.createFormData(payload);
    const url = `${environment.api}/wallet/billing-information/manage`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.post(url, formData, { headers }).pipe(
          map((response: MetaResponse) => response.data as BillingInformation)
        )
      })
    )
  }


}
