import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PaymentMethod } from 'src/app/api.interfaces';

export interface PaymentMethodsState extends EntityState<PaymentMethod> {
  loading: boolean,
  loaded: boolean,
  paymentDefault: string
}

export const paymentMethodsAdapter: EntityAdapter<PaymentMethod> = createEntityAdapter<PaymentMethod>()
