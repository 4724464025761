import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';
import { MetaResponse, NetworkItem } from 'src/app/api.interfaces';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(private http: HttpClient, private tokenFacade: TokenFacade) { }
  getEvent(id: string) {
    const url = `${environment.api}/network/event/no_auth/${id}`;
    return this.http.get(url).pipe(
      map((response: MetaResponse) => response.data as NetworkItem)
    )
  }
}
