import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { MetaResponse } from 'src/app/api.interfaces';

export type ContactFormType = {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
};

export type ContactSpaceFormType = {
  name: string;
  space_name: string;
  mail: string;
  phone: string;
  message: string;
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) {}

  saveFormContact(payload: ContactFormType) {
    const url = environment.firebaseApi + '/contactForms';
    return this.http.post(url, payload).pipe(
      map((response: MetaResponse) => response.data)
    );
  }

  contactSpace(payload: ContactSpaceFormType){
    const url = `${environment.api}/getMoreInformation`;
    const formData =  this.generateFormData(payload);
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data)
    )
  }

  generateFormData(obj: any) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      formData.append(key, `${value}`);
    }
    return formData;
  }

}
