import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MyOffice, FixedService, DynamicService } from 'src/app/api.interfaces';
import { LoggedService } from 'src/app/logged/logged.service';

@Component({
  selector: 'app-woke-office-detail',
  templateUrl: './woke-office-detail.component.html',
  styleUrls: ['./woke-office-detail.component.scss']
})
export class WokeOfficeDetailComponent implements OnInit {

  officeDetailCollapsed = true;
  modalRef: BsModalRef;

  @Input() pending = false;
  @Input() office: MyOffice;
  @Input() detail = true;

  fixed:FixedService[]
  dynamic:DynamicService[]
  servicesSum:number

  constructor(private modalService: BsModalService, public router:Router, public loggedService: LoggedService) { }

  ngOnInit() {
    this.fixed = this.office.payment.fixed
    this.dynamic = this.office.payment.dynamic
    this.servicesSum = this.sumServices()
  }

  sumServices(){
    let fixedSum = this.fixed.reduce((sum: number, b: FixedService) => sum + +b.subtotal, 0);
    let dynamicSum = this.dynamic.reduce((sum: number, b: DynamicService) => sum + +b.subtotal, 0);
    return fixedSum + dynamicSum
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-dialog-centered modal-qr'});
  }

}
