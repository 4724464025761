import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-woke-bookings',
  templateUrl: './woke-bookings.component.html',
  styleUrls: ['./woke-bookings.component.scss']
})
export class WokeBookingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
