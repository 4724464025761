import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

type Star = {
  state: 'active' | 'inactive';
}

@Component({
  selector: 'app-woke-rating',
  templateUrl: './woke-rating.component.html',
  styleUrls: ['./woke-rating.component.scss']
})
export class WokeRatingComponent implements OnInit {

  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() rating: number;
  @Input() canRate = false;
  @Output() rated = new EventEmitter();
  stars: Star[] = [];

  constructor() { }

  ngOnInit() {
    this.fillStars();
  }


  fillStars() {
    for(let i = 0; i < 5; i++) {
      this.stars.push({
        state: i < this.rating && this.rating ? 'active' : 'inactive'
      })
    }
  }

  selectRate(index: number) {
    if (this.canRate) {
      this.stars = this.stars.map(star => ({ ...star, state: 'inactive'}));
      for(let i = 0; i <= index; i++) {
        this.stars[i].state = 'active';
      }
      this.rated.emit(index + 1);
    }
  }

}
