import { createReducer, on, Action } from '@ngrx/store';
import { paymentMethodsAdapter, PaymentMethodsState } from './payment-methods.state';
import { PaymentMethodsActions } from './payment-methods.actions';
import { Update } from '@ngrx/entity';
import { PaymentMethod } from 'src/app/api.interfaces';

const initialState: PaymentMethodsState = paymentMethodsAdapter.getInitialState({
  loading: false,
  loaded: false,
  paymentDefault: undefined
});


export const paymentMethodsReducer = createReducer(
  initialState,
  on(
    PaymentMethodsActions.load,
    PaymentMethodsActions.delete,
    PaymentMethodsActions.update,
    (state => ({ ...state, loading: true, loaded: false }))
  ),
  on(
    PaymentMethodsActions.loadSuccess,
    (state, { items }) => paymentMethodsAdapter.addAll(items, { ...state, loading: false, loaded: true })
  ),
  on(
    PaymentMethodsActions.updateSuccess,
    (state, { item }) => {
      const itemUpdate: Update<PaymentMethod> = {
        id: item.id,
        changes: { ...item }
      };
      return paymentMethodsAdapter.updateOne(itemUpdate, { ...state, loading: false, loaded: true });
    }
  ),
  on(
    PaymentMethodsActions.deleteSuccess,
    (state, { id }) => paymentMethodsAdapter.removeOne(id, { ...state, loading: false, loaded: true })
  ),
  on(
    PaymentMethodsActions.loadError,
    PaymentMethodsActions.deleteError,
    PaymentMethodsActions.updateError,
    (state => ({ ...state, loading: false, loaded: false }))
  ),
  on(
    PaymentMethodsActions.setPaymentDefault,
    ((state, { paymentDefault }) => ({ ...state, paymentDefault }))
  )
)

export function reducer(state: PaymentMethodsState | undefined, action: Action) {
  return paymentMethodsReducer(state, action);
}
