import { Component, OnInit, TemplateRef } from '@angular/core';
import { MyOffice, PaymentMethod } from 'src/app/api.interfaces';
import { CheckoutOfficeResponse, CheckoutOfficePayload } from '../../my-office/my-office.models';
import { Subject, combineLatest } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, map, switchMap, take } from 'rxjs/operators';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { CheckoutService } from '../checkout.service';
import { isString } from 'util';
import { LoggedService } from '../../logged.service';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent implements OnInit {

  loading = false;
  loadingOffice = false;

  office: MyOffice;
  paymentMethod: PaymentMethod;
  checkoutResponse: CheckoutOfficeResponse;
  invoice = false;
  cfdi: number;

  killall = new Subject();

  constructor(
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private router: Router,
    private walletFacade: WalletFacade,
    private loggedService: LoggedService
  ) { }

  ngOnInit() {
    this.loadingOffice = true;
    this.walletFacade.loadPaymentMethods();
    this.loadInfo();
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  onInvoiceChange(invoice: boolean) {
    this.invoice = invoice;
  }

  onCfdiChange(cfdi: any) {
    this.cfdi = cfdi;
  }

  onPaymentMethodChange(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  loadInfo() {
    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap((id: string) => combineLatest(
        this.checkoutService.loadOfficeDetail(id),
        this.checkoutService.loadOfficeCheckoutInfo(id)
      )),
      take(1),
      takeUntil(this.killall),
    ).subscribe({
      next: ([office, checkoutResponse]) => {
        this.loadingOffice = false;
        this.office = office;
        this.checkoutResponse = checkoutResponse;
      },
      error: error => {
        console.warn(error);
        this.loadingOffice = false;
        this.loggedService.showError(error);
      },
    });
  }

  confirm(template: TemplateRef<any>) {
    this.loading = true;
    const payload: CheckoutOfficePayload = {
      source_id: this.paymentMethod.id,
      invoice: this.invoice === true ? 'yes' : 'no'
    };
    this.checkoutService.loadOfficeCheckoutProcess(payload, this.office, this.cfdi).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: data => {
        this.loading = false;
        if (isString(data)) {
          return this.loggedService.showError(data);
        }
        if (data.msg === 'succeeded') {
          return this.loggedService.openModal(template);
        }
      },
      error: error => {
        console.warn(error);
        this.loading = false;
        return this.loggedService.showError(error);
      }
    })
  }

  goToDetail() {
    console.log("succ");

    this.router.navigate(['/office/detail/' + this.office.id])
    this.loggedService.closeModal();
  }


}
