import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { PaymentMethodsActions } from './payment-methods.actions';
import { switchMap, map, withLatestFrom, catchError } from 'rxjs/operators';
import { MetaResponse, PaymentMethod } from 'src/app/api.interfaces';
import { TokenFacade } from '../../token/token.facade';
import { WalletService } from '../wallet.service';

@Injectable()
export class PaymentMethodsEffects {

  @Effect() load$: Observable<Action> = this.actions$.pipe(
    ofType(PaymentMethodsActions.load),
    withLatestFrom(this.tokenFacade.headers$),
    switchMap(([_, headers]) => {
      return this.api.loadPaymentMethods(headers).pipe(
        map((response: MetaResponse) => response.data !== null ? response.data : []),
        map((items: PaymentMethod[]) => PaymentMethodsActions.loadSuccess({ items })),
        catchError(() => of(PaymentMethodsActions.loadError()))
      )
    })
  );

  @Effect() delete$: Observable<Action> = this.actions$.pipe(
    ofType(PaymentMethodsActions.delete),
    withLatestFrom(this.tokenFacade.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.deletePaymentMethod(headers, id).pipe(
        map((response: MetaResponse) => response.data),
        map(() => PaymentMethodsActions.deleteSuccess({ id })),
        catchError(() => of(PaymentMethodsActions.deleteError()))
      )
    })
  );

  constructor(
    private actions$: Actions,
    private api: WalletService,
    private tokenFacade: TokenFacade,
  ) { }
}
