import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import Swiper from 'swiper';
import { ApiService } from 'src/app/api.service';
import { Subject } from 'rxjs';
import { calcuResponse } from 'src/app/api.interfaces';
import { TokenFacade } from 'src/store/token/token.facade';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-space',
  templateUrl: './register-space.component.html',
  styleUrls: ['./register-space.component.scss']
})
export class RegisterSpaceComponent implements OnInit, AfterViewInit, OnDestroy {
  swiperSpaces:any;

  perHour: number = 0
  perDay: number = 0
  openSpan = false
  calcuForm: FormGroup;
  killall = new Subject();
  language:string;
  calcuResponse = {
    unit_price:'0',
    total:0
  }

  constructor(private fb: FormBuilder,private apiService: ApiService, private tokenFacade: TokenFacade, private translate: TranslateService) { }

  ngOnInit(){
    this.tokenFacade.language$.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: lan =>{
        this.language = lan
      }
    })

    this.initForm();

    this.calcuForm.valueChanges.pipe(
      takeUntil(this.killall)
    ).subscribe({
      next: () =>{
        if (this.calcuForm.get('estate').value && this.calcuForm.get('capacity').value) {
          this.calculate()
        }

      },
      error: err =>{
        console.log(err);
      }
    })
  }

  ngAfterViewInit(){
    this.initSwiper();
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
    this.swiperSpaces.destroy();
  }

  switchLanguage(){
    let lang = this.language == 'en' ? 'es' : 'en'
    this.tokenFacade.switchLanguage(lang);
    this.translate.use(lang);
  }

  calculate () {
    const A = this.calcuForm.get('estate').value * this.calcuForm.get('capacity').value
    this.perHour = A * 200 * 40
    this.perDay = A * 300 * 20
    console.log(this.perHour, this.perDay)

  }

  initSwiper(){
    this.swiperSpaces = new Swiper(`#spaces`, {
      autoHeight: true,
      observer: true,
      observeParents:true,
      loop:true,
      loopFillGroupWithBlank: true,
      simulateTouch: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });

    setTimeout(() => {
      this.swiperSpaces.update()
    }, 500);

    this.swiperSpaces.on('slideChange', function () {
      setTimeout(() => {
        this.loopDestroy()
        this.loopCreate()
      }, 500);
    });

    this.swiperSpaces.on('observerUpdate',function(){
      setTimeout(() => {
        this.update()
      }, 300);
    })

    this.swiperSpaces.on('slideChange', function(){
      setTimeout(() => {
        this.update()
      }, 300);
    })

  }

  initForm(){
    this.calcuForm = this.fb.group({
      estate: ['', Validators.required],
      capacity: ['', Validators.required],
    });
  }

}
