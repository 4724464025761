import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-woke-subsection',
  templateUrl: './woke-subsection.component.html',
  styleUrls: ['./woke-subsection.component.scss']
})
export class WokeSubsectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
