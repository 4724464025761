// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseApi: 'https://us-central1-gold-experience.cloudfunctions.net/api',
  mapsApi:'https://maps.googleapis.com/maps/api/geocode/json?',
  MAPS_KEY:'AIzaSyBBDsVzOPIE-TIjBq7_DR61mukHKYCy_zw',

  //Prod
  api: 'https://manager.bauns.co/api/v1',
  gaTrackingId: 'UA-149388217-3',
  resourceUrl: 'https://manager.bauns.co',
  resource: 'https://manager.bauns.co',
  imageUrl: 'https://manager.bauns.co',
  PUSHER_KEY: '45c0c55b4c5ff7d6ae4b',
  PUSHER_APP_CLUSTER: 'us2',
  PUSHER_CHANNEL: 'chat',

  STRIPE_KEY: 'pk_live_BTd3GZnCxWgyZrGFoL5Pdwle0054jFfbCe',

  //Stage
  /*api: 'https://stage.deq.com.mx/bauns-dev/api/v1',
  gaTrackingId: '',
  resourceUrl: 'https://stage.deq.com.mx/bauns-dev',
  resource: 'https://stage.deq.com.mx/bauns-dev',
  resourceCredits:'https://stage.deq.com.mx',
  imageUrl: 'https://stage.deq.com.mx',
  PUSHER_KEY: '1343a32b09b2139add21',
  PUSHER_APP_CLUSTER: 'us2',
  PUSHER_CHANNEL: 'chat',

  STRIPE_KEY: 'pk_test_oXqriiH3dF7Ebh5uaYe1KHgF00nWrwnaz6',*/



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
 *
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/* Stage

PUSHER_APP_ID=868295
PUSHER_KEY=1343a32b09b2139add21
PUSHER_SECRET=4152dcc9ed22b04be0ed
APP_ENV=dev
APP_DEBUG=1
 */

/* #DEV PUSHER
PUSHER_APP_ID=868294
PUSHER_KEY=9bd7150b61fba278aed9
PUSHER_SECRET=b9fc2a0b7a7c77457f6f
APP_ENV=dev
APP_DEBUG=1 */
