import { createAction, props } from '@ngrx/store';
import { QuickModel } from 'src/app/logged/workspaces/workspace/quick/quick.model';
import { CheckoutWorkspaceViewModel } from 'src/app/logged/wallet/checkout-workspace/checkout-workspace/checkout-workspace.view-model';
import { BookingInfo } from 'src/app/logged/workspaces/workspace/models/workspaces.models';

export const QuickBookingActions = {
  // Booking Process
  bookingProcess: createAction(
    `[API] BookingProcess`,
    props<{ workspaceId: string, checkoutViewModel: CheckoutWorkspaceViewModel }>()
  ),
  bookingProcessSuccess: createAction(
    `[API] BookingProcess Success`,
  ),
  bookingProcessError: createAction(
    `[API] BookingProcess Error`
  ),
  // Search
  setQuickModel: createAction(
    `[Local] Set Quick Model`,
    props<{ quickModel: QuickModel }>()
  ),
  // Booking Info
  setBookingInfo: createAction(
    `[Local] Set Booking Info`,
    props<{ bookingInfo: BookingInfo }>()
  ),
}
