import { Store, select } from '@ngrx/store';
import { tokenValueSelector, darkModeSelector, headersSelector,canCreateBusiness, businessProfileSelector, youreInProfileSelector, canSwitchSelector, switchImageSelector, currentOnboardingSelector, currentSectionSelector, isOnboardingFinishedSelector, canShowOnboardingSelector, languageSelector } from './token.selectors';
import { Injectable } from '@angular/core';
import { TokenActions } from './token.actions';
import { TokenState } from './token.state';
import { filter, delay } from 'rxjs/operators';
import { OnboardingClass, OnboardingType } from 'src/app/woke/onboarder/onboarder.models';

@Injectable()
export class TokenFacade {

  headers$ = this.store.pipe(
    select(headersSelector),
    filter(headers => headers !== undefined),
  )

  tokenValue$ = this.store.pipe(
    select(tokenValueSelector),
    filter(token => token ? true : false)
  )

  darkMode$ = this.store.pipe(
    select(darkModeSelector)
  )

  language$ = this.store.pipe(
    select(languageSelector)
  )

  businessProfile$ = this.store.pipe(
    select(businessProfileSelector)
  )



  youreInProfile$ = this.store.pipe(
    select(youreInProfileSelector),
    filter(switchTo => switchTo !== undefined)
  )

  canSwitch$ = this.store.pipe(
    select(canSwitchSelector)
  )

  canCreateBusiness$ = this.store.pipe(
    select(canCreateBusiness)
  )

  switchImage$ = this.store.pipe(
    select(switchImageSelector)
  )

  currentOnboarding$ = this.store.pipe(
    select(currentOnboardingSelector),
    filter(onboarding => onboarding ? true : false)
  )

  currentSection$ = this.store.pipe(
    select(currentSectionSelector),
    filter(section => section ? true : false)
  )

  isFinished$ = this.store.pipe(
    select(isOnboardingFinishedSelector)
  )

  canShowOnboarding$ = this.store.pipe(
    select(canShowOnboardingSelector)
  )

  constructor(private store: Store<TokenState>) { }

  refreshToken(token: string) {
    this.store.dispatch(TokenActions.refreshToken({ token }));
  }

  setToken(value: string) {
    this.store.dispatch(TokenActions.set({ value }));
  }

  toggleDarkMode() {
    this.store.dispatch(TokenActions.toggleDarkMode());
  }


  switchLanguage(lan:string) {
    this.store.dispatch(TokenActions.switchLanguage({ lan }));
  }

  toggleBusinessProfile() {
    this.store.dispatch(TokenActions.toggleBusinessProfile());
  }

  finishOnboarding(onboarding: string) {
    this.store.dispatch(TokenActions.finishOnboarding({ onboarding }));
  }

  setCurrentOnboarding(onboarding: OnboardingType) {
    this.store.dispatch(TokenActions.setCurrenOnboarding({ onboarding }));
    this.store.dispatch(TokenActions.showOnboarding());
  }

  nextSection() {
    this.store.dispatch(TokenActions.nextOnboardingSection());
  }

  doneSection() {
    this.store.dispatch(TokenActions.closeOnboarding());
  }

}
