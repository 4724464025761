import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-business-profile-register',
  templateUrl: './business-profile-register.component.html',
  styleUrls: ['./business-profile-register.component.scss']
})
export class BusinessProfileRegisterComponent implements OnInit {

  constructor( private fb: FormBuilder,
    ) { }

  ngOnInit() {
  }

}
