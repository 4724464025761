import { Component, OnInit } from '@angular/core';
import { ModalsService } from 'src/app/shared/modals/modals.service'


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private wokeService: ModalsService) { }

  content:string;
  title:string;
  loading = true;

  ngOnInit() {
    this.showPolicies();
  }

  showPolicies(){
    this.wokeService.getPrivacy().subscribe({
      next: (response)=>{
        this.content = response.html;
        this.title = "Privacy Policy"
        if(this.content !== ""){
          console.log(this.content);
          this.loading = false
        }
      },
      error: (err) =>{
        console.log(err);
        this.loading = false
      }
    })
  }

}
