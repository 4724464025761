import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QuickBookingState } from './quick.state';

const feature = createFeatureSelector('QuickBooking');

export const bookingSelector = createSelector(feature, (state: QuickBookingState) => state.booking);
export const quickModelSelector = createSelector(feature, (state: QuickBookingState) => state.quickModel);
export const bookingInfoSelector = createSelector(feature, (state: QuickBookingState) => state.bookingInfo);
export const loadedSelector = createSelector(feature, (state: QuickBookingState) => state.loaded);
export const loadingSelector = createSelector(feature, (state: QuickBookingState) => state.loading);

export const bookingTypeSelector = createSelector(
  bookingInfoSelector,
  bookingInfo => bookingInfo ? bookingInfo.type : undefined
);
