import { Component, OnInit, Input } from '@angular/core';
import { Payment } from 'src/app/api.interfaces';

export interface WokeBalance {
  id?: string;
  concept: string;
  date: string;
  value: string | number;
  warning?: boolean;
}

@Component({
  selector: 'app-woke-balance',
  templateUrl: './woke-balance.component.html',
  styleUrls: ['./woke-balance.component.scss']
})
export class WokeBalanceComponent implements OnInit {

  @Input() dynamicColors = false;
  @Input() showTotal = false;
  @Input() shortable = false;

  @Input() payments: Payment[] = [];

  constructor() { }

  ngOnInit() {
  }

}
