import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { NetworkItem } from 'src/app/api.interfaces';
import { NetworkService } from './network.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggedService } from 'src/app/logged/logged.service';
import { Meta, Title } from '@angular/platform-browser';

declare var FB: any;
@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

  event: NetworkItem;
  loading = true;
  FB:any;
  location
  killall = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private networkService: NetworkService,
    public loggedService: LoggedService,
    public router: Router,
    private title: Title,
    private meta: Meta

  ) { }

  // <meta property="og:url"                content="https://woke.mx/event/event.id"/>
  //   <meta property="og:type"               content="article" />
  //   <meta property="og:title"              [content]="event.name" />
  //   <meta property="og:description"        [content]="event.description" />
  //   <meta property="og:image"              content="loggedService.getUrl(event.profilePicture)" />

  ngOnInit() {


    this.activatedRoute.paramMap.pipe(
      map(paramMap => paramMap.get('id')),
      switchMap(id => this.networkService.getEvent(id)),
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: eventInfo => {
        this.event = eventInfo;
        this.updateTag(this.event)
        this.loading = false;

      },
      error: error => {
        console.log(error);
        this.loading = false;

      },
      complete: () => console.log('Loading event complete')
    })
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  updateTag(evento:NetworkItem){
    console.log("updating", evento);

    this.meta.updateTag({ property: 'og:url', content: `https://woke.mx/event/${evento.id}` });
    this.meta.updateTag({ property: 'og:title', content: `${evento.name}` });
    this.meta.updateTag({ property: 'og:description', content: `${evento.description}` });
    this.meta.updateTag({ property: 'og:image', content: this.loggedService.getUrl(evento.profilePicture) });

  }

  shareOnFB(){
    console.log("sharing");
    FB.ui({
      method: 'feed',
      quote: `${this.event.name} \n ${this.event.description} \n Entra a: https://woke.mx/event/${this.event.id}`,
      display: 'popup',
      picture: this.loggedService.getUrl(this.event.profilePicture),
      redirec_uri: `https://woke.mx/event/${this.event.id}`
    }, function(response){
    });
  }

}


