import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-woke-list-item',
  templateUrl: './woke-list-item.component.html',
  styleUrls: ['./woke-list-item.component.scss']
})
export class WokeListItemComponent implements OnInit {

  @Input() link: string;
  @Input() image = 'https://miro.medium.com/max/390/1*emiGsBgJu2KHWyjluhKXQw.png';

  constructor() { }

  ngOnInit() {
  }

}
