import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginResponse, MetaResponse, Workspace, calcuResponse, RegisterSpaceFormType, MapsResponse, coresLanding, AddressResponse } from './api.interfaces';
import { TokenFacade } from 'src/store/token/token.facade';
import { switchMap, map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { APIMap, Endpoint } from './api.enum';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { areasQuick, mapsResult } from './logged/workspaces/workspace/quick/quick.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  api = new APIMap();

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenFacade: TokenFacade) { }

  adjust(endpoint: Endpoint, payload: any) {
    /**
     * Configuration 🤟
     */
    const adjusted: any = {};

    /**
     * Magic happens here 👇
     */
    for (const key of Object.keys(endpoint.keyMap)) {
      if (payload[key] !== undefined && payload[key] !== null) {
        adjusted[key] = payload[key]
      }
    }
    if (Object.keys(adjusted).length < Object.keys(endpoint.keyMap).length) {
      throw new Error('Faltan llaves');
    }
    return adjusted;
  };

  createUrl = (url: string) => `${environment.api}${url}`;


  createRequest(endpoint: Endpoint, payload: any, token?: string) {
    /**
     * Configuration 🤟
     */
    const body = new FormData();
    const { method, url } = endpoint;
    let urlParams = '?';
    const payloadKeys = Object.keys(payload);

    /**
     * Magic happens here 👇
     */
    for (const [index, key] of payloadKeys.entries()) {
      body.append(key, payload[key]);
      /**
       * * This part is specially important to send payloads
       * * when we're sending a GET request 🤙
       */
      urlParams = `${urlParams}${key}=${payload[key]}`;
      if (index !== payloadKeys.length) {
        urlParams = urlParams + ','
      }

    }

    if (token) {

      const headers = new HttpHeaders({
        'Authorization': token
      });

      // Just in case we need to send params in a GET request ✌️
      if (method === 'get' && payloadKeys.length) {
        return this.http.request(
          // 👉 Method. URL, Options 👈
          method, this.createUrl(`${url}${urlParams}`), { body, headers }
        );
      }

      return this.http.request(method, this.createUrl(url), { body, headers });
    }
    return this.http.request(method, this.createUrl(url), { body });
  }

  adjustAndCreate(endpoint: Endpoint, payload: any, token?: string) {
    if (endpoint.keyMap) {
      const adjusted = this.adjust(endpoint, payload);
      const request = this.createRequest(endpoint, adjusted, token);
      return request;
    }
    const request = this.createRequest(endpoint, payload, token);
    return request;
  }

  /**
   * Login Endpoints
   */

  login(payload: any) {
    const formData = new FormData();
    formData.append('_username', payload.username);
    formData.append('_password', payload.password);
    return this.http.post(`${environment.api}/login`, formData).pipe(
      switchMap((response: LoginResponse) => {
        let { error, token, refresh_token } = response;
        if (error) {
          return throwError(new Error(error));
        }
        token = `Bearer ${token}`;
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('refresh_token', refresh_token);

        this.tokenFacade.setToken(token);
        this.router.navigate(['/workspace']);
        return of({ token });
      })
    );
  }

  register(payload: any) {
    return this.adjustAndCreate(this.api.register, payload).pipe(
      switchMap(() => this.login({
        username: payload.username,
        password: payload.password
      }))
    )
  }

  registerLite(payload: any) {
    return this.adjustAndCreate(this.api.register, payload)
  }

  recoverPass(token:string,password: string) {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('password', password);
    return this.http.post(`${environment.api}/reset-password`, formData).pipe(
      map((response: MetaResponse) => response.data)
    );
  }

  searchWorkspaces(search: string) {
    const url = `${environment.api}/workspace/no_auth/filter_v2`;
    const formData = new FormData();
    formData.append('search_2', search);
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data as Workspace[])
    )
  }

  applyFilterWorkspaces(name:any,filter:string) {
    const url = `${environment.api}/landing/no_auth/filter`;
    const formData = new FormData();
    filter === 'area' ? formData.append('area', name) : formData.append('type', name);
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data as Workspace[])
    )
  }

  getBoundaries(address:string){
    const url = `${environment.mapsApi}&address=${address}&key=${environment.MAPS_KEY}`;
    return this.http.get(url).pipe(
      map((response: MapsResponse) => response.results as mapsResult)
    )
  }

  getSpacesbyLocation(quick,lat?:string, lng?:string){
    const formData = new FormData();
    const url = `${environment.api}/landing/no_auth/filter`;
    formData.append('address', quick.location);
    formData.append('type', quick.type);
    formData.append('time', quick.time);
    if(lat && lng){
      formData.append('lat', lat);
      formData.append('lng', lng);
      formData.delete('address')
    }

    return this.http.post(url,formData).pipe(
      map((response: any) => response.data),
      catchError(() => {
        return of([])
      })
    )
  }

  getCoresProfilePicture(){
    const url = `${environment.api}/access/coworking-parents/list`
    return this.http.get(url).pipe(
      map((response: MetaResponse) => response.data.data as coresLanding[])
    )
  }

  getAreasNoAuth(){
    const url = `${environment.api}/workspace/no_auth/areas`;
    return this.http.get(url).pipe(
      map((response: MetaResponse) => response.data)
    )
  }

  getAreasMap(){
    const url = `${environment.api}/workspace/no_auth/locations`;
    return this.http.get(url).pipe(
      map((response: MetaResponse) => response.data as areasQuick),
    )
  }

  getTypesNoAuth(){
    const url = `${environment.api}/workspace/no_auth/types`;
    return this.http.get(url).pipe(
      map((response: MetaResponse) => response.data)
    )
  }

  getRecentlyAdded(){
    const url = `${environment.api}/workspace/no_auth/filter`;
    const formData = new FormData();
    formData.append('sortBy', 'created');
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data as Workspace[])
    )
  }

  getBestRated(){
    const url = `${environment.api}/workspace/no_auth/filter`;
    const formData = new FormData();
    formData.append('sortBy', 'rating');
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data as Workspace[])
    )
  }

  spaceCostCalculator(estate:string, capacity:string){
    const url = `${environment.api}/calculateCapacityCost`;
    const formData = new FormData();
    formData.append('estate', estate);
    formData.append('capacity', capacity);
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data as calcuResponse)
    )
  }

  registerSpace(payload: RegisterSpaceFormType){
    const url = `${environment.api}/register_space`;
    const formData =  this.generateFormData(payload);
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data)
    )
  }

  getAddress(zipcode:string){
    const url = `${environment.api}/get_address`;
    const formData = new FormData();
    formData.append('zipCode', zipcode);
    return this.http.post(url, formData, ).pipe(
      map((response: MetaResponse) => response.data as AddressResponse)
    )
  }

  generateFormData(obj: any) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      formData.append(key, `${value}`);
    }
    return formData;
  }

}
