
import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModalOptions, BsModalService } from 'ngx-bootstrap/modal';
import { OnboardingType } from '../woke/onboarder/onboarder.models';
import { TokenFacade } from 'src/store/token/token.facade';
import { ChatThread, MetaResponse } from '../api.interfaces';
import { HttpClient } from '@angular/common/http';
import { takeUntil, take, switchMap, map } from 'rxjs/operators';
declare var Pusher: any;


@Injectable({
  providedIn: 'root'
})
export class LoggedService {

  logoPublico = 'https://stage.deq.com.mx/apiwokejwt/images/12-parent.jpg'

  errorOpen = false;
  errorContent = '';
  language:string


  status$ = new BehaviorSubject('');
  chatUpdate$ = new Subject<ChatThread>();
  messsagesReaded$ = new BehaviorSubject(false);

  constructor(
    private modalService: BsModalService,
    private tokenFacade: TokenFacade,
    private http: HttpClient
  ) {
    this.tokenFacade.language$.pipe(
      take(1),
    ).subscribe({
      next: lan => {
        this.language = lan
      }
    })
  }

  initPusher() {
    const pusher = new Pusher(environment.PUSHER_KEY, {
      cluster: environment.PUSHER_APP_CLUSTER,
      forceTLS: true
    });

    const channel = pusher.subscribe(environment.PUSHER_CHANNEL);

    channel.bind('new-message', (chatThread: ChatThread) => {
      // console.log(chatThread);
      this.chatUpdate$.next(chatThread);
    });
  }

  getErrorMessage(message?: string) {

    const errorMessage = (message as any)

    if (errorMessage.message) {
      return this.language === 'en'? 'Something went wrong from the server' : 'Algo salió mal desde el servidor'
    }

    if (errorMessage && errorMessage.error && errorMessage.error.error) {
      return errorMessage.error.error;
    }

    if (message === undefined) {
      return this.language === 'en'? 'Something went wrong from the server' : 'Algo salió mal desde el servidor'
    }

    return message;

  }

  showError(content?: string | any) {
    let message = this.getErrorMessage(content);
    console.log("message", message);

    if (this.errorOpen === true) {
      this.closeError();
      setTimeout(() => {
        this.openError(message);
      }, 500);
    } else {
      this.openError(message);
    }
    setTimeout(() => {
      this.closeAll();
    }, 2000);
  }

  openError(content: string) {
    this.errorContent = content;
    this.errorOpen = true;
    this.status$.next('open');
  }

  closeError() {
    this.errorContent = '';
    this.errorOpen = false;
    this.status$.next('');
  }

  getUrl(url: string) {
    if (url) {
      return `${environment.imageUrl}${url}`;
    }
    return 'assets/img/utils/Profile.png';
  }

  getResource(url: string) {
    if (url) {
      return `${environment.resourceUrl}${url}`;
    }
    return 'assets/img/utils/Profile.png';
  }

  encodeLocation(location: string) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURI(location)}`;
  }

  openModal(
    template: TemplateRef<any>,
    modalConfig: ModalOptions = { class: 'modal-dialog-centered modal-xl' }
  ) {
    this.modalService.show(template, modalConfig);
  }

  closeModal() {
    this.modalService.hide();
    document.querySelector('body').classList.remove('modal-open');
  }

  closeAll() {
    this.closeError();
    this.closeModal();
  }

  showOnboarding(onboarding: OnboardingType) {
    this.tokenFacade.setCurrentOnboarding(onboarding);
  }

  getWorkspaceTypes(type?:string,id?:number){
    const url = id ? `${environment.api}/workspace/types?id=${id}&time=${type}` : `${environment.api}/workspace/types?time=${type}`
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data)
        )
      })
    )
  }

  getWorkspaceTypesChild(id?:number){
    const url = `${environment.api}/workspace/types?id=${id}`
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data)
        )
      })
    )
  }

  getSupport() {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => this.http.get(`${environment.api}/settings/woke-support`, { headers })),
      map((response: any) => response.data)
    )
  }

}
