import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter } from './childs.state'
import { Dictionary } from '@ngrx/entity';
import { CoworkingChild } from 'src/app/api.interfaces';

const feature = createFeatureSelector('CoworkingChilds');

const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal
} = adapter.getSelectors();

export const allCoworkings = createSelector(
  feature,
  selectAll
);

export const selectEntity = createSelector(
  feature,
  (entities: Dictionary<CoworkingChild>, id: number) => {
    return entities.entities[id]
  }
);

export const selectGalleryEntity = createSelector(
  feature,
  (entities: Dictionary<CoworkingChild>, id: number) => {
    return entities.entities[id] ? entities.entities[id].gallery : undefined
  }
);
