import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-woke-radio',
  templateUrl: './woke-radio.component.html',
  styleUrls: ['./woke-radio.component.scss']
})
export class WokeRadioComponent implements OnInit {

  @Input() active = false;
  @Output() activeChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }

}
