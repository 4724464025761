import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { switchMap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private apiwoke = `${environment.api}`;

  constructor(private http: HttpClient) { }

  public getPrivacy() {
    return this.http.get(this.apiwoke + '/privacy-notice').pipe(
      map((response: any) => response.data)
    )
  }

  public getTerms() {
    return this.http.get(this.apiwoke + '/terms-and-conditions').pipe(
      map((response: any) => response.data)
    )
  }

  forgotPassword(email:string){
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post(this.apiwoke + '/forgot-password',formData).pipe(
      map((response: any) => response.data)
    )
  }

}
