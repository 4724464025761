import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ProfileActions } from './profile.actions';
import { ProfileState } from './profile.state';
import { profileSelector, profilePictureSelector, nameSelector, portfolioSelector, roleSelector, categoriesSelector, specialtiesSelector, academicFormationsSelector, experienceSelector, recommendationsSelector, allCategoriesSelector, officesSelector, licensesSelector, loadingSelector, profileIdSelector, businessUsernameSelector, officesValidSelector, extraofficesSelector } from './profile.selectors';
import { EditProfileData, Experience, AcademicInformation, Recommendation, PortfolioImage, Portfolio, MyOffice, ProfileData, MetaResponse, ExtraOffice } from 'src/app/api.interfaces';
import { TokenFacade } from '../token/token.facade';
import { take, filter, switchMap, switchMapTo, map, tap } from 'rxjs/operators';
import { MyLicense } from 'src/app/logged/my-license/my-license.types';
import { ProfileService } from './profile.service';
import { combineLatest } from 'rxjs';

@Injectable()
export class ProfileFacade {

  profile$ = this.store.pipe(
    select(profileSelector)
  );

  profileId$ = this.store.pipe(
    select(profileIdSelector),
    filter(id => id !== undefined ? true : false)
  )

  role$ = this.store.pipe(
    select(roleSelector)
  )

  profileName$ = this.store.pipe(
    select(nameSelector),
    filter(name => name !== undefined ? true : false)
  )

  businessUsername$ = this.store.pipe(
    select(businessUsernameSelector)
  )

  loading$ = this.store.pipe(
    select(loadingSelector)
  )

  // profile Picture
  profilePicture$ = this.store.pipe(
    select(profilePictureSelector)
  );

  allCategories$ = this.store.pipe(
    select(allCategoriesSelector)
  );

  // Categories
  categories$ = this.store.pipe(
    select(categoriesSelector)
  );

  // Specialties
  specialties$ = this.store.pipe(
    select(specialtiesSelector)
  );

  // Portfolios
  portfolio$ = this.store.pipe(
    select(portfolioSelector)
  );

  // Academic Formations
  academicFormations$ = this.store.pipe(
    select(academicFormationsSelector)
  );

  // Experiences
  experience$ = this.store.pipe(
    select(experienceSelector)
  );

  // Recommendations
  recommendations$ = this.store.pipe(
    select(recommendationsSelector)
  );

  // Offices
  offices$ = this.store.pipe(
    select(officesSelector)
  )

  //ExtraOffices
  extraoffices$ = this.store.pipe(
    select(extraofficesSelector)
  )
  // Offices
  officesValid$ = this.store.pipe(
    select(officesValidSelector)
  )

  // Licenses
  licenses$ = this.store.pipe(
    select(licensesSelector)
  )

  constructor(
    private store: Store<ProfileState>,
    private tokenFacade: TokenFacade,
    private profileService: ProfileService
  ) { }

  loadProfile() {
    this.store.dispatch(ProfileActions.load());
  }

  loadData(data: ProfileData) {
    this.store.dispatch(ProfileActions.loadData({ data }));
  }

  loadBusinessSuccess(data: ProfileData) {
    this.store.dispatch(ProfileActions.loadBusinessSuccess({ data }));
  }

  loadLicenses(licenses: MyLicense[]) {
    this.store.dispatch(ProfileActions.loadLicensesSuccess({ licenses }))
  }

  loadOffices(offices: MyOffice[]) {
    this.store.dispatch(ProfileActions.loadOfficesSuccess({ offices }))
  }

  loadExtraOffices(extraOffices: ExtraOffice[]) {
    this.store.dispatch(ProfileActions.loadExtraOfficesSuccess({ extraOffices }))
  }

  loadCategories() {
    this.store.dispatch(ProfileActions.loadCategories());
  }

  // Profile

  async editProfileOld(actualEmail, payload: EditProfileData) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.editProfile({actualEmail, payload, businessProfile }));
  }

  editProfile(actualEmail:string, payload: EditProfileData) {
    return combineLatest(
     [ this.tokenFacade.headers$,
      this.tokenFacade.tokenValue$,
      this.tokenFacade.businessProfile$,
      this.businessUsername$]
    ).pipe(
      take(1),
      switchMap(([headers, token, businessProfile, businessUsername]) =>
        this.profileService.updateProfile(actualEmail, payload, headers).pipe(
          switchMap(() => {
            if (businessProfile) {
              return this.profileService.loadBusinessProfile(token, businessUsername).pipe(
                map((response: MetaResponse) => response.data),
                map(data => ({ data, businessProfile}))
              )
            }
            return this.profileService.loadProfile(token).pipe(
              map((response: MetaResponse) => response.data),
              map(data => ({ data, businessProfile: false }))
            )
          }),
        )
      ),
    );
  }

  async updateProfilePictureOld(image: string) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.updateProfilePicture({ image, businessProfile }));
  }

  updateProfilePicture(image: string) {
    return combineLatest(
      this.tokenFacade.headers$,
      this.tokenFacade.tokenValue$,
      this.tokenFacade.businessProfile$,
      this.businessUsername$,
    ).pipe(
      take(1),
      switchMap(([headers, token, businessProfile, businessUsername]) =>
        this.profileService.updateProfilePicture(image, headers).pipe(
          switchMap(() => {
            if (businessProfile) {
              return this.profileService.loadBusinessProfile(token, businessUsername).pipe(
                map((response: MetaResponse) => response.data),
                map(data => ({ data, businessProfile}))
              )
            }
            return this.profileService.loadProfile(token).pipe(
              map((response: MetaResponse) => response.data),
              map(data => ({ data, businessProfile: false }))
            )
          }),
        )
      ),
    );
  }


  // Categories
  async addCategory(idCategory: string) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.addCategory({ idCategory, businessProfile }))
  }

  async removeCategory(idCategory: string) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.removeCategory({ idCategory, businessProfile }))
  }


  // Specialties
  async addSpecialti(specialti: string) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.addSpecialti({ specialti, businessProfile }));
  }

  async removeSpecialti(index: number) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.removeSpecialti({ index, businessProfile }));
  }

  // Portfolio
  addPortfolio(files: File[], payload: any) {
    return combineLatest(
      this.tokenFacade.headers$,
      this.tokenFacade.tokenValue$
    ).pipe(
      take(1),
      switchMap(([headers, token]) =>
        this.profileService.addPortfolio(files, payload, headers).pipe(
          switchMap(() => this.profileService.loadProfile(token)),
          map((response: MetaResponse) => response.data),
        )
      ),
    );
  }

  async removePortfolio(id: number) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.removePortfolio({ id, businessProfile }));
  }

  async updatePortfolioInfoOld(id: number, payload: any) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.updatePortfolioInfo({ id, payload, businessProfile }));
  }

  updatePortfolioInfo(id: number, payload: any) {
    return combineLatest(
      this.tokenFacade.headers$,
      this.tokenFacade.tokenValue$
    ).pipe(
      take(1),
      switchMap(([headers, token]) =>
        this.profileService.updatePortfolioInfo(id, payload, headers).pipe(
          switchMap(() => this.profileService.loadProfile(token)),
          map((response: MetaResponse) => response.data),
        )
      ),
    );
  }

  updatePortfolioPicture(sort: number, file: File, image: PortfolioImage, portfolio: Portfolio) {
    return this.tokenFacade.headers$.pipe(
      switchMap(headers =>
        this.profileService.updatePortfolioPicture(sort, file, image, portfolio, headers)
      )
    );
  }


  // Academic Formations
  async addAcademicFormation(item: AcademicInformation) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.addAcademicFormation({ item, businessProfile }));
  }

  async updateAcademicInformation(id: number, item: AcademicInformation) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.updateAcademicFormation({ id, item, businessProfile }));
  }

  async removeAcademicFormation(id: number) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.removeAcademicFormation({ id, businessProfile }));
  }


  // Experiences
  async addExperience(experience: Experience) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.addExperience({ experience, businessProfile }));
  }

  async updateExperience(id: number, experience: Experience) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.updateExperience({ id, experience, businessProfile }));
  }

  async removeExperience(id: number) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.removeExperience({ id, businessProfile }));
  }


  // Recommendations
  addRecommendation(recommendation: Recommendation) {
    return combineLatest(
      this.tokenFacade.headers$,
      this.tokenFacade.tokenValue$
    ).pipe(
      take(1),
      switchMap(([headers, token]) =>
        this.profileService.addRecommendation(recommendation, headers).pipe(
          switchMap(() => this.profileService.loadProfile(token)),
          map((response: MetaResponse) => response.data),
        )
      ),
    );
  }

  updateRecommendation(id: number, recommendation: Recommendation) {
    return combineLatest(
      this.tokenFacade.headers$,
      this.tokenFacade.tokenValue$
    ).pipe(
      take(1),
      switchMap(([headers, token]) =>
        this.profileService.updateRecommendation(id, recommendation, headers).pipe(
          switchMap(() => this.profileService.loadProfile(token)),
          map((response: MetaResponse) => response.data),
        )
      ),
    );
  }

  async removeRecommendation(id: number) {
    const businessProfile = await this.tokenFacade.businessProfile$.pipe(take(1)).toPromise();
    this.store.dispatch(ProfileActions.removeRecommendation({ id, businessProfile }));
  }

}
