import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MetaResponse, CoworkingChild } from 'src/app/api.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CoworkingChildsService {

  constructor(
    private http: HttpClient,
  ) { }


  loadOneCoworkingChild(id: number, headers: HttpHeaders): Observable<CoworkingChild> {
    return this.http.get(`${environment.api}/workspace/coworking-child/${id}`, { headers }).pipe(
      map((response: MetaResponse) => response.data),
    )
  }

  loadCoworkingChilds(headers: HttpHeaders): Observable<CoworkingChild[]> {
    return this.http.get(`${environment.api}/workspace/featured`, { headers }).pipe(
      map((response: MetaResponse) => response.data),
    )
  }


  loadCoworkingChildGallery(id: number, headers: HttpHeaders): Observable<{ image: string }[]> {
    const url = `${environment.api}/workspace/coworking-child/gallery/${id}`;
    const ids$: Observable<{ id: number }[]> = this.http.get(url, { headers }).pipe(
      map((response: MetaResponse) => response.data),
    );
    const http$ = ids$.pipe(
      map(images => images.map(
        image => this.http.get(`${environment.api}/workspace/coworking-child/${id}/${image.id}`, { headers })
      ))
    );

    const gallery$: Observable<{ image: string }[]> = http$.pipe(
      switchMap((arr: Observable<any>[]) => zip(...arr)),
      map((arr: MetaResponse[]) => arr.map(response => response.data))
    );

    return gallery$

  }

}
