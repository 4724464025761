import { ActionReducer } from 'ngx-bootstrap/mini-ngrx';
import { ProfileActions } from './profile/profile.actions';
import { MetaReducer } from '@ngrx/store';
import { TokenActions } from './token/token.actions';
import { RootActions } from './root.actions';

export function setProfile(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    let reducedState = reducer(state, action);
    if (action.type === ProfileActions.loadSuccess.type) {
      // window.localStorage.setItem('Profile', JSON.stringify(reducedState.Profile));
    } else if (action.type === `@ngrx/store/init`) {
      const Profile = JSON.parse(window.localStorage.getItem('Profile'));
      if (Profile) {
        reducedState = { ...reducedState, Profile };
      }
    }
    return reducedState;
  };
}

// TokenActions.setBooking
export function setBooking(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    let reducedState = reducer(state, action);
    if (action.type === TokenActions.setBooking.type) {
      window.localStorage.setItem('TokenBooking', JSON.stringify(reducedState.Token.booking));
    } else if (action.type === TokenActions.set.type) {
      const booking = JSON.parse(window.localStorage.getItem('TokenBooking'));
      if (booking) {
        reducedState.Token.booking = booking;
      }
    }
    return reducedState;
  };
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === RootActions.logout.type) {
      state = undefined;
      window.localStorage.removeItem('Profile');
      window.localStorage.removeItem('booking');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('TokenBooking');
      window.localStorage.removeItem('workspaces');
      window.localStorage.removeItem('billingInformation');
      window.localStorage.removeItem('CoworkingChilds');
      window.localStorage.removeItem('imgData');
      window.localStorage.removeItem('businessProfile');
      window.localStorage.removeItem('darkMode');
      window.localStorage.removeItem('refresh_token');
    }
    return reducer(state, action);
  };
}


export const metaReducers: MetaReducer<any>[] = [
  logout
];
