import { Component, OnInit, OnDestroy } from '@angular/core';
import { TokenFacade } from 'src/store/token/token.facade';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Subject, timer } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';

const has = Object.prototype.hasOwnProperty;


@Component({
  selector: 'app-root',
  template: `
    <div [ngClass]="{'dark': darkMode$ | async }">
      <router-outlet></router-outlet>
    </div>
    <style>
      div{
        height:100%;
      }
    </style>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  darkMode$ = this.tokenFacade.darkMode$;
  lang:string

  killall = new Subject();

  constructor(
    private tokenFacade: TokenFacade,
    private translate: TranslateService,
    private router: Router,
    private gaService:GoogleAnalyticsService
  ) {

    this.lang = localStorage.getItem('language');
    if(!this.lang){
      localStorage.setItem('language','es')
      this.translate.setDefaultLang('es');
    }else{
      this.translate.setDefaultLang(this.lang);
      this.tokenFacade.switchLanguage(this.lang);

    }

    // * Set Google Analytics tag on index

    if (environment.gaTrackingId !== '') {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaTrackingId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${environment.gaTrackingId}');
      `;
      document.head.appendChild(gaScript);
    }
  }

  ngOnInit() {
    //* Para Google Analytics
    timer(500)
    .pipe(
      filter(() => has.call(window, 'ga')),
      take(1),
      takeUntil(this.killall),
      switchMap(() => {
        return this.router.events.pipe(
          filter((e) => e instanceof NavigationEnd),
          tap((e: NavigationEnd) => {
            this.gaService.logPageView(e.url);
          })
        );
      }),
    ).subscribe();

    //****
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }
}
