import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CoworkingChildsActions } from './childs.actions';
import { switchMap, map, withLatestFrom, catchError, take } from 'rxjs/operators';
import { TokenFacade } from '../token/token.facade';
import { CoworkingChildsService } from './childs.service';

@Injectable()
export class CoworkingChildsEffects {

  @Effect() loadOne$: Observable<Action> = this.actions$.pipe(
    ofType(CoworkingChildsActions.loadOne),
    withLatestFrom(this.tokenFacade.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.loadOneCoworkingChild(id, headers).pipe(
        map(item => CoworkingChildsActions.loadOneSuccess({ item })),
        catchError(() => of(CoworkingChildsActions.loadOneError()))
      );
    })
  );

  @Effect() load$: Observable<Action> = this.actions$.pipe(
    ofType(CoworkingChildsActions.load),
    withLatestFrom(this.tokenFacade.headers$),
    switchMap(([_, headers]) => {
      console.log('F E I D')
      return this.api.loadCoworkingChilds(headers).pipe(
        map(items => CoworkingChildsActions.loadSuccess({ items })),
        catchError(() => of(CoworkingChildsActions.loadError()))
      );
    })
  );


  @Effect() loadGallery$: Observable<Action> = this.actions$.pipe(
    ofType(CoworkingChildsActions.loadGallery),
    withLatestFrom(this.tokenFacade.headers$),
    switchMap(([{ id }, headers]) => {
      return this.api.loadCoworkingChildGallery(id, headers).pipe(
        map(items => CoworkingChildsActions.loadGallerySuccess({ items, id })),
        catchError(() => of(CoworkingChildsActions.loadGalleryError())),
      );
    })
  );

  constructor(
    private actions$: Actions,
    private api: CoworkingChildsService,
    private tokenFacade: TokenFacade
  ) { }
}
