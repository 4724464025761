import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { CheckoutService } from '../checkout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CheckoutInfoResponse } from '../checkout.types';
import { PaymentMethod } from 'src/app/api.interfaces';
import { LoggedService } from '../../logged.service';
import { WalletFacade } from 'src/store/wallet/wallet.facade';


@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit, OnDestroy {

  // loaders
  loadingCheckout = true;
  loadingConfirm = false;

  info: CheckoutInfoResponse;
  paymentMethod: PaymentMethod;
  invoice = false;
  cfdi: number;



  killall = new Subject();

  constructor(
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private router: Router,
    private loggedService: LoggedService,
    private walletFacade: WalletFacade
  ) { }

  ngOnInit() {
    this.walletFacade.loadPaymentMethods();
    this.loadInfo();
  }

  ngOnDestroy() {
    this.killall.next();
    this.killall.complete();
  }

  onInvoiceChange(invoice: boolean) {
    this.invoice = invoice;
  }


  onCfdiChange(cfdi: any) {
    this.cfdi = cfdi;
  }

  onPaymentMethodChange(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  loadInfo() {
    // a) Load the Info
    this.route.paramMap.pipe(
      map(params => params.get('id')),
      switchMap(id => this.checkoutService.loadNetworkCheckout(id)),
    ).pipe(
      take(1),
      takeUntil(this.killall)
    ).subscribe({
      next: info => {
        this.loadingCheckout = false;
        this.info = info;
      },
      error: (error) => {
        console.warn(error);
        this.loadingCheckout = false;
        this.loggedService.showError(error.error.error);
      },
    })
  }


  confirm(template?: TemplateRef<any>) {
    if (!this.loadingConfirm) {
      this.loadingConfirm = true;
      this.route.paramMap.pipe(
        map(params => params.get('id')),
        switchMap(idBooking => this.checkoutService.networkCheckoutProcess({
          idBooking, sourceId: this.paymentMethod.id, invoice: this.invoice, cfdi: this.cfdi
        })),
        take(1),
        takeUntil(this.killall)
      ).subscribe({
        next: data => {
          this.loadingConfirm = false;
          if (typeof(data) === 'string') {
            return this.loggedService.showError(data);
          }
          if (data.msg === 'succeeded' || data.msg === 'completed') {
            return this.loggedService.openModal(template);
          } else {
            return this.loggedService.showError(data.msg);
          }
        },
        error: error => {
          this.loadingConfirm = false;
          console.warn(error);
          this.loggedService.showError(error);
        }
      })
    }

  }

  goToWorkspace(){
    this.router.navigate(['/network']);
    this.loggedService.closeModal()
  }

}
