import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenFacade } from 'src/store/token/token.facade';
import { environment } from 'src/environments/environment';
import { BillingInformation, MetaResponse, Workspace, Booking } from 'src/app/api.interfaces';
import { switchMap, map, filter, tap, catchError, take } from 'rxjs/operators';
import { WalletFacade } from 'src/store/wallet/wallet.facade';
import { QuickBookingFacade } from 'src/store/quick/quick.facade';
import { ProfileFacade } from 'src/store/profile/profile.facade';
import { CheckoutWorkspaceViewModel } from './checkout-workspace.view-model';
import { BookingResponse, CheckingOutResponse, CheckoutWorkspaceValidate, CurrentBookingDataForInvitation } from 'src/store/quick/quick.service';
import { of, combineLatest, throwError } from 'rxjs';
import { ModifyBookingModel } from 'src/app/logged/workspaces/workspace/models/workspaces.models';
import { isString } from 'util';


export const createInvitationData = (payload: any, email: string) => {
  let image = '';
  if (payload.workspace && payload.workspace.profilePicture) {
    image = payload.workspace.profilePicture;
  }

  let room = '';
  if (payload.workspace && payload.workspace.type) {
    room = payload.workspace.type;
  }

  let coworking = '';
  if (payload.workspace && payload.workspace.name) {
    coworking = payload.workspace.name;
  }

  let date = '';
  if (payload.startAt !== payload.endAt) {
    date = `${payload.startAt} - ${payload.endAt}`;
  } else {
    date = payload.startAt;
  }

  let time = `${payload.startTimeAt}`;
  if (payload.startAt === payload.endAt) {
    time = `${payload.startTimeAt} - ${payload.endTimeAt}`;
  }

  return {
    email,
    idBooking: payload.id,
    fromName: payload.name || '',
    image,
    room,
    coworking,
    date,
    time,
  };
};

export const modifyInvitatonData = (payload: ModifyBookingModel) => {
  let date = '';
  if (payload.dateStart !== payload.dateEnd) {
    date = `${payload.dateStart} - ${payload.dateEnd}`;
  } else {
    date = payload.dateStart;
  }

  let time = `${payload.startTimeAt}`;
  if (payload.dateStart === payload.dateEnd) {
    time = `${payload.startTimeAt} - ${payload.endTimeAt}`;
  }

  return {
    date,
    time,
  };

}

@Injectable({
  providedIn: 'root'
})
export class CheckoutWorkspaceService {

  paymentDefault$ = this.walletFacade.paymentDefault$;
  paymentMethods$ = this.walletFacade.paymentMethods$;
  bookingInfo$ = this.quickFacade.bookingInfo$;
  bookingType$ = this.quickFacade.bookingType$;
  offices$ = this.profileFacade.offices$;
  licenses$ = this.profileFacade.licenses$;
  quickModel$ = this.quickFacade.quickModel$

  constructor(
    private http: HttpClient,
    private tokenFacade: TokenFacade,
    private walletFacade: WalletFacade,
    private quickFacade: QuickBookingFacade,
    private profileFacade: ProfileFacade,
  ) { }

  promiseGuest(email: string) {
    return this.http.get(
      `${environment.api}/get-user-id?email=${email}`
    ).pipe(
      map(response => ({ ...response, email }) as { id?: number, email: string }),
    )
  }

  getGuestsEmails(guests: string[]) {
    const guestsSerialized = guests.map(guest => guest.replace('@', '%40'));
    const observables$ = guestsSerialized.map(guest => this.promiseGuest(guest));
    return combineLatest(observables$);
  }

  promiseIdToInvite(idWithEmail: { id?: number, email: string }, data: any) {
    return this.http.post(
      `${environment.firebaseApi}/notifications/create/invitation`,
      {
        ownerId: idWithEmail.id,
        data: createInvitationData(data, idWithEmail.email),
      }
    );
  }

  promiseIdToCancelInvite(ownerId: number, idBooking: number) {
    return this.http.post(
      `${environment.firebaseApi}/notifications/cancel/invitation`,
      {
        ownerId,
        idBooking,
      }
    );
  }

  promiseIdToModifyInvite(ownerId: number, idBooking: number, data: any) {
    return this.http.post(`${environment.firebaseApi}/notifications/modify/invitation`, { ownerId, idBooking, data: modifyInvitatonData(data), });
  }

  sendInvitations(ids: { id?: number, email: string }[], data: any) {
    return combineLatest(ids.map((idWithEmail) => this.promiseIdToInvite(idWithEmail, data)));
  }

  cancelInvitations(ids: number[], idBooking: number) {
    return combineLatest(ids.map(ownerId => this.promiseIdToCancelInvite(ownerId, idBooking)));
  }

  modifyInvitations(ids: number[], idBooking: number, data: any) {
    return combineLatest(ids.map(ownerId => this.promiseIdToModifyInvite(ownerId, idBooking, data)));
  }


  invitePeople(guests: string[], currentBooking: any) {
    console.log("inviting people");

    return this.getGuestsEmails(guests).pipe(
      switchMap(users => {
        const idsWithEmail = users.filter(user => user.id);
        console.log(idsWithEmail);

        if (idsWithEmail.length) {
          return this.sendInvitations(idsWithEmail, currentBooking);
        }
        return of(true);
      })
    );
  }

  cancelPeople(guests: string[], idBooking: number) {
    return this.getGuestsEmails(guests).pipe(
      switchMap(users => {
        const ids = users.filter(user => user.id).map(idWithEmail => idWithEmail.id);
        return this.cancelInvitations(ids, idBooking);
      })
    );
  }

  modifyPeople(guests: string[], idBooking: number, data: any) {
    return this.getGuestsEmails(guests).pipe(
      switchMap(users => {
        const ids = users.filter(user => user.id).map(idWithEmail => idWithEmail.id);
        return this.modifyInvitations(ids, idBooking, data);
      })
    );
  }

  removeInvitation(idBooking: number, email: string) {
    return this.http.post(`${environment.firebaseApi}/notifications/delete/invitation`, { idBooking, email });
  }

  checkoutBooking$ = (headers: HttpHeaders, bookingId: number, formData: FormData) => this.http.post(
    `${environment.api}/workspace/booking/stripe/checkout/process/${bookingId}`,
    formData,
    { headers }
  ).pipe(
    map((response: MetaResponse) => response.data),
  );

  checkoutValidate$ = (headers: HttpHeaders, bookingId: number) => this.http.get(
    `${environment.api}/workspace/booking/stripe/checkout/validate/${bookingId}`,
    { headers }
  ).pipe(
    map((response: MetaResponse) => response.data as CheckoutWorkspaceValidate),
  );

  // Endpoint al API de Fer
  bookingProcess$ = (headers: HttpHeaders, formData: FormData, workspace: Workspace) => this.http.post(
    `${environment.api}/workspace/booking/process/${workspace.id}`,
    formData,
    { headers }
  ).pipe(
    map((response: MetaResponse) => response.data as BookingResponse),
    tap((response: BookingResponse) => console.log(response)),
  );

  // Procesa un error del Booking Process de API de Fer
  processError$ = (checkoutResponse: CheckingOutResponse, reason?: string) => {
    checkoutResponse.msg = reason || 'Error Desconocido';
    checkoutResponse.error = true;
    return of(checkoutResponse);
  };

  // Consulta el detalle del Booking del API de Fer
  getUpcomingBookingInfo$ = (headers: HttpHeaders) => (bookingId: number) => (profileName: string) => (checkoutResponse: CheckingOutResponse, workspace: Workspace) => this.http.get(
    `${environment.api}/workspace/upcoming/detail/${bookingId}`,
    { headers }
  ).pipe(
    map((response: MetaResponse) => response.data),
    filter(response => Array.isArray(response)),
    map((response: Booking[]) => response[0]),
    map((bookingDetail: Booking) => ({ ...bookingDetail, workspace, name: profileName })),
    catchError(error => {
      console.warn(error, 'error al obtener el detalle de un booking');
      return this.processError$(checkoutResponse, 'error al obtener el detalle de un booking');
    }),
  );

  // Procesa cuando se paga con credits
  getInfoToSendInvites$ = (headers: HttpHeaders, bookingId: number, profileName: string, checkoutResponse: CheckingOutResponse, invites: string[], workspace: Workspace) =>
    this.getUpcomingBookingInfo$(headers)(bookingId)(profileName)(checkoutResponse, workspace).pipe(
      switchMap(currentBooking => this.invitePeople(invites, currentBooking)),
      map(() => checkoutResponse),
      catchError(error => {
        console.warn(error, 'error al enviar invites');
        return this.processError$(checkoutResponse, 'error al enviar invitess');
      }),
    );

  // Convierte el checkout Response a Observable para terminar el flujo
  sendToWorkspace$ = (checkoutResponse: CheckingOutResponse) => of(checkoutResponse);

  // Procesa cuando se paga con varo
  processBookingToCheckout$ = (
    headers: HttpHeaders,
    bookingId: number,
    profileName: string,
    checkoutResponse: CheckingOutResponse,
    formData: FormData,
    invites: string[],
    workspace: Workspace
  ) => {
    // Se hace la petición de checkout al API
    return this.checkoutBooking$(headers, bookingId, formData).pipe(
      switchMap(data => {
        if (isString(data)) {
          throw new Error(data);
        }
        // Una vez finalizado, se valida que el pago sea exitoso
        return this.checkoutValidate$(headers, bookingId).pipe(
          switchMap(response => {
            // Con el pago exitoso, se verifica que haya invites que enviar
            if (response.status === 'completed' || response.msg === 'Emails to Invites sent') {
              // Debemos verificar si hay invitaciones que enviar
              //* if (invites.length) {
              //   // 1. obtiene la info del booking
              //   // 2. hace un map de esa info para enviar las notificaciones de tipo Invite a Woke Lite
              //   return this.getInfoToSendInvites$(headers, bookingId, profileName, checkoutResponse, invites, workspace);
              // }
              // en caso contrario, solo queda resolver el response del checkout
              return this.sendToWorkspace$(checkoutResponse);
            }
            checkoutResponse.msg = 'Payment is pending';
            checkoutResponse.error = false;
            return of(checkoutResponse);
          }),
        )
      }),
      catchError((error) => {
        console.warn(error);
        checkoutResponse.msg = error.message || 'Error al pagar un booking';
        checkoutResponse.error = true;
        return of(checkoutResponse);
      }),
    )
  };

  // Procesamiento de la respuesta del endpoint booking process en el API de Fer
  payProcess$ = (booking: BookingResponse) => (headers: HttpHeaders) => (profileName: string, workspace: Workspace, checkoutViewModel: CheckoutWorkspaceViewModel, invites: string[]) => {
    console.log(booking);
    // Se crea el Checkout Response
    let checkoutResponse: CheckingOutResponse = {
      id: `${booking.id}`,
      msg: 'succeeded',
      error: false
    };
    switch (booking.continueTo) {
      case 'checkout':
        const formData = checkoutViewModel.getCheckoutFormData();
        return this.processBookingToCheckout$(
          headers,
          booking.id,
          profileName,
          checkoutResponse,
          formData,
          invites,
          workspace
        );
      case 'workspace':
      case 'invites':
        // Debemos verificar si hay invitaciones que enviar
        // if (invites.length) {
        //   // 1. obtiene la info del booking
        //   // 2. hace un map de esa info para enviar las notificaciones de tipo Invite a Woke Lite
        //   return this.getInfoToSendInvites$(headers, booking.id, profileName, checkoutResponse, invites, workspace);
        // }
        // en caso contrario, solo queda resolver el response del checkout
        return this.sendToWorkspace$(checkoutResponse);
      default:
        return this.processError$(checkoutResponse);
    }
  }

  // Este método se manda a llamar cuando quieres pagar un booking generado por CMS o de tipo Other
  checkoutProcess(
    workspace: Workspace,
    checkoutViewModel: CheckoutWorkspaceViewModel,
    invites: string[]
  ) {
    const bookingId = checkoutViewModel.getBookingId();
    let checkoutResponse: CheckingOutResponse = { id: `${bookingId}`, msg: 'succeeded', error: false };
    return combineLatest(
     [ this.tokenFacade.headers$,
      this.profileFacade.profileName$]
    ).pipe(
      switchMap(([headers, profileName]) => {
        const formData = checkoutViewModel.getCheckoutFormData();
        return this.processBookingToCheckout$(headers, bookingId, profileName, checkoutResponse, formData, invites, workspace).pipe(
          catchError((error) => {
            console.warn(error);
            checkoutResponse.msg = error.message || 'Error Checkout';
            checkoutResponse.error = true;
            return of(checkoutResponse);
          })
        );
      }),
    );
  }

  // Este método se llama al querer reservar cualquier espacio que no sea de tipo Other
  bookingProcess(
    workspace: Workspace,
    checkoutViewModel: CheckoutWorkspaceViewModel,
    invites: string[]
  ) {
    return combineLatest(
      [this.tokenFacade.headers$,
      this.profileFacade.profileName$]
    ).pipe(
      switchMap(([headers, profileName]) => {
        // 1. Obtenemos el Form Data para hacer el booking process
        const formDataForProcess = checkoutViewModel.getFormData();
        //  2. Llamando al API de Fer para crear el booking
        return this.bookingProcess$(headers, formDataForProcess, workspace).pipe(
          // 3. Con lo que regresa, se procesa un chingo de cosas
          switchMap(booking => this.payProcess$(booking)(headers)(profileName, workspace, checkoutViewModel, invites)),
        );
      }),
    );
  }

  loadPaymentMethods() {
    this.walletFacade.loadPaymentMethods();
  }

  loadBillingInformation() {
    const url = `${environment.api}/wallet/billing-information`;
    return this.tokenFacade.headers$.pipe(
      switchMap(headers => {
        return this.http.get(url, { headers }).pipe(
          map((response: MetaResponse) => response.data as BillingInformation)
        );
      }),
    );
  }

}
